<template>
  <v-card outlined>
    <v-card-text>
      <v-data-table
        :options.sync="optionsItem"
        :headers="contractHeader"
        :items="contractList"
        :item-class="row_class"
        :server-items-length="contractCount"
        :loading="false"
        loading-text="数据读取中... 请稍后"
        :footer-props="{'items-per-page-options': [5, 10, 15, 30, 50]}"
        class="elevation-0 mt-4"
        transition
        fixed-header
      >
        <template v-slot:item.code="{ item }">
          <contract-edit v-if="(isEdit && !item.isForce) || checkOnly" :getType="getType" :getId="getId" :editData="item" :isEdit="isEdit" :checkOnly="checkOnly">
            <td nowrap="true" class="primary--text">{{ item.no }}</td>
          </contract-edit>
          <td v-else nowrap="true">{{ item.no }}</td>
        </template>
        <template v-slot:item.business_type="{ item }">
          <span v-if="item.business_type === 1">能源</span>
          <span v-if="item.business_type === 2">调峰</span>
          <span v-if="item.business_type === 3">售电</span>
          <span v-if="item.business_type === 4">智科</span>
        </template>
        <template v-slot:item.contract_name="{ item }">
          <td nowrap="true" class="text-center" v-if="item.contract_name">{{ item.contract_name }}</td>
        </template>
        <template v-slot:item.sign_type="{ item }">
          <span v-if="item.sign_type === 1">比例分成</span>
          <span v-if="item.sign_type === 2">固定服务费</span>
          <span v-if="item.sign_type === 3">固定单价</span>
          <span v-if="item.sign_type === 5">其他</span>
        </template>
        <template v-slot:item.sign_other="{ item }">
          <span v-if="item.sign_other">{{ item.sign_other }}</span>
        </template>
        <template v-slot:item.sign_date="{ item }">
          {{ item.sign_date | formatDate }}
        </template>
        <template v-slot:item.contract_start="{ item }">
          {{ item.contract_start | formatDate }}
        </template>
        <template v-slot:item.contract_end="{ item }">
          {{ item.contract_end | formatDate }}
        </template>
        <template v-slot:item.confirm_image="{ item }">
          <span v-if="Array.isArray(item.confirm_image)">{{item.confirm_image.length}}份</span>
          <span v-else>无</span>
        </template>
        <template v-slot:item.contract_status="{ item }">
          <span v-if="item.contract_status === 0">
            未开始
          </span>
          <span v-if="item.contract_status === 1" class="success--text">
            履约中
          </span>
          <span v-if="item.contract_status === 2" class="error--text">
            已终止
          </span>
        </template>
        <template v-slot:item.file_no="{ item }">
          <td v-if="item.file_no" nowrap="true" align="center">
            {{ item.file_no }}
          </td>
          <span v-else>无</span>
        </template>
        <template v-slot:item.href="{ item }">
          <div v-if="item.contract_type === 1">
            <v-menu bottom v-if="item.attachment_image && item.attachment_image.length && (isEdit || checkOnly)">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on">
                {{ item?.attachment_image?.length }}
                </span>
                <v-btn x-small icon v-bind="attrs" v-on="on">
                  <v-icon>more_vert</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item v-for="(file, index) in item.attachment_image" :key="'_file_'+index">
                  <v-list-item-content>
                    <v-list-item-title>{{file.substring(file.lastIndexOf('/') + 1, file.length)}}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn icon small @click="downloadHref(file)"><v-icon>download</v-icon></v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </v-menu>
            <span v-else>-</span>
          </div>
          <div v-else>
             <span v-if="item.href && (isEdit || checkOnly)">
            <v-btn text small color="primary" @click="downloadHref(item.href)">下载</v-btn>
          </span>
            <span v-else>-</span>
          </div>
<!--          <span v-if="item.attachment_image && item.attachment_image.length && (isEdit || checkOnly)">-->
<!--&lt;!&ndash;            <v-btn text small color="primary" @click="downloadHref(item.href)">下载</v-btn>&ndash;&gt;-->
<!--            {{item.attachment_image}}-->
<!--            -->
<!--            -->
<!--            -->
<!--            -->
<!--          </span>-->
<!--          <span v-else>-</span>-->
        </template>
        <template v-slot:item.creator="{ item }">
          <span v-if="item.creator">{{ item.creator.personal.name }}</span>
        </template>
        <template v-slot:item.createTime="{ item }">
          {{ item.createTime | formatTime }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y v-if="isEdit">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                icon
                small
              >
                <v-icon small>more_vert</v-icon>
              </v-btn>
            </template>
            <v-list dense>
              <agency-customer-edit :getType="item.business_type" :getId="getId" v-if="getType === 'agency' && (item.contract_status === 1 || item.contract_status === 0)">
                <v-list-item class="v-list-item&#45;&#45;link" @click="doAction('default')">
                  <v-list-item-icon>
                    <v-icon small>add</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> 添加客户 </v-list-item-title>
                </v-list-item>
              </agency-customer-edit>
              <v-list-item v-if="getType === 'agency' && (item.contract_status === 1 || item.contract_status === 0)" class="v-list-item&#45;&#45;link" @click="doAction('batch_create', item)">
                <v-list-item-icon>
                  <v-icon small>upload_file</v-icon>
                </v-list-item-icon>
                <v-list-item-title> 导入客户 </v-list-item-title>
              </v-list-item>
              <v-list-item class="v-list-item&#45;&#45;link" @click="confirmBlock(item._id)" v-if="item.contract_status === 1 || item.contract_status === 0">
                <v-list-item-icon>
                  <v-icon small>block</v-icon>
                </v-list-item-icon>
                <v-list-item-title> 终止 </v-list-item-title>
              </v-list-item>
              <v-list-item class="v-list-item&#45;&#45;link" @click="confirmDelete(item._id)">
                <v-list-item-icon>
                  <v-icon small color="error">delete</v-icon>
                </v-list-item-icon>
                <v-list-item-title> 删除 </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span v-else>-</span>
        </template>
      </v-data-table>
      <v-dialog v-model="warnDlg" persistent scrollable width="400">
        <v-card>
          <v-card-title>
            {{ dlgTitle }}
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDlg"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="text-center py-6">
            <span v-if="dlgTitle === '终止合同'">确定终止此合同吗？</span>
            <span v-if="dlgTitle === '删除合同'">确定删除此合同吗？</span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="closeDlg">取消</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="dlgTitle === '终止合同'" text color="error" @click="blockContract">确认终止</v-btn>
            <v-btn v-if="dlgTitle === '删除合同'" text color="error" @click="deleteContract">确认删除</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="imgDlg" persistent scrollable width="800px">
        <v-card>
          <v-card-title>
            项目确认单
            <v-btn v-if="imgUrl" icon @click="doAction('download_img')" class="ml-2"><v-icon>download</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn icon @click="doAction('close_img')"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-img :src="imgUrl" contain></v-img>
            <v-overlay
              v-model="overlay"
              absolute
              class="align-center justify-center"
            >
              <v-progress-circular
                color="primary"
                indeterminate
                size="64"
              ></v-progress-circular>
            </v-overlay>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dlgInfo" persistent scrollable width="600px">
        <v-card>
          <v-card-title>
            批量导入
            <v-spacer></v-spacer>
            <v-btn icon @click="doAction('close_info')"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12"><v-divider></v-divider></v-col>
              <v-col cols="6">
                <p>1. 下载新增模版，填写合作客户信息.</p>
                <v-btn text @click="doAction('download_create_template')">下载模版<v-icon>file_download</v-icon></v-btn>
              </v-col>
              <v-col cols="6">
                <input
                  id="import_file"
                  type="file"
                  name="import_file"
                  ref="uploadImport"
                  accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  :multiple="false"
                  @change="importCreateFiles($event)"/>
                <p>2. 上传填好的文件.</p>
                <v-btn text @click="doAction('upload_create_file')" :loading="!batch.import_end"><v-icon left dark>file_upload</v-icon>上传文件</v-btn>
              </v-col>
            </v-row>
            <v-row v-if="batch.import_failed.length">
              <v-col cols="12">
                <v-divider></v-divider>
                <v-alert
                  outlined
                  type="error"
                  text
                >
                  导入失败：<span v-if="batch.import_failed.length === 0">无</span><ul v-else><li v-for="(fail,index) in batch.import_failed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
                </v-alert>
                <v-alert
                  outlined
                  type="success"
                  text
                >
                  成功创建：{{ batch.import_result }} 条记录。
<!--                  <v-row v-if="batch.import_result.length">-->
<!--                    <v-col class="grow">-->
<!--                      <p class="font-weight-black" v-for="item in batch.import_result" :key="item._id">{{ item.name }}</p>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import ApiService from "@/common/http";
import store from "@/store";
import {mapGetters} from "vuex";
import {
  FETCH_CONTRACT_LIST,
  EDIT_CONTRACT,
  REMOVE_CONTRACT, FETCH_URL
} from "@/store/actions.type";
import {RELOAD_CONTRACT_LIST, SET_ERROR} from "@/store/mutations.type";
export default {
  data() {
    return {
      optionsItem: {},
      contractHeader: [],
      defaultHeader: [
        { text: '合同编号', value: 'code', width: '120', align: ' d-none', sortable: false},
        { text: '档案号', value: 'file_no', width: '120', align: ' d-none', sortable: false},
        { text: '合同类型', value: 'business_type', width: '120', align: ' d-none', sortable: false},
        { text: '合同名称', value: 'contract_name', width: '180', align: ' d-none', sortable: false},
        { text: '年用电量(兆瓦时)', value: 'electric_use', width: '85', align: ' d-none', sortable: false},
        { text: '签约电价(元/兆瓦时)', value: 'price', width: '100', align: ' d-none', sortable: false},
        { text: '签约方式', value: 'sign_type', width: '120', align: ' d-none', sortable: false},
        { text: '签约方式说明', value: 'sign_other', width: '160', align: ' d-none', sortable: false},
        { text: '鑫泰比例(%)', value: 'xt_ratio', width: '120', align: ' d-none', sortable: false},
        { text: '客户比例(%)', value: 'customer_ratio', width: '120', align: ' d-none', sortable: false},
        { text: '固定单价(元)', value: 'fixed_price', width: '120', align: ' d-none', sortable: false},
        { text: '固定服务费(元)', value: 'fixed_fee', width: '120', align: ' d-none', sortable: false},
        { text: '签约日期', value: 'sign_date', width: '120', align: ' d-none', sortable: false},
        { text: '开始日期', value: 'contract_start', width: '120', align: ' d-none', sortable: false},
        { text: '结束日期', value: 'contract_end', width: '120', align: ' d-none', sortable: false},
        { text: '合同状态', value: 'contract_status', width: '120', align: ' d-none', sortable: false},
        { text: '项目确认单', value: 'confirm_image', width: '120', align: ' d-none', sortable: false},
        { text: '合同文件', value: 'href', width: '120', align: ' d-none', sortable: false},
        { text: '备注', value: 'remark', width: '200', align: ' d-none', sortable: false},
        { text: '创建人', value: 'creator', width: '120', align: 'center', sortable: false},
        { text: '创建时间', value: 'createTime', width: '120', align: ' d-none', sortable: false},
        { text: '操作', value: 'actions', width: '100', align: 'center', sortable: false},
      ],
      warnDlg: false,
      dlgTitle: '',
      activeId: '',
      imgDlg: false,
      imgUrl: '',
      overlay: true,
      dlgInfo: false,
      batch: {
        import_result: 0,
        import_failed: [],
        import_end: true,
      },
      defaultBatch: {
        import_result: 0,
        import_failed: [],
        import_end: true,
      },
      agencyId: '',
      business_type: '',
    }
  },
  props: {
    getType: {
      type: String,
      require: true,
      default: '',
    },
    contractType: {
      type: String,
      require: true,
      default: '',
    },
    getId: {
      type: String,
      require: true,
      default: '',
    },
    reload: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    checkOnly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    contractReload() {
      this.getList()
    },
  },
  created() {
    this.setHeader(this.getType)
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "contractList",
      "contractCount",
      "contractLoading",
      "contractReload",
    ]),
  },
  mounted() {},
  methods: {
    setHeader(val) {
      switch (val) {
        case 'customer': {
          this.contractHeader = JSON.parse(JSON.stringify(this.defaultHeader))
          this.contractHeader.forEach((item) => {
            if (
              item.text === "合同编号" ||
              item.text === "合同类型" ||
              item.text === "合同名称" ||
              item.text === "年用电量(兆瓦时)" ||
              item.text === "签约电价(元/兆瓦时)" ||
              item.text === "签约方式" ||
              item.text === "签约方式说明" ||
              item.text === "鑫泰比例(%)" ||
              item.text === "客户比例(%)" ||
              item.text === "固定单价(元)" ||
              item.text === "固定服务费(元)" ||
              item.text === "签约日期" ||
              item.text === "开始日期" ||
              item.text === "结束日期" ||
              item.text === "合同状态" ||
              item.text === "档案号" ||
              item.text === "合同文件" ||
              item.text === "创建时间" ||
              item.text === "备注"
            ) {
              item.align = "center"
            }
          });
          break;
        }
        case 'agency': {
          this.contractHeader = JSON.parse(JSON.stringify(this.defaultHeader))
          this.contractHeader.forEach((item) => {
            if (
              item.text === "合同编号" ||
              item.text === "合同类型" ||
              item.text === "签约日期" ||
              item.text === "开始日期" ||
              item.text === "结束日期" ||
              item.text === "合同状态" ||
              item.text === "项目确认单" ||
              item.text === "合同文件" ||
              item.text === "档案号" ||
              item.text === "创建人" ||
              item.text === "备注"
            ) {
              item.align = "center"
            }
          });
          break;
        }
      }
    },
    row_class(item) {
      if (item.contract_end && ((new Date(item.contract_end) - new Date()) / (1000*60*60*24) <= 30)) return this.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
    },
    getList() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }

      let urlQuery = ''
      urlQuery += '&contract_type=' + this.contractType
      if (this.getType === 'customer') {
        urlQuery += '&customer=' + this.getId
      }else if (this.getType === 'agency') {
        urlQuery += '&agency=' + this.getId
      }
      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_CONTRACT_LIST, {
          query:
            "page=" +
            page +
            "&limit=" +
            itemsPerPage +
            "&sort=" +
            sort +
            urlQuery,
        })
      }
    },
    doAction(action, item = {}) {
      switch (action) {
        case 'batch_create': {
          this.agencyId = item.agency._id
          this.business_type = item.business_type
          this.dlgInfo = true
          break
        }
        case 'input_create': {
          this.$router.push('/distributor/customer/edit')
          break
        }
        case 'close_info': {
          this.agencyId = ''
          this.business_type = ''
          this.dlgInfo = false
          break
        }
        case 'check_img': {
          this.imgDlg = true
          this.$store.dispatch(FETCH_URL, item).then((url) => {
            this.imgUrl = url
            this.overlay = false
          })
          break
        }
        case 'close_img': {
          this.imgUrl = ''
          this.imgDlg = false
          this.overlay = true
          break
        }
        case 'download_create_template': {
          ApiService.download('/agency/exportCustomer?page=1&limit=-1&onlyheader=yes&agency_id=' + this.agencyId, {
            responseType:'blob'
          })
            .then(resp => {
              let fileName = '代理商合作客户导入模版  .xlsx';
              let contentDisposition = resp.headers['content-disposition'];
              if (contentDisposition) {
                fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
              }
              let blob = new Blob([resp.data], {type: 'application/octet-stream'});
              if (window.navigator.msSaveOrOpenBlob) { //支持IE
                navigator.msSaveBlob(blob, fileName);
              } else {
                let link = document.createElement('a');
                link.style.display = "none";
                link.href = window.URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                this.downloading = false
                //释放内存
                window.URL.revokeObjectURL(link.href);
                document.body.removeChild(link);
              }
            })
            .catch(err => { // 请求失败处理
              this.downloading = false
              store.commit(SET_ERROR, {msg: err});
            });
          break
        }
        case 'upload_create_file': {
          this.$refs.uploadImport.click()
          break
        }
        case 'delete_customer': {
          this.deleteId = item
          this.warnDlg = true
          break
        }
        case 'close_delete': {
          this.deleteId = ''
          this.warnDlg = false
          break
        }
        case 'download_img': {
          window.location.href = this.imgUrl;
          break
        }
        case 'default': {
          console.log('action')
          break
        }
      }
    },
    downloadHref(url) {
      store.dispatch(FETCH_URL, url)
        .then((fileUrl) => {
          // let w = window.open()
          // w.location.href = fileUrl
          window.location.href = fileUrl;
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err });
        });
    },
    importCreateFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.importAgencyCustomer(fileList[x]);
      })
    },
    importAgencyCustomer(file) {
      this.batch.import_end = false
      let param = new FormData()
      param.append('business_type', this.business_type)
      param.append('agency', this.agencyId)
      param.append('file', file)
      ApiService.post('/agency/importCustomer', param)
        .then((data) => {
          this.batch.import_result = data.data.Success
          this.batch.import_failed = data.data.Failed
          this.$refs.uploadImport.value = ''
          this.batch.import_end = true
          this.$store.commit(RELOAD_CONTRACT_LIST)
          this.$emit('reload')
          if (!this.batch.import_failed.length) {
            this.dlgInfo = false
            store.commit(SET_ERROR, {msg: '成功导入' + this.batch.import_result + '个合作客户', color: 'primary'})
          }
        })
        .catch((err) => {
          this.$refs.uploadImport.value = ''
          this.batch.import_result = { updateSum: 0, insertSum: 0 }
          this.batch.import_failed = [{line: 2, error: err.response.data.message}]
          this.batch.import_end = true
        })
    },
    confirmBlock(id) {
      this.activeId = id
      this.dlgTitle = '终止合同'
      this.warnDlg = true
    },
    blockContract() {
      store.dispatch(EDIT_CONTRACT, { id: this.activeId, item: { contract_status: 2 }}).then(() => {
        this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
        this.getList()
        this.closeDlg()
      })
    },
    confirmDelete(id) {
      this.activeId = id
      this.dlgTitle = '删除合同'
      this.warnDlg = true
    },
    deleteContract() {
      store.dispatch(REMOVE_CONTRACT, this.activeId).then(() => {
        this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
        this.getList()
        this.closeDlg()
      })
    },
    closeDlg() {
      this.warnDlg = false
      this.activeId = ''
      this.dlgTitle = ''
    },
  },
  directives: {}
}
</script>

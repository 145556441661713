var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-data-table', {
    attrs: {
      "headers": _vm.headers,
      "items": _vm.itemlist,
      "hide-default-footer": _vm.itemlist.length < 10
    },
    scopedSlots: _vm._u([{
      key: "item.customer",
      fn: function fn(_ref) {
        var _item$customer;
        var item = _ref.item;
        return [_c('span', [_vm._v(_vm._s((_item$customer = item.customer) === null || _item$customer === void 0 ? void 0 : _item$customer.name))])];
      }
    }, {
      key: "item.data.year",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('span', [_vm._v(_vm._s(new Date(item.date).getFullYear()) + "年")])];
      }
    }, {
      key: "item.data.month",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('span', [_vm._v(_vm._s(new Date(item.date).getMonth() + 1) + "月")])];
      }
    }, {
      key: "item.agency_invoice_type",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('span', [_vm._v(_vm._s(item.agency_invoice_type === 1 ? '专票' : '普票'))])];
      }
    }, {
      key: "item.agency_fee",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('span', [_vm._v(_vm._s(_vm._f("formatMoney")(item.agency_fee.$numberDecimal)))])];
      }
    }, {
      key: "item.settle_elec_total",
      fn: function fn(_ref6) {
        var _item$settle_elec_tot;
        var item = _ref6.item;
        return [_c('span', [_vm._v(_vm._s((((_item$settle_elec_tot = item.settle_elec_total) === null || _item$settle_elec_tot === void 0 ? void 0 : _item$settle_elec_tot.$numberDecimal) * 1).toFixed(3)))])];
      }
    }, {
      key: "item.invoice_type",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('span', [_vm._v(_vm._s(item.invoice_type === 1 ? '专票' : '普票'))])];
      }
    }, {
      key: "body.append",
      fn: function fn(_ref8) {
        var headers = _ref8.headers;
        return [_c('tr', [_c('td', {
          attrs: {
            "colspan": headers.length - 1
          }
        }, [_vm._v("合计")]), _c('td', {
          staticStyle: {
            "text-align": "end"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.caseInfo.group === 1 ? _vm.itemlist.reduce(function (acc, curr) {
          return acc + Number(curr.agency_fee.$numberDecimal);
        }, 0) : _vm.itemlist.reduce(function (acc, curr) {
          return acc + Number(curr.agency_fee.$numberDecimal);
        }, 0))))])])];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
import { LogService } from "@/common/http";

import {
  FETCH_LOGS_LIST,
} from "./actions.type";
import {
  SET_ERROR,
  SET_LOGS,
  LOAD_LOGS,
  SET_LOGS_LIST,
} from "./mutations.type"

const state = {
  logsLoading: true,
  logsList: [],
  logsCount: 0,
};
const getters = {
  logsLoading: state => state.logsLoading,
  logsList: state => state.logsList,
  logsCount: state => state.logsCount,
};
const actions = {
  [FETCH_LOGS_LIST]({ commit }, slug) {
    commit(LOAD_LOGS);
    return new Promise((resolve, reject) => {
      LogService.get('', 'list?'+slug)
        .then(({ data }) => {
          commit(SET_LOGS_LIST, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
};
const mutations = {
  [LOAD_LOGS] (state) {
    state.logsLoading = true;
  },
  [SET_LOGS_LIST](state, list) {
    state.logsList = list.logs;
    state.logsCount = list.query.count;
    state.logsLoading = false;
  },
  [SET_LOGS](state, data) {
    LogService.add(data)
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};



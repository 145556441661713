<template>
  <v-timeline dense clipped align-top v-if="caseLogs && caseLogs.length">
    <v-timeline-item
      v-for="(item, n) in caseLogs"
      :key="n"
      small
      fill-dot
      :icon="item.action === 'Create' ? 'add' : item.action === 'Batch' ? 'layers' : item.action === 'Print' ? 'print' : item.action === 'Download' ? 'file_download' : 'create'"
    >
      <v-row class="mt-n4">
        <v-col cols="5">
          <strong>{{ item.createTime | formatTime }}</strong> {{item.action | stringModule}}
          <div class="caption">
<!--            <account-dialogs :account="item.operator._id" class="v-list-item&#45;&#45;link">{{item.operator.personal.name ? item.operator.personal.name : '*未设置姓名'}}<v-icon class="mx-2">read_more</v-icon></account-dialogs>-->
            {{item.operator.personal.name ? item.operator.personal.name : '*未设置姓名'}}
          </div>
        </v-col>
        <v-col cols="7" class="text-right" v-if="item.record.length">
          <div class="caption text-left" v-for="(change, j) in item.record" :key="j">
            <span v-if="item.action === 'Batch'">{{change.text}}: {{change.value}}</span>
            <span v-else>{{change}}</span>
          </div>
        </v-col>
      </v-row>
    </v-timeline-item>
  </v-timeline>
  <div class="text-center py-6" v-else>
    暂无数据
  </div>
</template>
<script>
import store from "@/store";
import {FETCH_LOGS_LIST} from "@/store/actions.type";

export default {
  props: {
    caseData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      caseLogs: [],
    }
  },
  mounted() {},
  watch: {
    caseData(val) {
      if (val) this.initialize();
    }
  },
  created() {
    this.initialize()
  },
  computed: {},
  methods: {
    initialize() {
      if(this.caseData._id) {
        store.dispatch(FETCH_LOGS_LIST, '&limit=100&key='+ this.caseData._id)
          .then((logs) => {
            this.caseLogs = logs.logs.concat()
          })
      }
    }
  }
}
</script>

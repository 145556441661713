var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "width": "1000"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({
          on: {
            "click": _vm.checkData
          }
        }, 'span', attrs, false), on), [_vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2)];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "width": "600",
      "light": ""
    }
  }, [_c('v-card-title', [_c('span', [_vm._v("结算明细单")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-6"
  }, [_c('div', {
    ref: "report",
    staticClass: "pa-6"
  }, [_vm.getType === 3 ? _c('case-receipt-detailed-selling', {
    attrs: {
      "settleData": _vm.settleData
    }
  }) : _vm._e(), _vm.getType === 1 ? _c('case-receipt-detailed-energy', {
    attrs: {
      "settleData": _vm.settleData
    }
  }) : _vm._e(), _vm.getType === 4 ? _c('case-receipt-detailed-zhike', {
    attrs: {
      "settleData": _vm.settleData
    }
  }) : _vm._e()], 1)]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.downloadReceipt
    }
  }, [_vm._v("下载")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
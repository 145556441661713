import {SettingService} from "@/common/http";

import {
  FETCH_AREA_LIST,
  PUBLISH_AREA,
  EDIT_AREA,
  ACTIV_AREA
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_AREA_LIST,
  SET_AREA_LIST,
  SET_AREA_ALL_LIST
} from "./mutations.type"

const state = {
  areaLoading: true,
  areaList: [],
  areaCount: 0,
};
const getters = {
  areaLoading: state => state.areaLoading,
  areaList: state => state.areaList,
  areaCount: state => state.areaCount
};

const mutations = {
  [LOAD_AREA_LIST] (state) {
    state.areaLoading = true;
  },
  [SET_AREA_LIST](state, list) {
    state.areaCount = list.query.count;
    state.areaList = list.docs;
    state.areaLoading = false;
  },
  [SET_AREA_ALL_LIST](state, list) {
    state.areaList = list.docs;
    state.areaLoading = false;
  }
};

const actions = {
  [FETCH_AREA_LIST]({ commit }, slug) {
    commit(LOAD_AREA_LIST);
    return new Promise((resolve, reject) => {
      SettingService.get('/area/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_AREA_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_AREA](context, vendor) {
    return SettingService.create('/area', vendor);
  },
  [EDIT_AREA](context, vendor) {
    return SettingService.update('/area', vendor._id, vendor);
  },
  [ACTIV_AREA](context, vendor) {
    return SettingService.enable('/area/', vendor._id +'/'+ vendor.reply);
  },
};


export default {
  state,
  actions,
  mutations,
  getters
};

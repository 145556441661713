<template>
  <div class="watermarkWrap" v-show="isClientV" ref="waterMark">
    <div
      :style="`color:${textColor};transform: rotate(${rotate}deg);width:${wm_w}%;height:${wm_h}%`"
      class="wm"
      v-for="(i, index) in watermarkNum"
      :key="index"
    >
      <p class="text-h6">{{ watermarkText }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      watermarkNum: 99, //水印数量
      watermarkRow: 40, //列数
      watermarkLine: 40, //行数
      rotate: -30, //文字旋转角度
      textColor: "rgba(0,0,0,.1)", //文字颜色
      wm_w: 30, //水印宽度百分比
      wm_h: 20, //水印高度百分比
    }
  },
  props: {
    isClientV: {
      type: Boolean,
      default: true
    },
    watermarkText: {
      type: String,
      default: '北京鑫泰能源股份有限公司'
    },
  },
}
</script>

<style scoped>
.watermarkWrap {
  overflow: hidden;
  position: absolute;
  padding-top: 120px;
  padding-bottom: 40px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99999;
  pointer-events: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.wm {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

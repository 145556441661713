<template>
  <v-card class="mx-auto">
    <v-list-item>
      <v-list-item-avatar color="primary" size="30">
        <v-icon dark v-text="icon" small></v-icon>
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title
          class="body-1 font-weight-light mb-2"
          v-text="count"
        />
        <v-list-item-subtitle
          class="caption font-weight-light"
          v-text="label"
        ></v-list-item-subtitle
        >
      </v-list-item-content>
<!--      <v-list-item-action>-->
<!--        <v-icon class="subheading font-weight-light"-->
<!--        >arrow_forward</v-icon-->
<!--        >-->
<!--      </v-list-item-action>-->
    </v-list-item>
<!--    <v-divider></v-divider>-->
<!--    <v-card-text class="py-1">-->
<!--      <v-icon class="mr-2" small>schedule</v-icon>-->
<!--      <span class="caption font-weight-light">更新时间：{{updateTime | fromDate}}</span>-->
<!--    </v-card-text>-->
  </v-card>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: 'flag'
    },
    count: {
      default: 0
    },
    label: {
      type: String,
    },
    path: {
      type: String,
      default: '/'
    },
  },
}
</script>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', [_c('v-card', [_c('v-card-title', [_vm._v("常用入口")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-slide-group', {
    staticClass: "pa-4",
    attrs: {
      "show-arrows": ""
    }
  }, _vm._l(_vm.userItem, function (item) {
    return _c('v-slide-item', {
      key: item.code,
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn() {
          return [_c('v-card', {
            staticClass: "ma-4",
            attrs: {
              "color": item.color,
              "height": "100",
              "width": "100"
            },
            on: {
              "click": function click($event) {
                return _vm.gotoLink(item.link);
              }
            }
          }, [_c('v-row', {
            staticClass: "fill-height",
            attrs: {
              "align": "center",
              "justify": "center"
            }
          }, [_c('v-icon', {
            staticClass: "mt-5",
            attrs: {
              "color": "white",
              "size": "48"
            },
            domProps: {
              "textContent": _vm._s(item.icon)
            }
          }), _c('v-subheader', {
            attrs: {
              "dark": ""
            },
            domProps: {
              "textContent": _vm._s(item.name)
            }
          })], 1)], 1)];
        },
        proxy: true
      }], null, true)
    });
  }), 1)], 1), _c('v-col', {
    staticClass: "d-flex align-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('v-card', {
    attrs: {
      "outlined": "",
      "flat": "",
      "height": "100",
      "width": "100"
    },
    on: {
      "click": function click($event) {
        return _vm.addLink();
      }
    }
  }, [_c('v-row', {
    staticClass: "fill-height",
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-icon', {
    staticClass: "mt-5",
    domProps: {
      "textContent": _vm._s('add')
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "350px"
    },
    model: {
      value: _vm.dlgEdit,
      callback: function callback($$v) {
        _vm.dlgEdit = $$v;
      },
      expression: "dlgEdit"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("选择快捷入口")]), _c('v-card-text', [_c('v-container', [_c('v-chip-group', {
    attrs: {
      "column": "",
      "multiple": ""
    },
    model: {
      value: _vm.editItem,
      callback: function callback($$v) {
        _vm.editItem = $$v;
      },
      expression: "editItem"
    }
  }, _vm._l(_vm.shortcutsLink, function (item) {
    return _c('v-chip', {
      key: item.code,
      attrs: {
        "outlined": "",
        "filter": "",
        "value": item.code
      }
    }, [_vm._v(_vm._s(item.name))]);
  }), 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.close
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card>
    <v-card-title>
      {{id ? '编辑人员信息' : '创建人员资料'}}
      <v-spacer></v-spacer>
      <v-btn icon @click="closeEdit()"><v-icon>close</v-icon></v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-form v-model="valid" @submit.prevent="submitEdit">
          <v-row align="start" justify="center">
            <v-col cols="2" class="text-center">
              <input
                id="files"
                type="file"
                name="file"
                ref="uploadInput"
                accept="image/*"
                :multiple="false"
                @change="detectFiles($event)"/>
              <v-hover v-slot:default="{ hover }">
                <v-avatar>
                  <v-img
                    :src="editedEmployee.profile ? ossURL +'/'+ editedEmployee.profile : '/static/grey_silhouette.png'"
                    alt="设置照片"
                    aspect-ratio="1">
                    <v-progress-circular
                      color="secondary"
                      rotate="360"
                      size="48"
                      width="5"
                      v-if="Upload.uploading"
                      :value="Upload.progressUpload">
                    </v-progress-circular>
                  </v-img>
                  <div v-if="hover" class="show_upload" style="height: 100%; cursor: pointer">
                    <v-icon @click="uploadAvatar()" >add_a_photo</v-icon>
                  </div>
                </v-avatar>
              </v-hover>
            </v-col>
            <v-col cols="10">
              <v-text-field
                v-model="editedEmployee.name"
                :rules="[rules.required]"
                type="text"
                label="员工姓名"
                hint="员工姓名为用户的真实姓名"
                outlined>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="editedEmployee.username"
                :rules="[rules.required, rules.phonenum]"
                :error-messages="editedEmployee.msgName"
                :clearable="editedEmployee.msgName !== ''"
                :disabled="!!this.id"
                prepend-icon='https'
                type="text"
                label="用户账号"
                hint="用户账号将作为登陆名称使用"
                outlined
                required
              >
                <template v-slot:append>
                  <v-scale-transition leave-absolute>
                    <v-progress-circular
                      v-if="editedEmployee.chkName"
                      :width="2"
                      size="24"
                      color="secondary"
                      indeterminate
                    ></v-progress-circular>
                  </v-scale-transition>
                </template>
              </v-text-field>
              <v-text-field
                v-model="editedEmployee.password"
                :rules="[rules.required]"
                :type="editedEmployee.showPass ? 'text' : 'password'"
                :append-icon="editedEmployee.showPass ? 'visibility_off' : 'visibility'"
                :disabled="!!this.id"
                prepend-icon='vpn_key'
                label="账号密码"
                hint="请牢记此密码，密码将作为登陆凭证"
                outlined
                required
                @click:append="editedEmployee.showPass = !editedEmployee.showPass"
              >
              </v-text-field>
              <v-autocomplete
                v-model="editedEmployee.department"
                :items="flatDeptList(departments[0])"
                :rules="[rules.selected]"
                prepend-icon='account_tree'
                item-text="name"
                item-value="value"
                autocomplete="off"
                outlined
                chips
                small-chips
                label="请选择所属部门"
                multiple
              ></v-autocomplete>
              <v-autocomplete
                v-model="editedEmployee.roles"
                :items="flatGroupRoles(groupList)"
                :rules="[rules.selected]"
                prepend-icon='admin_panel_settings'
                item-text="name"
                item-value="_id"
                autocomplete="off"
                outlined
                chips
                small-chips
                label="请选择所属角色"
                multiple
              >
                <template v-slot:item="data">
                  <template v-if="!data.item.name">
                    <v-list-item-content v-text="data.item"></v-list-item-content>
                  </template>
                  <template v-else>
                    <v-list-item-action>
                      <v-checkbox :input-value="data.attrs.inputValue"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title v-html="data.item.name"></v-list-item-title>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <v-autocomplete
                v-model="editedEmployee.group"
                :items="dictBusinessGroup"
                :rules="[rules.selected]"
                prepend-icon='groups'
                item-text="name"
                item-value="code"
                autocomplete="off"
                outlined
                chips
                small-chips
                label="请选择所属业务群组"
                multiple
              ></v-autocomplete>
              <v-autocomplete
                v-model="editedEmployee.area"
                :items="areaList"
                :rules="[rules.selected]"
                prepend-icon='crop'
                item-text="name"
                item-value="_id"
                autocomplete="off"
                outlined
                chips
                small-chips
                label="请选择所属区域"
                multiple
              ></v-autocomplete>
            </v-col>
<!--            <v-col cols="6">-->
<!--              -->
<!--            </v-col>-->
<!--            <v-col cols="6">-->
<!--              <v-select-->
<!--                v-model="editedEmployee.certType"-->
<!--                :items="dictCertType"-->
<!--                :rules="[rules.required, rules.selected]"-->
<!--                item-text="name"-->
<!--                item-value="code"-->
<!--                autocomplete="off"-->
<!--                prepend-icon="branding_watermark"-->
<!--                outlined-->
<!--                label="证件类型"-->
<!--                hint="请选择证件类型"-->
<!--              ></v-select>-->
<!--            </v-col>-->
<!--            <v-col cols="6">-->
<!--              <v-text-field-->
<!--                v-model="editedEmployee.certNumber"-->
<!--                :rules="[rules.required]"-->
<!--                :error-messages="editedEmployee.msgCert"-->
<!--                :clearable="editedEmployee.msgCert !== ''"-->
<!--                @change="setIdentity"-->
<!--                prepend-icon="fingerprint"-->
<!--                type="text"-->
<!--                label="证件号码"-->
<!--                hint="请输入住人员证件号码"-->
<!--                outlined-->
<!--              >-->
<!--                <template v-slot:append>-->
<!--                  <v-scale-transition leave-absolute>-->
<!--                    <v-progress-circular-->
<!--                      v-if="editedEmployee.chkCert"-->
<!--                      :width="2"-->
<!--                      size="24"-->
<!--                      color="secondary"-->
<!--                      indeterminate-->
<!--                    ></v-progress-circular>-->
<!--                  </v-scale-transition>-->
<!--                </template>-->
<!--              </v-text-field>-->
<!--            </v-col>-->
<!--            <v-col cols="6">-->
<!--              <v-text-field-->
<!--                v-model="editedEmployee.phone"-->
<!--                :rules="[rules.phonenum]"-->
<!--                :error-messages="editedEmployee.msgPhone"-->
<!--                :clearable="editedEmployee.msgPhone !== ''"-->
<!--                :disabled="!!this.id"-->
<!--                prepend-icon='stay_current_portrait'-->
<!--                type="number"-->
<!--                label="手机号码"-->
<!--                hint="电话号码将用于恢复密码和登陆凭证"-->
<!--                outlined-->
<!--              >-->
<!--                <template v-slot:append>-->
<!--                  <v-scale-transition leave-absolute>-->
<!--                    <v-progress-circular-->
<!--                      v-if="editedEmployee.chkPhone"-->
<!--                      :width="2"-->
<!--                      size="24"-->
<!--                      color="secondary"-->
<!--                      indeterminate-->
<!--                    ></v-progress-circular>-->
<!--                  </v-scale-transition>-->
<!--                </template>-->
<!--              </v-text-field>-->
<!--            </v-col>-->
<!--            <v-col cols="6">-->
<!--              <v-text-field-->
<!--                v-model="editedEmployee.employeeNo"-->
<!--                prepend-icon='assignment_ind'-->
<!--                type="text"-->
<!--                label="员工编号"-->
<!--                hint="员工编号为企业内部的员工编号"-->
<!--                outlined>-->
<!--              </v-text-field>-->
<!--            </v-col>-->
<!--            <v-col cols="6">-->
<!--              <v-autocomplete-->
<!--                v-model="editedEmployee.berth"-->
<!--                :items="berthList"-->
<!--                :loading="editedEmployee.loadingBerth"-->
<!--                :search-input.sync="editedEmployee.searchBerth"-->
<!--                prepend-icon='home_filled'-->
<!--                item-text="code_no"-->
<!--                item-value="_id"-->
<!--                autocomplete="off"-->
<!--                outlined-->
<!--                hide-no-data-->
<!--                label="选择房间"-->
<!--                hint="请输入房间号查询房间"-->
<!--                clearable-->
<!--              >-->
<!--                <template v-slot:item="data">-->
<!--                  <v-list-item-content>-->
<!--                    <v-list-item-title>{{data.item.build.name}}-{{data.item.code_no}}</v-list-item-title>-->
<!--                    <v-list-item-subtitle>{{data.item.state.name}} - {{data.item.guest.map(guest => guest.name)}}</v-list-item-subtitle>-->
<!--                  </v-list-item-content>-->
<!--                </template>-->
<!--              </v-autocomplete>-->
<!--            </v-col>-->
<!--            <v-col cols="6">-->
<!--              <v-text-field-->
<!--                v-model="editedEmployee.berthKey"-->
<!--                prepend-icon='key'-->
<!--                type="text"-->
<!--                label="房间密码"-->
<!--                hint="请输入房间对应门禁密码"-->
<!--                outlined>-->
<!--              </v-text-field>-->
<!--            </v-col>-->
          </v-row>
        </v-form>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="closeEdit">取消</v-btn>
      <v-btn color="primary" text @click="submitEdit()" :disabled="!valid">确认</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {
  CHECK_USERNAME,
  CHECK_USERPHONE,
  CREATE_EMPLOYEE,
  FETCH_DEPT_LIST, FETCH_GROUP_LIST, FETCH_AREA_LIST,
  FETCH_UPLOAD,
  FETCH_EMPLOYEE,
  EDIT_EMPLOYEE,
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import axios from "axios";
import _ from "lodash";
import {mapGetters} from "vuex";
import store from "@/store";
import Util from "@/common/util";

export default {
  props: {
    id: {
      type: String
    },
  },
  data() {
    return {
      valid: true,
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      editedEmployee: {
        _id: '',
        username: '',
        password: '',
        phone: '',
        name: '',
        profile: '',
        department: [],
        employeeNo: '',
        group: [],
        roles: [],
        area: [],
        chkName: false,
        msgName: '',
        chkPhone: false,
        msgPhone: '',
        chkCert: false,
        msgCert: '',
        showPass: false,
        selDeptActive:[],
      },
      defaultEmployee: {
        _id: '',
        username: '',
        password: '',
        phone: '',
        name: '',
        profile: '',
        department: [],
        employeeNo: '',
        roles: [],
        group: [],
        area: [],
        chkName: false,
        msgName: '',
        chkPhone: false,
        msgPhone: '',
        chkCert: false,
        msgCert: '',
        showPass: false,
        selDeptActive:[],
      },
      berthList: [],
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      defaultUpload: {
        uploading: false,
        progressUpload: 0,
      }
    }
  },
  created() {
    store.dispatch(FETCH_DEPT_LIST)
    store.dispatch(FETCH_GROUP_LIST)
    store.dispatch(FETCH_AREA_LIST)
    if (this.id) {
      store.dispatch(FETCH_EMPLOYEE, this.id)
        .then((data) => {
          this.editedEmployee = Object.assign(this.defaultEmployee, data)
          this.editedEmployee.profile = this.editedEmployee?.personal?.profile
          this.editedEmployee.name = this.editedEmployee?.personal?.name
          this.editedEmployee.department = data.employer?.department.map(item => item._id)
          this.editedEmployee.roles = data.employer?.roles.map(item => item._id)
          this.editedEmployee.employeeNo = this.editedEmployee?.employer?.employeeNo
          this.editedEmployee.group = this.editedEmployee?.employer?.group
          this.editedEmployee.area = this.editedEmployee?.employer?.area
          this.editedEmployee.password = 'password'
        })
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'departments',
      'groupList',
      'areaList',
      'uploadSign']),
    dictBusinessGroup () {
      return Util.categories('businessGroup')
    },
  },
  watch: {
    'editedEmployee.username'() {
      if (!this.id) {
        this.checkName();
      }
    },
    'editedEmployee.phone'() {
      if (!this.id) {
        this.checkPhone();
      }
    },
  },
  methods: {
    closeEdit() {
      this.editedEmployee = Object.assign({}, this.defaultEmployee)
      this.Upload = Object.assign({}, this.defaultUpload)
      this.$emit('Close')
    },
    checkName: _.debounce(function () {
      if (this.editedEmployee.username) {
        this.editedEmployee.chkName = true;
        store.dispatch(CHECK_USERNAME, this.editedEmployee.username)
          .then(() => {
            this.editedEmployee.msgName = '账号已被注册，请更换其它名称';
            this.editedEmployee.chkName = false;
          })
          .catch(() => {
            this.editedEmployee.msgName = '';
            this.editedEmployee.chkName = false;
          })
      }
    }, 1000),
    checkPhone: _.debounce(function () {
      if (this.editedEmployee.phone) {
        this.editedEmployee.chkPhone = true;
        store.dispatch(CHECK_USERPHONE, this.editedEmployee.phone)
          .then(() => {
            this.editedEmployee.msgPhone = '手机号码已被注册，请更换其它号码';
            this.editedEmployee.chkPhone = false;
          })
          .catch(() => {
            this.editedEmployee.msgPhone = '';
            this.editedEmployee.chkPhone = false;
          })
      }
    }, 1000),
    flatDeptList(obj) {
      if (obj) {
        let arrObj = [];
        arrObj.push({name: obj.name, value: obj._id})
        if (obj.children) {
          for (let item_1 of obj.children) {
            arrObj.push({name: obj.name +'>>'+ item_1.name, value: item_1._id})
            if (item_1.children) {
              for (let item_2 of item_1.children) {
                arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name, value: item_2._id})
                if (item_2.children) {
                  for (let item_3 of item_2.children) {
                    arrObj.push({name: obj.name +'>>'+ item_1.name +'>>'+ item_2.name +'>>'+ item_3.name, value: item_3._id})
                  }
                }
              }
            }
          }
        }
        return arrObj;
      }
    },
    flatGroupRoles(arrGroup) {
      let arrRoles = []
      arrGroup.forEach((groupItem) => {
        arrRoles.push({ header: groupItem.name })
        groupItem.roles.forEach((rolesItem) => {
          arrRoles.push({ _id: rolesItem._id, name: rolesItem.name, group: groupItem.name })
        })
        arrRoles.push({ divider: true })
      })
      return arrRoles
    },
    submitEdit() {
      if (this.id){
        let strUpdate = {}
        strUpdate._id = this.id
        strUpdate.name = this.editedEmployee.name
        strUpdate.department = this.editedEmployee.department
        strUpdate.roles = this.editedEmployee.roles
        strUpdate.employeeNo = this.editedEmployee.employeeNo
        strUpdate.group = this.editedEmployee.group
        strUpdate.area = this.editedEmployee.area
        if (this.editedEmployee.profile) strUpdate.profile = this.editedEmployee.profile

        store.dispatch(EDIT_EMPLOYEE, strUpdate)
          .then(() => {
            this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'success'});
            this.closeEdit()
          })
          .catch((error) => {
            this.$store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      } else {
        let strInsert = {}
        strInsert['personal'] = {}
        strInsert['employer'] = {}
        strInsert.username = this.editedEmployee.username
        strInsert.password = this.editedEmployee.password
        strInsert.phone = this.editedEmployee.username
        strInsert['personal'].name = this.editedEmployee.name
        strInsert['employer'].department = this.editedEmployee.department
        strInsert['employer'].roles = this.editedEmployee.roles
        strInsert['employer'].group = this.editedEmployee.group
        strInsert['employer'].area = this.editedEmployee.area
        if (this.editedEmployee.profile) strInsert['personal'].profile = this.editedEmployee.profile
        if (this.editedEmployee.employeeNo) strInsert['employer'].employeeNo = this.editedEmployee.employeeNo;

        store.dispatch(CREATE_EMPLOYEE, strInsert)
          .then(() => {
            this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'success'});
            this.closeEdit()
          })
          .catch((error) => {
            this.$store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    },
    uploadAvatar() {
      store.dispatch(FETCH_UPLOAD)
        .then(() => {
          this.$refs.uploadInput.click()
        })
        .catch(err => {
          this.$store.commit(SET_ERROR, {msg: err});
        });
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    async upload(file) {
      this.fileName = file.name;
      this.Upload.uploading = true;
      let param = new FormData();
      param.append('name', file.name);
      param.append('key', 'avatar/' + this.uploadSign.key);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };

      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.editedEmployee.profile = 'avatar/' + this.uploadSign.key;
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          store.commit(SET_ERROR, {msg: error.message});
        });
    },
  }
}

</script>
<style scoped>
.nowrap {
  white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "options": _vm.optionsItem,
      "headers": _vm.contractHeader,
      "items": _vm.contractList,
      "item-class": _vm.row_class,
      "server-items-length": _vm.contractCount,
      "loading": false,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50]
      },
      "transition": "",
      "fixed-header": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "item.code",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm.isEdit && !item.isForce || _vm.checkOnly ? _c('contract-edit', {
          attrs: {
            "getType": _vm.getType,
            "getId": _vm.getId,
            "editData": item,
            "isEdit": _vm.isEdit,
            "checkOnly": _vm.checkOnly
          }
        }, [_c('td', {
          staticClass: "primary--text",
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.no))])]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.no))])];
      }
    }, {
      key: "item.business_type",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [item.business_type === 1 ? _c('span', [_vm._v("能源")]) : _vm._e(), item.business_type === 2 ? _c('span', [_vm._v("调峰")]) : _vm._e(), item.business_type === 3 ? _c('span', [_vm._v("售电")]) : _vm._e(), item.business_type === 4 ? _c('span', [_vm._v("智科")]) : _vm._e()];
      }
    }, {
      key: "item.contract_name",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.contract_name ? _c('td', {
          staticClass: "text-center",
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item.contract_name))]) : _vm._e()];
      }
    }, {
      key: "item.sign_type",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.sign_type === 1 ? _c('span', [_vm._v("比例分成")]) : _vm._e(), item.sign_type === 2 ? _c('span', [_vm._v("固定服务费")]) : _vm._e(), item.sign_type === 3 ? _c('span', [_vm._v("固定单价")]) : _vm._e(), item.sign_type === 5 ? _c('span', [_vm._v("其他")]) : _vm._e()];
      }
    }, {
      key: "item.sign_other",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.sign_other ? _c('span', [_vm._v(_vm._s(item.sign_other))]) : _vm._e()];
      }
    }, {
      key: "item.sign_date",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.sign_date)) + " ")];
      }
    }, {
      key: "item.contract_start",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.contract_start)) + " ")];
      }
    }, {
      key: "item.contract_end",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.contract_end)) + " ")];
      }
    }, {
      key: "item.confirm_image",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [Array.isArray(item.confirm_image) ? _c('span', [_vm._v(_vm._s(item.confirm_image.length) + "份")]) : _c('span', [_vm._v("无")])];
      }
    }, {
      key: "item.contract_status",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [item.contract_status === 0 ? _c('span', [_vm._v(" 未开始 ")]) : _vm._e(), item.contract_status === 1 ? _c('span', {
          staticClass: "success--text"
        }, [_vm._v(" 履约中 ")]) : _vm._e(), item.contract_status === 2 ? _c('span', {
          staticClass: "error--text"
        }, [_vm._v(" 已终止 ")]) : _vm._e()];
      }
    }, {
      key: "item.file_no",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [item.file_no ? _c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_vm._v(" " + _vm._s(item.file_no) + " ")]) : _c('span', [_vm._v("无")])];
      }
    }, {
      key: "item.href",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [item.contract_type === 1 ? _c('div', [item.attachment_image && item.attachment_image.length && (_vm.isEdit || _vm.checkOnly) ? _c('v-menu', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref13) {
              var _item$attachment_imag;
              var on = _ref13.on,
                attrs = _ref13.attrs;
              return [_c('span', _vm._g(_vm._b({}, 'span', attrs, false), on), [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$attachment_imag = item.attachment_image) === null || _item$attachment_imag === void 0 ? void 0 : _item$attachment_imag.length) + " ")]), _c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "x-small": "",
                  "icon": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("more_vert")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, _vm._l(item.attachment_image, function (file, index) {
          return _c('v-list-item', {
            key: '_file_' + index
          }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(file.substring(file.lastIndexOf('/') + 1, file.length)))])], 1), _c('v-list-item-action', [_c('v-btn', {
            attrs: {
              "icon": "",
              "small": ""
            },
            on: {
              "click": function click($event) {
                return _vm.downloadHref(file);
              }
            }
          }, [_c('v-icon', [_vm._v("download")])], 1)], 1)], 1);
        }), 1)], 1) : _c('span', [_vm._v("-")])], 1) : _c('div', [item.href && (_vm.isEdit || _vm.checkOnly) ? _c('span', [_c('v-btn', {
          attrs: {
            "text": "",
            "small": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.downloadHref(item.href);
            }
          }
        }, [_vm._v("下载")])], 1) : _c('span', [_vm._v("-")])])];
      }
    }, {
      key: "item.creator",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [item.creator ? _c('span', [_vm._v(_vm._s(item.creator.personal.name))]) : _vm._e()];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatTime")(item.createTime)) + " ")];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_vm.isEdit ? _c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref17) {
              var on = _ref17.on,
                attrs = _ref17.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "primary",
                  "dark": "",
                  "icon": "",
                  "small": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("more_vert")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [_vm.getType === 'agency' && (item.contract_status === 1 || item.contract_status === 0) ? _c('agency-customer-edit', {
          attrs: {
            "getType": item.business_type,
            "getId": _vm.getId
          }
        }, [_c('v-list-item', {
          staticClass: "v-list-item&#45;&#45;link",
          on: {
            "click": function click($event) {
              return _vm.doAction('default');
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("add")])], 1), _c('v-list-item-title', [_vm._v(" 添加客户 ")])], 1)], 1) : _vm._e(), _vm.getType === 'agency' && (item.contract_status === 1 || item.contract_status === 0) ? _c('v-list-item', {
          staticClass: "v-list-item&#45;&#45;link",
          on: {
            "click": function click($event) {
              return _vm.doAction('batch_create', item);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("upload_file")])], 1), _c('v-list-item-title', [_vm._v(" 导入客户 ")])], 1) : _vm._e(), item.contract_status === 1 || item.contract_status === 0 ? _c('v-list-item', {
          staticClass: "v-list-item&#45;&#45;link",
          on: {
            "click": function click($event) {
              return _vm.confirmBlock(item._id);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("block")])], 1), _c('v-list-item-title', [_vm._v(" 终止 ")])], 1) : _vm._e(), _c('v-list-item', {
          staticClass: "v-list-item&#45;&#45;link",
          on: {
            "click": function click($event) {
              return _vm.confirmDelete(item._id);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "error"
          }
        }, [_vm._v("delete")])], 1), _c('v-list-item-title', [_vm._v(" 删除 ")])], 1)], 1)], 1) : _c('span', [_vm._v("-")])];
      }
    }])
  }), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "400"
    },
    model: {
      value: _vm.warnDlg,
      callback: function callback($$v) {
        _vm.warnDlg = $$v;
      },
      expression: "warnDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.dlgTitle) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDlg
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "text-center py-6"
  }, [_vm.dlgTitle === '终止合同' ? _c('span', [_vm._v("确定终止此合同吗？")]) : _vm._e(), _vm.dlgTitle === '删除合同' ? _c('span', [_vm._v("确定删除此合同吗？")]) : _vm._e()]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeDlg
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _vm.dlgTitle === '终止合同' ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": _vm.blockContract
    }
  }, [_vm._v("确认终止")]) : _vm._e(), _vm.dlgTitle === '删除合同' ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": _vm.deleteContract
    }
  }, [_vm._v("确认删除")]) : _vm._e()], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "800px"
    },
    model: {
      value: _vm.imgDlg,
      callback: function callback($$v) {
        _vm.imgDlg = $$v;
      },
      expression: "imgDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 项目确认单 "), _vm.imgUrl ? _c('v-btn', {
    staticClass: "ml-2",
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('download_img');
      }
    }
  }, [_c('v-icon', [_vm._v("download")])], 1) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_img');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', [_c('v-img', {
    attrs: {
      "src": _vm.imgUrl,
      "contain": ""
    }
  }), _c('v-overlay', {
    staticClass: "align-center justify-center",
    attrs: {
      "absolute": ""
    },
    model: {
      value: _vm.overlay,
      callback: function callback($$v) {
        _vm.overlay = $$v;
      },
      expression: "overlay"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "color": "primary",
      "indeterminate": "",
      "size": "64"
    }
  })], 1)], 1)], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600px"
    },
    model: {
      value: _vm.dlgInfo,
      callback: function callback($$v) {
        _vm.dlgInfo = $$v;
      },
      expression: "dlgInfo"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 批量导入 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('close_info');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider')], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('p', [_vm._v("1. 下载新增模版，填写合作客户信息.")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('download_create_template');
      }
    }
  }, [_vm._v("下载模版"), _c('v-icon', [_vm._v("file_download")])], 1)], 1), _c('v-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('input', {
    ref: "uploadImport",
    attrs: {
      "id": "import_file",
      "type": "file",
      "name": "import_file",
      "accept": ".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.importCreateFiles($event);
      }
    }
  }), _c('p', [_vm._v("2. 上传填好的文件.")]), _c('v-btn', {
    attrs: {
      "text": "",
      "loading": !_vm.batch.import_end
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('upload_create_file');
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "dark": ""
    }
  }, [_vm._v("file_upload")]), _vm._v("上传文件")], 1)], 1)], 1), _vm.batch.import_failed.length ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-divider'), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "error",
      "text": ""
    }
  }, [_vm._v(" 导入失败："), _vm.batch.import_failed.length === 0 ? _c('span', [_vm._v("无")]) : _c('ul', _vm._l(_vm.batch.import_failed, function (fail, index) {
    return _c('li', {
      key: index
    }, [_vm._v("行：" + _vm._s(fail.line) + " 错误：" + _vm._s(fail.error))]);
  }), 0)]), _c('v-alert', {
    attrs: {
      "outlined": "",
      "type": "success",
      "text": ""
    }
  }, [_vm._v(" 成功创建：" + _vm._s(_vm.batch.import_result) + " 条记录。 ")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
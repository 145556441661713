var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isClientV,
      expression: "isClientV"
    }],
    ref: "waterMark",
    staticClass: "watermarkWrap"
  }, _vm._l(_vm.watermarkNum, function (i, index) {
    return _c('div', {
      key: index,
      staticClass: "wm",
      style: "color:".concat(_vm.textColor, ";transform: rotate(").concat(_vm.rotate, "deg);width:").concat(_vm.wm_w, "%;height:").concat(_vm.wm_h, "%")
    }, [_c('p', {
      staticClass: "text-h6"
    }, [_vm._v(_vm._s(_vm.watermarkText))])]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }
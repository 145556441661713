var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-snackbar', {
    attrs: {
      "bottom": "",
      "right": "",
      "value": _vm.updateExists,
      "timeout": -1,
      "color": "primary"
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "text": ""
          },
          on: {
            "click": _vm.refreshApp
          }
        }, 'v-btn', attrs, false), [_vm._v(" 更新 ")])];
      }
    }])
  }, [_vm._v(" 有新的功能调整。 ")]);

}
var staticRenderFns = []

export { render, staticRenderFns }
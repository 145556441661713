var render = function render(){
  var _vm$settleData$produc, _vm$settleData$produc2, _vm$settleData$agency2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticClass: "receiptTable caption",
    attrs: {
      "cellspacing": "0"
    }
  }, [_c('tr', [_c('th', {
    staticClass: "title py-4",
    attrs: {
      "colspan": "13"
    }
  }, [_vm._v(" " + _vm._s(_vm.settleData.settleTitle) + " ")])]), _vm._m(0), _vm._l(_vm.settleData.settleList, function (item, i) {
    var _vm$settleData$agency, _item$customer, _item$agency_ratio, _vm$settleData$settle, _vm$settleData$settle2, _vm$settleData$settle3, _vm$settleData$settle4, _vm$settleData$settle5;
    return _c('tr', {
      key: i
    }, [i === 0 ? _c('td', {
      staticClass: "text-center",
      staticStyle: {
        "width": "10%"
      },
      attrs: {
        "rowspan": _vm.settleData.settleList.length + 1
      }
    }, [_vm._v(_vm._s((_vm$settleData$agency = _vm.settleData.agency) === null || _vm$settleData$agency === void 0 ? void 0 : _vm$settleData$agency.name))]) : _vm._e(), _c('td', {
      staticClass: "text-center",
      staticStyle: {
        "width": "10%",
        "border-left": "0"
      }
    }, [_vm._v(_vm._s((_item$customer = item.customer) === null || _item$customer === void 0 ? void 0 : _item$customer.name))]), _c('td', {
      staticClass: "text-center",
      attrs: {
        "nowrap": "true"
      }
    }, [_vm._v(_vm._s(new Date(item.date).getFullYear()) + "年")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(new Date(item.date).getMonth() + 1) + "月")]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.xt_service_fee.$numberDecimal)))]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.xt_service_fee_no_tax.$numberDecimal)))]), i === 0 ? _c('td', {
      staticClass: "text-center",
      attrs: {
        "rowspan": _vm.settleData.settleList.length
      }
    }, [_vm._v(_vm._s(item.agency_ratio ? Number((_item$agency_ratio = item.agency_ratio) === null || _item$agency_ratio === void 0 ? void 0 : _item$agency_ratio.$numberDecimal).toFixed(2) + '%' : '-'))]) : _vm._e(), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.agency_service_fee_no_tax.$numberDecimal)))]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.tax.$numberDecimal)))]), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.agency_fee_no_tax.$numberDecimal)))]), i === 0 ? _c('td', {
      staticClass: "text-center",
      attrs: {
        "rowspan": _vm.settleData.settleList.length
      }
    }, [_vm._v(_vm._s(((_vm$settleData$settle = _vm.settleData.settleList) === null || _vm$settleData$settle === void 0 ? void 0 : (_vm$settleData$settle2 = _vm$settleData$settle[0]) === null || _vm$settleData$settle2 === void 0 ? void 0 : _vm$settleData$settle2.agency_invoice_type) === 1 ? '专票' : '普票'))]) : _vm._e(), i === 0 ? _c('td', {
      staticClass: "text-center",
      attrs: {
        "rowspan": _vm.settleData.settleList.length
      }
    }, [_vm._v(_vm._s(((_vm$settleData$settle3 = _vm.settleData.settleList) === null || _vm$settleData$settle3 === void 0 ? void 0 : (_vm$settleData$settle4 = _vm$settleData$settle3[0]) === null || _vm$settleData$settle4 === void 0 ? void 0 : (_vm$settleData$settle5 = _vm$settleData$settle4.agency_tax) === null || _vm$settleData$settle5 === void 0 ? void 0 : _vm$settleData$settle5.$numberDecimal) * 1) + "%")]) : _vm._e(), _c('td', {
      staticClass: "text-right"
    }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.agency_fee.$numberDecimal)))])]);
  }), _c('tr', [_c('td', {
    staticClass: "text-center",
    staticStyle: {
      "border-left": "0"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("合计")]), _c('td', {
    attrs: {
      "align": "right"
    }
  }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.xt_service_fee_total)))]), _c('td', {
    attrs: {
      "align": "right"
    }
  }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.xt_service_fee_no_tax_total)))]), _c('td', {
    attrs: {
      "align": "center"
    }
  }, [_vm._v("-")]), _c('td', {
    attrs: {
      "align": "right"
    }
  }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_service_fee_no_tax_total)))]), _c('td', {
    attrs: {
      "align": "right"
    }
  }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.tax_total)))]), _c('td', {
    attrs: {
      "align": "right"
    }
  }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_fee_no_tax_total)))]), _c('td', {
    attrs: {
      "align": "center"
    }
  }, [_vm._v("-")]), _c('td', {
    attrs: {
      "align": "center"
    }
  }, [_vm._v("-")]), _c('td', {
    attrs: {
      "align": "right"
    }
  }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_fee_total)))])]), _c('tr', {
    staticStyle: {
      "border-left": "1px solid #000",
      "border-right": "1px solid #000"
    }
  }, [_c('td', {
    staticClass: "text-center no-border font-weight-bold",
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("制表人：" + _vm._s((_vm$settleData$produc = _vm.settleData.producedBy) === null || _vm$settleData$produc === void 0 ? void 0 : (_vm$settleData$produc2 = _vm$settleData$produc.personal) === null || _vm$settleData$produc2 === void 0 ? void 0 : _vm$settleData$produc2.name))]), _c('td', {
    staticClass: "text-center no-border font-weight-bold",
    attrs: {
      "colspan": "3"
    }
  }, [_vm.comfirmors.length ? _vm._l(_vm.comfirmors, function (item, i) {
    return _c('v-row', {
      key: i
    }, [_c('v-col', {
      staticClass: "pr-0",
      attrs: {
        "cols": "6",
        "align": "right"
      }
    }, [i === 0 ? _c('span', [_vm._v("确认人：")]) : _vm._e()]), _c('v-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "6",
        "align": "left"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }) : [_vm._v(" 确认人： ")]], 2), _c('td', {
    staticClass: "text-center no-border font-weight-bold",
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_vm.approvers.length ? _vm._l(_vm.approvers, function (item, i) {
    return _c('v-row', {
      key: i
    }, [_c('v-col', {
      staticClass: "pr-0",
      attrs: {
        "cols": "6",
        "align": "right"
      }
    }, [i === 0 ? _c('span', [_vm._v("核定人：")]) : _vm._e()]), _c('v-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "6",
        "align": "left"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }) : [_vm._v(" 核定人： ")]], 2), _c('td', {
    staticClass: "text-center no-border font-weight-bold",
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      "colspan": "4"
    }
  }, [_vm.reviewers.length ? _vm._l(_vm.reviewers, function (item, i) {
    return _c('v-row', {
      key: i
    }, [_c('v-col', {
      staticClass: "pr-0",
      attrs: {
        "cols": "6",
        "align": "right"
      }
    }, [i === 0 ? _c('span', [_vm._v("复核人：")]) : _vm._e()]), _c('v-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "6",
        "align": "left"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }) : [_vm._v(" 复核人： ")]], 2)]), _c('tbody', {
    staticStyle: {
      "border": "1px solid #000"
    }
  }, [_c('tr', [_c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "6"
    }
  }, [_vm._v("甲方：北京鑫泰能源股份有限公司")]), _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "7"
    }
  }, [_vm._v("乙方：" + _vm._s((_vm$settleData$agency2 = _vm.settleData.agency) === null || _vm$settleData$agency2 === void 0 ? void 0 : _vm$settleData$agency2.name))])]), _c('tr', [this.reviewTime ? _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "6"
    }
  }, [_vm._v("日期：" + _vm._s(_vm.reviewTime.getFullYear() + '年' + (_vm.reviewTime.getMonth() + 1) + '月' + _vm.reviewTime.getDate() + '日'))]) : _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "6"
    }
  }, [_vm._v("日期："), _c('span', {
    staticClass: "pl-10",
    staticStyle: {
      "letter-spacing": "1.5rem"
    }
  }, [_vm._v("年月日")])]), this.reviewTime ? _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "7"
    }
  }, [_vm._v("日期：" + _vm._s(_vm.reviewTime.getFullYear() + '年' + (_vm.reviewTime.getMonth() + 1) + '月' + _vm.reviewTime.getDate() + '日'))]) : _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "7"
    }
  }, [_vm._v("日期："), _c('span', {
    staticClass: "pl-10",
    staticStyle: {
      "letter-spacing": "1.5rem"
    }
  }, [_vm._v("年月日")])])])])], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', [_vm._v("代理商名称")]), _c('th', [_vm._v("项目名称")]), _c('th', [_vm._v("年份")]), _c('th', [_vm._v("月份")]), _c('th', [_vm._v("甲方收益（含税）")]), _c('th', [_vm._v("甲方收益（不含税）")]), _c('th', [_vm._v("代理商"), _c('br'), _vm._v("分成比例")]), _c('th', [_vm._v("代理费用（不含税）")]), _c('th', [_vm._v("减：增值税差 - 税金及附加（元）")]), _c('th', [_vm._v("代理商不含税合计")]), _c('th', [_vm._v("发票类型")]), _c('th', [_vm._v("代理商"), _c('br'), _vm._v("发票税率")]), _c('th', [_vm._v("价税合计")])]);

}]

export { render, staticRenderFns }
import {ContractService} from "@/common/http";

import {
  CREATE_CONTRACT,
  FETCH_CONTRACT_LIST,
  FETCH_CONTRACT,
  FETCH_CONTRACT_CUSTOMER,
  EDIT_CONTRACT,
  REMOVE_CONTRACT,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_CONTRACT_LIST,
  RELOAD_CONTRACT_LIST,
  SET_CONTRACT_LIST,
  SET_CONTRACT_CUSTOMER,
  SET_CONTRACT,
} from "./mutations.type";

const state = {
  contractLoading: false,
  contractList: [],
  contractCustomerList: [],
  contractCount: 0,
  contractCustomerCount: 0,
  contractInfo: {},
  contractReload: false,
};

const getters = {
  contractLoading: state => state.contractLoading,
  contractList: state => state.contractList,
  contractCustomerList: state => state.contractCustomerList,
  contractCount: state => state.contractCount,
  contractCustomerCount: state => state.contractCustomerCount,
  contractInfo: state => state.contractInfo,
  contractReload: state => state.contractReload,
};

const actions = {
  [CREATE_CONTRACT](context, params) {
    return new Promise((resolve, reject) => {
      ContractService.create('', params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response);
        })
    });
  },
  [FETCH_CONTRACT]({ commit }, params) {
    commit(LOAD_CONTRACT_LIST);
    return new Promise((resolve, reject) => {
      ContractService.get('', params.query)
        .then(({ data }) => {
          commit(SET_CONTRACT, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [EDIT_CONTRACT](context, params) {
    return ContractService.update('/' +  params.id, params.item);
  },
  [REMOVE_CONTRACT](context, data) {
    return ContractService.delete('/' + data);
  },
  [FETCH_CONTRACT_LIST]({ commit }, params) {
    commit(LOAD_CONTRACT_LIST);
    return new Promise((resolve, reject) => {
      ContractService.get('/list', '?'+params.query)
        .then(({ data }) => {
          commit(SET_CONTRACT_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_CONTRACT_CUSTOMER]({ commit }, params) {
    commit(LOAD_CONTRACT_LIST);
    return new Promise((resolve, reject) => {
      ContractService.get('/list', '?'+params.query)
        .then(({ data }) => {
          commit(SET_CONTRACT_CUSTOMER, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
}

const mutations = {
  [LOAD_CONTRACT_LIST] (state) {
    state.contractLoading = true;
  },
  [RELOAD_CONTRACT_LIST] (state) {
    state.contractReload = !state.contractReload;
  },
  [SET_CONTRACT_LIST](state, list) {
    state.contractCount = list.query.count;
    state.contractList = list.docs;
    state.contractLoading = false;
  },
  [SET_CONTRACT](state, contract) {
    state.contractInfo = contract.contractInfo;
    state.contractLoading = false;
  },
  [SET_CONTRACT_CUSTOMER](state, list) {
    state.contractCustomerCount = list.query.count;
    state.contractCustomerList = list.docs;
    state.contractLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

var render = function render(){
  var _vm$settleData$produc, _vm$settleData$produc2, _vm$settleData$agency2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticClass: "receiptTable caption",
    attrs: {
      "cellspacing": "0"
    }
  }, [_c('tr', [_c('th', {
    staticClass: "title py-4",
    attrs: {
      "colspan": "13"
    }
  }, [_vm._v(" " + _vm._s(_vm.settleData.settleTitle) + " ")])]), _vm._m(0), _vm._l(_vm.settleData.settleList, function (item, i) {
    var _vm$settleData$settle, _vm$settleData$agency, _item$customer, _item$settle_elec_tot, _item$settle_elec_tot2, _item$agency_service_, _item$settle_elec_tot3, _item$agency_service_2, _item$agency_service_3, _vm$settleData$settle2, _vm$settleData$settle3, _vm$settleData$settle4, _vm$settleData$settle5, _vm$settleData$settle6, _item$tax, _item$agency_fee_no_t, _item$agency_fee;
    return _c('tr', {
      key: i
    }, [_c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(i + 1))]), i === 0 ? _c('td', {
      staticClass: "text-center",
      staticStyle: {
        "width": "10%"
      },
      attrs: {
        "rowspan": (_vm$settleData$settle = _vm.settleData.settleList) === null || _vm$settleData$settle === void 0 ? void 0 : _vm$settleData$settle.length
      }
    }, [_vm._v(_vm._s((_vm$settleData$agency = _vm.settleData.agency) === null || _vm$settleData$agency === void 0 ? void 0 : _vm$settleData$agency.name))]) : _vm._e(), _c('td', {
      staticClass: "text-center",
      staticStyle: {
        "width": "10%"
      }
    }, [_vm._v(_vm._s((_item$customer = item.customer) === null || _item$customer === void 0 ? void 0 : _item$customer.name))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s((((_item$settle_elec_tot = item.settle_elec_total) === null || _item$settle_elec_tot === void 0 ? void 0 : _item$settle_elec_tot.$numberDecimal) * 1).toFixed(3)))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm._f("formatMoney")(((_item$settle_elec_tot2 = item.settle_elec_total) === null || _item$settle_elec_tot2 === void 0 ? void 0 : _item$settle_elec_tot2.$numberDecimal) === '0' ? '0' : ((_item$agency_service_ = item.agency_service_fee) === null || _item$agency_service_ === void 0 ? void 0 : _item$agency_service_.$numberDecimal) / ((_item$settle_elec_tot3 = item.settle_elec_total) === null || _item$settle_elec_tot3 === void 0 ? void 0 : _item$settle_elec_tot3.$numberDecimal))))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm._f("formatMoney")(((_item$agency_service_2 = item.agency_service_fee) === null || _item$agency_service_2 === void 0 ? void 0 : _item$agency_service_2.$numberDecimal) * 1)))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm._f("formatMoney")(((_item$agency_service_3 = item.agency_service_fee_no_tax) === null || _item$agency_service_3 === void 0 ? void 0 : _item$agency_service_3.$numberDecimal) * 1)))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(((_vm$settleData$settle2 = _vm.settleData.settleList) === null || _vm$settleData$settle2 === void 0 ? void 0 : (_vm$settleData$settle3 = _vm$settleData$settle2[0]) === null || _vm$settleData$settle3 === void 0 ? void 0 : _vm$settleData$settle3.invoice_type) === 1 ? '专票' : '普票'))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(((_vm$settleData$settle4 = _vm.settleData.settleList) === null || _vm$settleData$settle4 === void 0 ? void 0 : (_vm$settleData$settle5 = _vm$settleData$settle4[0]) === null || _vm$settleData$settle5 === void 0 ? void 0 : (_vm$settleData$settle6 = _vm$settleData$settle5.agency_tax) === null || _vm$settleData$settle6 === void 0 ? void 0 : _vm$settleData$settle6.$numberDecimal) * 1) + "%")]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm._f("formatMoney")(((_item$tax = item.tax) === null || _item$tax === void 0 ? void 0 : _item$tax.$numberDecimal) * 1)))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm._f("formatMoney")(((_item$agency_fee_no_t = item.agency_fee_no_tax) === null || _item$agency_fee_no_t === void 0 ? void 0 : _item$agency_fee_no_t.$numberDecimal) * 1)))]), _c('td', {
      staticClass: "text-center"
    }, [_vm._v(_vm._s(_vm._f("formatMoney")(((_item$agency_fee = item.agency_fee) === null || _item$agency_fee === void 0 ? void 0 : _item$agency_fee.$numberDecimal) * 1)))]), i === 0 ? _c('td', {
      staticClass: "text-center",
      attrs: {
        "rowspan": _vm.settleData.settleList.length
      }
    }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.totalData.agency_fee_total)))]) : _vm._e()]);
  }), _c('tr', {
    attrs: {
      "align": "center"
    }
  }, [_c('td', {
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("合计")]), _c('td', [_vm._v(_vm._s(_vm.totalData.elec_total.toFixed(3)))]), _c('td', [_vm._v("-")]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.totalData.agency_service_fee_total)))]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.totalData.agency_service_fee_no_tax_total)))]), _c('td', [_vm._v("-")]), _c('td', [_vm._v("-")]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.totalData.tax_total)))]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.totalData.agency_fee_no_tax_total)))]), _c('td', [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.totalData.agency_fee_total)))]), _c('td', [_vm._v("-")])]), _c('tr', {
    staticStyle: {
      "border": "1px solid #000",
      "border-top": "0",
      "border-bottom": "0"
    }
  }, [_c('td', {
    staticClass: "text-center no-border font-weight-bold",
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      "colspan": !_vm.isAreaHB ? 3 : 2
    }
  }, [_vm._v("制表人：" + _vm._s((_vm$settleData$produc = _vm.settleData.producedBy) === null || _vm$settleData$produc === void 0 ? void 0 : (_vm$settleData$produc2 = _vm$settleData$produc.personal) === null || _vm$settleData$produc2 === void 0 ? void 0 : _vm$settleData$produc2.name))]), _c('td', {
    staticClass: "text-center no-border font-weight-bold",
    attrs: {
      "colspan": !_vm.isAreaHB ? 3 : 2
    }
  }, [_vm.comfirmors.length ? _vm._l(_vm.comfirmors, function (item, i) {
    return _c('v-row', {
      key: i
    }, [_c('v-col', {
      staticClass: "pr-0",
      attrs: {
        "cols": "6",
        "align": "right"
      }
    }, [i === 0 ? _c('span', [_vm._v("确认人：")]) : _vm._e()]), _c('v-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "6",
        "align": "left"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }) : [_vm._v(" 确认人： ")]], 2), _vm.isAreaHB ? _c('td', {
    staticClass: "text-center no-border font-weight-bold",
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("区域负责人：")]) : _vm._e(), _c('td', {
    staticClass: "text-center no-border font-weight-bold",
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      "colspan": !_vm.isAreaHB ? 4 : 3
    }
  }, [_vm.approvers.length ? _vm._l(_vm.approvers, function (item, i) {
    return _c('v-row', {
      key: i
    }, [_c('v-col', {
      staticClass: "pr-0",
      attrs: {
        "cols": "6",
        "align": "right"
      }
    }, [i === 0 ? _c('span', [_vm._v("核定人：")]) : _vm._e()]), _c('v-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "6",
        "align": "left"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }) : [_vm._v(" 核定人： ")]], 2), _c('td', {
    staticClass: "text-center no-border font-weight-bold",
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      "colspan": !_vm.isAreaHB ? 4 : 3
    }
  }, [_vm.reviewers.length ? _vm._l(_vm.reviewers, function (item, i) {
    return _c('v-row', {
      key: i
    }, [_c('v-col', {
      staticClass: "pr-0",
      attrs: {
        "cols": "6",
        "align": "right"
      }
    }, [i === 0 ? _c('span', [_vm._v("复核人：")]) : _vm._e()]), _c('v-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "6",
        "align": "left"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }) : [_vm._v(" 复核人： ")]], 2)]), _c('tbody', {
    staticStyle: {
      "border": "1px solid #000"
    }
  }, [_c('tr', [_c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "6"
    }
  }, [_vm._v("甲方：" + _vm._s(_vm.isAreaGD ? '北京鑫泰能源股份有限公司广东分公司' : '北京鑫泰能源股份有限公司'))]), _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "7"
    }
  }, [_vm._v("乙方：" + _vm._s((_vm$settleData$agency2 = _vm.settleData.agency) === null || _vm$settleData$agency2 === void 0 ? void 0 : _vm$settleData$agency2.name))])]), _c('tr', [this.reviewTime ? _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "6"
    }
  }, [_vm._v("日期：" + _vm._s(_vm.reviewTime.getFullYear() + '年' + (_vm.reviewTime.getMonth() + 1) + '月' + _vm.reviewTime.getDate() + '日'))]) : _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "6"
    }
  }, [_vm._v("日期："), _c('span', {
    staticClass: "pl-10",
    staticStyle: {
      "letter-spacing": "1.5rem"
    }
  }, [_vm._v("年月日")])]), this.reviewTime ? _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "7"
    }
  }, [_vm._v("日期：" + _vm._s(_vm.reviewTime.getFullYear() + '年' + (_vm.reviewTime.getMonth() + 1) + '月' + _vm.reviewTime.getDate() + '日'))]) : _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "7"
    }
  }, [_vm._v("日期："), _c('span', {
    staticClass: "pl-10",
    staticStyle: {
      "letter-spacing": "1.5rem"
    }
  }, [_vm._v("年月日")])])])])], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', [_vm._v("序号")]), _c('th', [_vm._v("代理商名称")]), _c('th', [_vm._v("用户名称")]), _c('th', [_vm._v("结算电量（兆瓦时）")]), _c('th', [_vm._v("代理商服务费单价（含税）（元/兆瓦时）")]), _c('th', [_vm._v("代理商服务费（含税）（元）")]), _c('th', [_vm._v("代理商服务费（不含税）（元）")]), _c('th', [_vm._v("专票/普票")]), _c('th', [_vm._v("代理商"), _c('br'), _vm._v("发票费率")]), _c('th', [_vm._v("税金及附加（元）")]), _c('th', [_vm._v("代理商费用（不含税）（元）")]), _c('th', [_vm._v("代理商费用（含税）（元）")]), _c('th', [_vm._v("总开票金额（元）")])]);

}]

export { render, staticRenderFns }
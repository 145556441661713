export default [
  {
    path: '/admin/distributor',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/admin/distributor'
    ),
    name: 'Distributor',
    meta: {
      title: '主体管理',
      icon: 'account_balance',
      roles: ['admin']
    }
  },
  {
    path: '/admin/distributor/edit/:id',
    name: 'Distributor_Edit',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/admin/distributor_edit'
    ),
    meta: {
      title: '主体编辑',
      roles: ['admin']
    }
  },
]

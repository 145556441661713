var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "outlined": ""
    }
  }, [_c('v-card-text', [_c('v-data-table', {
    staticClass: "elevation-0 mt-4",
    attrs: {
      "options": _vm.optionsItem,
      "headers": _vm.defaultHeader,
      "items": _vm.contractCustomerList,
      "item-class": _vm.row_class,
      "server-items-length": _vm.contractCustomerCount,
      "loading": _vm.contractLoading,
      "loading-text": "数据读取中... 请稍后",
      "footer-props": {
        'items-per-page-options': [5, 10, 15, 30, 50]
      },
      "transition": "",
      "fixed-header": ""
    },
    on: {
      "update:options": function updateOptions($event) {
        _vm.optionsItem = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "top",
      fn: function fn() {
        return [_c('v-toolbar', {
          attrs: {
            "flat": ""
          }
        }, [_c('v-row', [_c('v-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('v-text-field', {
          attrs: {
            "label": "客户名称",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "hide-details": ""
          },
          on: {
            "input": _vm.searchList
          },
          model: {
            value: _vm.query.customer_name,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "customer_name", $$v);
            },
            expression: "query.customer_name"
          }
        })], 1), _c('v-col', {
          attrs: {
            "cols": "3"
          }
        }, [_c('v-select', {
          attrs: {
            "items": _vm.areaList,
            "label": "客户区域",
            "item-text": "name",
            "item-value": "_id",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "hide-details": ""
          },
          on: {
            "change": _vm.searchList
          },
          model: {
            value: _vm.query.area,
            callback: function callback($$v) {
              _vm.$set(_vm.query, "area", $$v);
            },
            expression: "query.area"
          }
        })], 1)], 1)], 1)];
      },
      proxy: true
    }, {
      key: "item.project_name",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : item.project_name))])];
      }
    }, {
      key: "item.customer",
      fn: function fn(_ref2) {
        var _item$customer, _item$customer2, _item$customer3, _item$customer4;
        var item = _ref2.item;
        return [(_vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser._id === item.creator._id || _vm.currentUser._id === ((_item$customer = item.customer) === null || _item$customer === void 0 ? void 0 : _item$customer.creator) || _vm.currentUser._id === ((_item$customer2 = item.customer) === null || _item$customer2 === void 0 ? void 0 : _item$customer2.confirm_settle)) && item.approve_status !== 1 ? _c('agency-customer-edit', {
          attrs: {
            "getType": item.business_type,
            "getId": _vm.getId,
            "editData": item,
            "isEdit": true
          }
        }, [_c('td', {
          staticClass: "primary--text",
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s((_item$customer3 = item.customer) === null || _item$customer3 === void 0 ? void 0 : _item$customer3.name))])]) : _c('td', {
          attrs: {
            "nowrap": "true"
          }
        }, [_vm._v(_vm._s((_item$customer4 = item.customer) === null || _item$customer4 === void 0 ? void 0 : _item$customer4.name))])];
      }
    }, {
      key: "item.area",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [item.customer && item.customer.area ? _c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_vm._v(_vm._s(item.customer.area.name))]) : _vm._e()];
      }
    }, {
      key: "item.business_type",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [item.business_type === 1 ? _c('span', [_vm._v("能源")]) : _vm._e(), item.business_type === 2 ? _c('span', [_vm._v("调峰")]) : _vm._e(), item.business_type === 3 ? _c('span', [_vm._v("售电")]) : _vm._e(), item.business_type === 4 ? _c('span', [_vm._v("智科")]) : _vm._e()];
      }
    }, {
      key: "item.sign_type",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [item.sign_type === 1 ? _c('span', [_vm._v("比例分成")]) : _vm._e(), item.sign_type === 2 ? _c('span', [_vm._v("固定服务费")]) : _vm._e(), item.sign_type === 3 ? _c('span', [_vm._v("固定单价")]) : _vm._e(), item.sign_type === 5 ? _c('span', [_vm._v("其他")]) : _vm._e()];
      }
    }, {
      key: "item.sign_other",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [item.sign_other ? _c('span', [_vm._v(_vm._s(item.sign_other))]) : _vm._e()];
      }
    }, {
      key: "item.xt_ratio",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [item.xt_ratio ? _c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_vm._v(_vm._s(item.xt_ratio.toFixed(2)))]) : _vm._e()];
      }
    }, {
      key: "item.agency_ratio",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [item.agency_ratio ? _c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_vm._v(_vm._s(item.agency_ratio.toFixed(2)))]) : _vm._e()];
      }
    }, {
      key: "item.sharp_price",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [item.sharp_price ? _c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.sharp_price)))]) : _vm._e()];
      }
    }, {
      key: "item.peak_price",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [item.peak_price ? _c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.peak_price)))]) : _vm._e()];
      }
    }, {
      key: "item.plain_price",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [item.plain_price ? _c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.plain_price)))]) : _vm._e()];
      }
    }, {
      key: "item.valley_price",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [item.valley_price ? _c('td', {
          attrs: {
            "nowrap": "true",
            "align": "center"
          }
        }, [_vm._v(_vm._s(_vm._f("formatMoney")(item.valley_price)))]) : _vm._e()];
      }
    }, {
      key: "item.income_start",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.income_start)) + " ")];
      }
    }, {
      key: "item.contract_start",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.contract_start)) + " ")];
      }
    }, {
      key: "item.contract_end",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.contract_end)) + " ")];
      }
    }, {
      key: "item.confirm_image",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [item.confirm_image ? _c('v-btn', {
          attrs: {
            "text": "",
            "x-small": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('check_img', item.confirm_image);
            }
          }
        }, [_vm._v("查看")]) : _c('span', [_vm._v("无")])];
      }
    }, {
      key: "item.href",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [item.href ? _c('v-btn', {
          attrs: {
            "text": "",
            "x-small": "",
            "color": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.doAction('check_img', item.href);
            }
          }
        }, [_vm._v("查看")]) : _c('span', [_vm._v("无")])];
      }
    }, {
      key: "item.contract_status",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [item.contract_status === 0 ? _c('span', [_vm._v(" 未开始 ")]) : _vm._e(), item.contract_status === 1 ? _c('span', {
          staticClass: "success--text"
        }, [_vm._v(" 履约中 ")]) : _vm._e(), item.contract_status === 2 ? _c('span', {
          staticClass: "error--text"
        }, [_vm._v(" 已终止 ")]) : _vm._e()];
      }
    }, {
      key: "item.approve_status",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [item.approve_status === 0 ? _c('span', {
          staticClass: "error--text"
        }, [_vm._v(" 未审核 ")]) : _vm._e(), item.approve_status === 1 ? _c('span', [_vm._v(" 已审核 ")]) : _vm._e()];
      }
    }, {
      key: "item.creator",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [item.creator ? _c('span', [_vm._v(_vm._s(item.creator.personal.name))]) : _vm._e()];
      }
    }, {
      key: "item.createTime",
      fn: function fn(_ref21) {
        var item = _ref21.item;
        return [_vm._v(" " + _vm._s(_vm._f("formatTime")(item.createTime)) + " ")];
      }
    }, {
      key: "item.actions",
      fn: function fn(_ref22) {
        var item = _ref22.item;
        return [_c('v-menu', {
          attrs: {
            "offset-y": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref23) {
              var on = _ref23.on,
                attrs = _ref23.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                attrs: {
                  "color": "primary",
                  "dark": "",
                  "icon": "",
                  "small": ""
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', {
                attrs: {
                  "small": ""
                }
              }, [_vm._v("more_vert")])], 1)];
            }
          }], null, true)
        }, [_c('v-list', {
          attrs: {
            "dense": ""
          }
        }, [!item.approve_status && (_vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('Contract_Approve')) ? _c('v-list-item', {
          staticClass: "v-list-item&#45;&#45;link",
          on: {
            "click": function click($event) {
              return _vm.confirmApprove(item);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "primary"
          }
        }, [_vm._v("task")])], 1), _c('v-list-item-title', [_vm._v(" 审核 ")])], 1) : _vm._e(), item.contract_status === 1 && (_vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser._id === item.creator._id || _vm.currentUser._id === item.customer.confirm_settle) ? _c('v-list-item', {
          staticClass: "v-list-item&#45;&#45;link",
          on: {
            "click": function click($event) {
              return _vm.confirmBlock(item._id);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("block")])], 1), _c('v-list-item-title', [_vm._v(" 终止 ")])], 1) : _vm._e(), _vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser._id === item.creator._id || _vm.currentUser._id === item.customer.confirm_settle ? _c('v-list-item', {
          staticClass: "v-list-item&#45;&#45;link",
          on: {
            "click": function click($event) {
              return _vm.confirmDelete(item._id);
            }
          }
        }, [_c('v-list-item-icon', [_c('v-icon', {
          attrs: {
            "small": "",
            "color": "error"
          }
        }, [_vm._v("delete")])], 1), _c('v-list-item-title', [_vm._v(" 删除 ")])], 1) : _vm._e()], 1)], 1)];
      }
    }])
  }), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "400"
    },
    model: {
      value: _vm.warnDlg,
      callback: function callback($$v) {
        _vm.warnDlg = $$v;
      },
      expression: "warnDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.dlgTitle) + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDlg
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "text-center py-6"
  }, [_vm.dlgTitle === '审批客户' ? _c('span', [_vm._v("确定审核通过此客户吗？")]) : _vm._e(), _vm.dlgTitle === '终止合同' ? _c('span', [_vm._v("确定终止此合同吗？")]) : _vm._e(), _vm.dlgTitle === '删除合同' ? _c('span', [_vm._v("确定删除此合同吗？")]) : _vm._e()]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeDlg
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _vm.dlgTitle === '审批客户' ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary"
    },
    on: {
      "click": _vm.approveCustomer
    }
  }, [_vm._v("审核通过")]) : _vm._e(), _vm.dlgTitle === '终止合同' ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": _vm.blockContract
    }
  }, [_vm._v("确认终止")]) : _vm._e(), _vm.dlgTitle === '删除合同' ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "error"
    },
    on: {
      "click": _vm.deleteContract
    }
  }, [_vm._v("确认删除")]) : _vm._e()], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
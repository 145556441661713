import Vue from 'vue';
import VueMask from 'vue-the-mask'
import VueHtmlToPaper from 'vue-html-to-paper';
// import VueSocketIO from 'vue-socket.io';
// Vue.use(new VueSocketIO({
//   debug: true,
//   connection: process.env.VUE_APP_BASE_API,
// }))
Vue.use(VueMask);

const optsPrint = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://cdn.bootcdn.net/ajax/libs/vuetify/2.6.9/vuetify.min.css',
    'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css',
    './style/receipt.css',
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}
Vue.use(VueHtmlToPaper, optsPrint);
Vue.use(VueHtmlToPaper);

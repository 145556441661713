export const categories = (target) => {
  let objTarget = {}
  switch (target) {
    case 'permission':
      objTarget = [
        { title: '客户管理', name: 'Customer_Edit', icon: 'assignment' },
        { title: '客户管理-编辑', name: 'Customer_Edit_Detail', icon: 'assignment' },
        { title: '代理商管理', name: 'Agent_Edit', icon: 'assignment' },
        { title: '代理商管理-编辑', name: 'Agent_Edit_Detail', icon: 'assignment' },
        { title: '项目确认单-查看', name: 'Agent_Check_Confirm', icon: 'assignment' },
        { title: '合同管理', name: 'Contract_Edit', icon: 'assignment' },
        { title: '合同管理-编辑', name: 'Contract_Edit_Detail', icon: 'assignment' },
        { title: '合同管理-审核', name: 'Contract_Approve', icon: 'assignment' },
        { title: '结算管理-能源(作废)', name: 'Settle_Energy', icon: 'assignment' },
        { title: '结算管理-售电(作废)', name: 'Settle_Sale', icon: 'assignment' },
        { title: '结算管理-调峰(作废)', name: 'Settle_Peak', icon: 'assignment' },
        { title: '结算管理-智科(作废)', name: 'Settle_ZhiKe', icon: 'assignment' },
        { title: '结算管理-使用', name: 'Settle_Edit', icon: 'assignment' },
        { title: '结算工单-管理', name: 'Case_Edit_Admin', icon: 'assignment' },
        { title: '结算工单-使用', name: 'Case_Edit', icon: 'assignment' },
        { title: '结算工单-付款单', name: 'Case_Edit_Receipt', icon: 'assignment' },
        { title: '数据统计-公司', name: 'Report_Company', icon: 'bar_chart' },
        { title: '数据统计-代理商', name: 'Report_Agency', icon: 'bar_chart' },
        { title: '数据统计-代理商（汇总）', name: 'Report_Agency_Summary', icon: 'bar_chart' },
        { title: '数据统计-区域', name: 'Report_Area', icon: 'bar_chart' },
        { title: '储备客户', name: 'Filing_Edit', icon: 'assignment' },
        { title: '系统设置 > 组织权限', name: 'System_Organize', icon: 'settings' },
        { title: '系统设置 > 区域设置', name: 'System_Area', icon: 'settings' },
        { title: '系统设置 > 流程设置', name: 'System_Task', icon: 'settings' },
        { title: '系统设置 > 问题反馈', name: 'System_Bugs', icon: 'settings' },
        { title: '系统设置 > 日志分析', name: 'System_Logs', icon: 'settings' }
      ]
      break
    case 'electricType':
      objTarget = [
        { name: '工业', code: 1 },
        { name: '商业', code: 2 },
        { name: '居民', code: 3 }
      ]
      break
    case 'businessType':
      objTarget = [
        { name: '能源', code: 1 },
        { name: '调峰', code: 2 },
        { name: '售电', code: 3 },
        { name: '智科', code: 4 }
      ]
      break
    case 'otherBusinessType':
      objTarget = [
        { name: '售电业务', code: 3 },
        { name: '配电室合同（智科）', code: 4 },
        { name: '入市协议', code: 8 },
        { name: '电厂合同', code: 9 },
        { name: '采购合同', code: 5 },
        { name: '辅助服务（调峰）', code: 2 },
        { name: '煤炭业务', code: 10 },
      ]
      break
    case 'otherDept':
      objTarget = [
        { name: '发展运营部', code: 'YY' },
        { name: '财务部', code: 'CW' },
        { name: '鑫泰智科', code: 'PD' },
        { name: '能源管理部', code: 'NY' },
        { name: '营销部', code: 'YX' },
        { name: '市场部', code: 'SC' },
      ]
      break
    case 'signType':
      objTarget = [
        { name: '比例分成', code: 1 },
        { name: '固定服务费', code: 2 },
        { name: '固定单价', code: 3 },
        { name: '其他', code: 5 }
      ]
      break
    case 'businessGroup':
      objTarget = [
        { name: '能源', code: 1, icon: 'sunny_snowing' },
        { name: '调峰', code: 2, icon: 'electric_meter' },
        { name: '售电', code: 3, icon: 'offline_bolt' },
        { name: '智科', code: 4, icon: 'broadcast_on_personal' },
      ]
      break
    case 'dictEnable':
      objTarget = [
        { name: '全部', code: null},
        { name: '是', code: true},
        { name: '否', code: false},
      ]
      break
    case 'contractStatus':
      objTarget = [
        { name: '履约中', code: 1 },
        { name: '已终止', code: 2 },
      ]
      break
    case 'invoiceType':
      objTarget = [
        { name: '增值税普通发票', code: '0' },
        { name: '增值税专用发票', code: '1' },
      ]
      break
    case 'corpType':
      objTarget = [
        { name: "民营", code: 1 },
        { name: "外资", code: 2 },
        { name: "合资", code: 3 },
        { name: "事业单位", code: 4 },
      ]
      break
    case 'shortcutsLink':
      objTarget = [
        { name: "新增客户", code: 10, link: "/distributor/customer/edit", icon: "add_home", color: "light-green", roles: "Customer_Edit" },
        { name: "新增代理商", code: 20, link: "/distributor/agency/edit", icon: "add_home_work", color: "light-green ", roles: "Agent_Edit" },
        { name: "创建其他合同", code: 30, link: "/distributor/contract", icon: "note_add", color: "light-green ", roles: "Contract_Edit" },
        { name: "上传能源结算", code: 41, link: "/distributor/settle", icon: "upload_file", color: "primary", roles: "Settle_Edit" },
        { name: "上传售电结算", code: 42, link: "/distributor/settle", icon: "upload_file", color: "primary", roles: "Settle_Edit" },
        { name: "上传调峰结算", code: 43, link: "/distributor/settle", icon: "upload_file", color: "primary", roles: "Settle_Edit" },
        { name: "创建结算工单", code: 50, link: "/distributor/settle", icon: "task", color: "light-green ", roles: "Customer_Edit" },
        { name: "新增储备客户", code: 60, link: "/distributor/filing/edit/0", icon: "bookmark_added", color: "light-green ", roles: "Customer_Edit" },
        { name: "设置部门", code: 91, link: "/distributor/system/organize/department", icon: "schema", color: "amber", roles: "System_Organize" },
        { name: "配置权限", code: 92, link: "/distributor/system/organize/roles", icon: "admin_panel_settings", color: "amber", roles: "System_Organize" },
        { name: "维护区域", code: 93, link: "/distributor/system/area", icon: "hdr_strong", color: "amber", roles: "System_Area" },
      ]
      break
    case 'caseStatus':
      objTarget = [
        { name: "进行中", code: 1 },
        { name: "已取消", code: 2 },
        { name: "已完成", code: 4 },
      ]
      break
    case 'logsType':
      objTarget = [
        { name: '账号', code: 'Account' },
        { name: '经营主体', code: 'Distributor' },
        { name: '代理商', code: 'Agency' },
        { name: '储备客户', code: 'Filing' },
        { name: '部门', code: 'Department' },
        { name: '权限组', code: 'Group' },
        { name: '角色', code: 'Roles' },
        { name: '企业客户', code: 'Customer' },
        { name: '合同', code: 'Contract' },
        { name: '任务', code: 'Task' },
        { name: '附件备注', code: 'Notes' },
        { name: '结算工单', code: 'CaseSettle' },
        { name: '结算数据', code: 'Settle' },
        { name: '结算汇总数据', code: 'SettleSumData' },
      ]
  }
  return objTarget
};

export default {
  categories,
};

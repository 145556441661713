import {CustomerService} from "@/common/http";

import {
  CREATE_CUSTOMER,
  CREATE_CUSTOMER_QUICK,
  FETCH_CUSTOMER_LIST,
  FETCH_CUSTOMER,
  EDIT_CUSTOMER,
  REMOVE_CUSTOMER,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_CUSTOMER_LIST,
  SET_CUSTOMER_LIST,
  SET_CUSTOMER,
} from "./mutations.type";

const state = {
  customerLoading: false,
  customerList: [],
  customerCount: 0,
  customerInfo: {},
};

const getters = {
  customerLoading: state => state.customerLoading,
  customerList: state => state.customerList,
  customerCount: state => state.customerCount,
  customerInfo: state => state.customerInfo,
};

const actions = {
  [CREATE_CUSTOMER](context, params) {
    return new Promise((resolve, reject) => {
      CustomerService.create('customer', params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response);
        })
    });
  },
  [CREATE_CUSTOMER_QUICK](context, params) {
    return new Promise((resolve, reject) => {
      CustomerService.create('customerQuick', params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response);
        })
    });
  },
  [FETCH_CUSTOMER]({ commit }, params) {
    commit(LOAD_CUSTOMER_LIST);
    return new Promise((resolve, reject) => {
      CustomerService.get('', params.query)
        .then(({ data }) => {
          commit(SET_CUSTOMER, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [EDIT_CUSTOMER](context, params) {
    return CustomerService.update('/' +  params.id, params.item);
  },
  [REMOVE_CUSTOMER](context, data) {
    return CustomerService.delete('/' + data);
  },
  [FETCH_CUSTOMER_LIST]({ commit }, params) {
    commit(LOAD_CUSTOMER_LIST);
    return new Promise((resolve, reject) => {
      CustomerService.get('/list', '?'+params.query)
        .then(({ data }) => {
          commit(SET_CUSTOMER_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
}

const mutations = {
  [LOAD_CUSTOMER_LIST] (state) {
    state.customerLoading = true;
  },
  [SET_CUSTOMER_LIST](state, list) {
    state.customerCount = list.query.count;
    state.customerList = list.docs;
    state.customerLoading = false;
  },
  [SET_CUSTOMER](state, customer) {
    state.customerInfo = customer.customerInfo;
    state.customerLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

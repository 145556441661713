var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "width": "550"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({
          on: {
            "click": _vm.checkData
          }
        }, 'span', attrs, false), on), [_vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2)];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "width": "600"
    }
  }, [_c('v-card-title', [_c('span', [_vm._v("编辑合同")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-6"
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.saveSubmit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [(_vm.getType === 'customer' || _vm.getType === 'agency') && !_vm.reSign ? _c('v-select', {
    attrs: {
      "items": _vm.userBusinessType,
      "label": "合同类型*",
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "prepend-icon": "list",
      "disabled": _vm.getType === 'agency' && !_vm.isEdit && _vm.checkOnly || _vm.contractItem.isForce
    },
    model: {
      value: _vm.contractItem.business_type,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "business_type", $$v);
      },
      expression: "contractItem.business_type"
    }
  }) : _vm._e(), _vm.getType === 'customer' && !_vm.reSign ? _c('v-text-field', {
    attrs: {
      "label": "合同名称*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "prepend-icon": "gavel"
    },
    model: {
      value: _vm.contractItem.contract_name,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "contract_name", $$v);
      },
      expression: "contractItem.contract_name"
    }
  }) : _vm._e(), !_vm.reSign ? _c('v-text-field', {
    attrs: {
      "label": "档案号",
      "outlined": "",
      "dense": "",
      "prepend-icon": "pin",
      "disabled": _vm.getType === 'agency' && !_vm.isEdit && _vm.checkOnly || _vm.contractItem.isForce
    },
    model: {
      value: _vm.contractItem.file_no,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "file_no", $$v);
      },
      expression: "contractItem.file_no"
    }
  }) : _vm._e(), _vm.getType === 'customer' && !_vm.reSign ? _c('v-text-field', {
    attrs: {
      "label": "年用电量*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number",
      "suffix": "兆瓦时",
      "prepend-icon": "bolt"
    },
    model: {
      value: _vm.contractItem.electric_use,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "electric_use", $$v);
      },
      expression: "contractItem.electric_use"
    }
  }) : _vm._e(), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_vm.getType === 'customer' || _vm.getType === 'agency' ? _c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "签约时间*",
            "rules": [_vm.rules.required],
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "prepend-icon": "fact_check",
            "disabled": _vm.getType === 'agency' && !_vm.isEdit && _vm.checkOnly || _vm.contractItem.isForce
          },
          model: {
            value: _vm.contractItem.sign_date,
            callback: function callback($$v) {
              _vm.$set(_vm.contractItem, "sign_date", $$v);
            },
            expression: "contractItem.sign_date"
          }
        }, 'v-text-field', attrs, false), on)) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.sign_date_menu,
      callback: function callback($$v) {
        _vm.sign_date_menu = $$v;
      },
      expression: "sign_date_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.sign_date_menu = false;
      }
    },
    model: {
      value: _vm.contractItem.sign_date,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "sign_date", $$v);
      },
      expression: "contractItem.sign_date"
    }
  })], 1), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_vm.getType === 'customer' || _vm.getType === 'agency' ? _c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "合同开始日期*",
            "rules": [_vm.rules.required],
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "prepend-icon": "event_available",
            "disabled": _vm.getType === 'agency' && !_vm.isEdit && _vm.checkOnly || _vm.contractItem.isForce
          },
          model: {
            value: _vm.contractItem.contract_start,
            callback: function callback($$v) {
              _vm.$set(_vm.contractItem, "contract_start", $$v);
            },
            expression: "contractItem.contract_start"
          }
        }, 'v-text-field', attrs, false), on)) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.contract_start_menu,
      callback: function callback($$v) {
        _vm.contract_start_menu = $$v;
      },
      expression: "contract_start_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.contract_start_menu = false;
      }
    },
    model: {
      value: _vm.contractItem.contract_start,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "contract_start", $$v);
      },
      expression: "contractItem.contract_start"
    }
  })], 1), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_vm.getType === 'customer' || _vm.getType === 'agency' ? _c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "合同结束日期*",
            "rules": [_vm.rules.required],
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "prepend-icon": "event_busy",
            "disabled": _vm.getType === 'agency' && !_vm.isEdit && _vm.checkOnly || _vm.contractItem.isForce
          },
          model: {
            value: _vm.contractItem.contract_end,
            callback: function callback($$v) {
              _vm.$set(_vm.contractItem, "contract_end", $$v);
            },
            expression: "contractItem.contract_end"
          }
        }, 'v-text-field', attrs, false), on)) : _vm._e()];
      }
    }]),
    model: {
      value: _vm.contract_end_menu,
      callback: function callback($$v) {
        _vm.contract_end_menu = $$v;
      },
      expression: "contract_end_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.contract_end_menu = false;
      }
    },
    model: {
      value: _vm.contractItem.contract_end,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "contract_end", $$v);
      },
      expression: "contractItem.contract_end"
    }
  })], 1), _vm.getType === 'customer' && !_vm.reSign ? _c('v-text-field', {
    attrs: {
      "label": "签约电价*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number",
      "suffix": "元",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.contractItem.price,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "price", $$v);
      },
      expression: "contractItem.price"
    }
  }) : _vm._e(), _vm.getType === 'customer' && !_vm.reSign ? _c('v-select', {
    attrs: {
      "items": _vm.signType,
      "label": "签约方式*",
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "prepend-icon": "checklist"
    },
    model: {
      value: _vm.contractItem.sign_type,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "sign_type", $$v);
      },
      expression: "contractItem.sign_type"
    }
  }) : _vm._e(), _vm.getType === 'customer' && _vm.contractItem.sign_type === 5 && !_vm.reSign ? _c('v-text-field', {
    attrs: {
      "label": "签约方式说明",
      "outlined": "",
      "dense": "",
      "prepend-icon": "assignment"
    },
    model: {
      value: _vm.contractItem.sign_other,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "sign_other", $$v);
      },
      expression: "contractItem.sign_other"
    }
  }) : _vm._e(), _vm.getType === 'customer' && _vm.contractItem.sign_type === 1 && !_vm.reSign ? _c('v-text-field', {
    attrs: {
      "label": "鑫泰比例*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number",
      "suffix": "%",
      "prepend-icon": "group_add"
    },
    model: {
      value: _vm.contractItem.xt_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "xt_ratio", $$v);
      },
      expression: "contractItem.xt_ratio"
    }
  }) : _vm._e(), _vm.getType === 'customer' && _vm.contractItem.sign_type === 1 && !_vm.reSign ? _c('v-text-field', {
    attrs: {
      "label": "客户比例*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number",
      "suffix": "%",
      "prepend-icon": "group_remove"
    },
    model: {
      value: _vm.contractItem.customer_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "customer_ratio", $$v);
      },
      expression: "contractItem.customer_ratio"
    }
  }) : _vm._e(), _vm.getType === 'customer' && _vm.contractItem.sign_type === 3 && !_vm.reSign ? _c('v-text-field', {
    attrs: {
      "label": "固定单价*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number",
      "suffix": "元/兆瓦时",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.contractItem.fixed_price,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "fixed_price", $$v);
      },
      expression: "contractItem.fixed_price"
    }
  }) : _vm._e(), _vm.getType === 'customer' && _vm.contractItem.sign_type === 2 && !_vm.reSign ? _c('v-text-field', {
    attrs: {
      "label": "固定服务费*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number",
      "suffix": "元",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.contractItem.fixed_fee,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "fixed_fee", $$v);
      },
      expression: "contractItem.fixed_fee"
    }
  }) : _vm._e(), !_vm.reSign ? _c('v-textarea', {
    attrs: {
      "label": "备注",
      "autocomplete": "off",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "no-resize": "",
      "prepend-icon": "comment",
      "disabled": _vm.getType === 'agency' && !_vm.isEdit && _vm.checkOnly || _vm.contractItem.isForce
    },
    model: {
      value: _vm.contractItem.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "remark", $$v);
      },
      expression: "contractItem.remark"
    }
  }) : _vm._e(), _vm.getType === 'customer' && !_vm.reSign ? _c('v-list', {
    attrs: {
      "dense": ""
    }
  }, [_vm._l(this.preAttUrl, function (n, i) {
    return _c('v-list-item', {
      key: i + '_preatt',
      staticClass: "px-0"
    }, [_c('v-list-item-avatar', {
      attrs: {
        "rounded": "0",
        "size": "42"
      }
    }, [_c('v-icon', {
      attrs: {
        "x-large": ""
      }
    }, [_vm._v("description")])], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', [_c('v-btn', {
      attrs: {
        "text": "",
        "small": "",
        "color": "primary"
      },
      on: {
        "click": function click($event) {
          return _vm.downloadFile(n);
        }
      }
    }, [_vm._v(_vm._s(_vm.contractItem.attachment_image[i].substring(_vm.contractItem.attachment_image[i].lastIndexOf('/') + 1, _vm.contractItem.attachment_image[i].length)))])], 1)], 1), _c('v-list-item-action', [_c('v-btn', {
      attrs: {
        "icon": "",
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.removeImg('customer', i);
        }
      }
    }, [_c('v-icon', [_vm._v("delete")])], 1)], 1)], 1);
  }), _c('v-list-item', {
    staticClass: "px-0"
  }, [_c('v-list-item-content'), _c('v-list-item-action', [_c('v-btn', {
    attrs: {
      "depressed": "",
      "small": ""
    },
    on: {
      "click": function click($event) {
        return _vm.clickUploadInput('customer', -1);
      }
    }
  }, [_c('v-icon', [_vm._v("upload")]), _vm._v(" 上传附件 ")], 1)], 1)], 1)], 2) : _vm._e(), _vm.getType === 'agency' ? _c('v-list', {
    attrs: {
      "subheader": "",
      "dense": ""
    }
  }, [_c('v-list-item', {
    staticClass: "px-0"
  }, [_c('v-list-item-avatar', {
    attrs: {
      "rounded": "0",
      "size": "80"
    }
  }, [_c('v-icon', {
    attrs: {
      "x-large": ""
    }
  }, [_vm._v("description")])], 1), _c('v-list-item-content', [_c('v-list-item-subtitle', {
    staticClass: "caption"
  }, [_c('div', [_vm._v("合同文件，支持图片、PDF、Word格式及压缩包")]), _vm.contractItem.file_name ? _c('div', {
    staticClass: "text-truncate"
  }, [_vm._v("当前文件："), _c('span', {
    staticClass: "primary--text"
  }, [_vm._v(_vm._s(_vm.contractItem.file_name))])]) : _vm._e()])], 1), _c('v-list-item-action', [_c('v-row', [_c('v-col', [_vm.contractItem.file_name ? _c('v-btn', {
    attrs: {
      "icon": "",
      "small": "",
      "disabled": !_vm.isEdit && _vm.checkOnly || _vm.contractItem.isForce
    },
    on: {
      "click": function click($event) {
        return _vm.removeImg('license_img');
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1) : _vm._e()], 1), _c('v-col', [_c('v-btn', {
    attrs: {
      "icon": "",
      "small": "",
      "disabled": !_vm.isEdit && _vm.checkOnly || _vm.contractItem.isForce
    },
    on: {
      "click": function click($event) {
        return _vm.clickUploadInput('license_img');
      }
    }
  }, [_c('v-icon', [_vm._v("upload")])], 1)], 1)], 1)], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-subheader', [_vm._v("项目确认单")]), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm._l(this.preUrl, function (n, i) {
    return _c('v-card', {
      key: i,
      staticClass: "ma-2",
      attrs: {
        "outlined": "",
        "tile": ""
      }
    }, [_c('v-hover', {
      scopedSlots: _vm._u([{
        key: "default",
        fn: function fn(_ref5) {
          var hover = _ref5.hover;
          return [n.substring(n.lastIndexOf('.') + 1, n.indexOf('?')).toLocaleLowerCase() === 'jpg' || n.substring(n.lastIndexOf('.') + 1, n.indexOf('?')).toLocaleLowerCase() === 'png' ? _c('v-img', {
            staticClass: "ma-2",
            attrs: {
              "src": n,
              "max-width": 125
            }
          }, [_c('v-fade-transition', [hover ? _c('v-overlay', {
            staticClass: "align-center",
            attrs: {
              "absolute": "",
              "color": "white lighten-5"
            }
          }, [_c('v-btn', {
            attrs: {
              "icon": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.downloadFile(n);
              }
            }
          }, [_c('v-icon', [_vm._v("get_app")])], 1), _c('v-btn', {
            attrs: {
              "icon": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.clickUploadInput('confirm_img', i);
              }
            }
          }, [_c('v-icon', [_vm._v("backup")])], 1), _c('v-btn', {
            attrs: {
              "icon": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.removeImg('confirm_img', i);
              }
            }
          }, [_c('v-icon', [_vm._v("delete")])], 1)], 1) : _vm._e()], 1)], 1) : _c('v-sheet', {
            staticClass: "d-flex align-center px-2",
            attrs: {
              "color": "white",
              "height": "50",
              "width": "200"
            }
          }, [_vm._v(" " + _vm._s(_vm.contractItem.confirm_image[i].substring(_vm.contractItem.confirm_image[i].lastIndexOf('/') + 1, _vm.contractItem.confirm_image[i].length)) + " "), _c('v-fade-transition', [hover ? _c('v-overlay', {
            staticClass: "align-center",
            attrs: {
              "absolute": "",
              "color": "white lighten-5"
            }
          }, [_c('v-btn', {
            attrs: {
              "icon": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.downloadFile(n);
              }
            }
          }, [_c('v-icon', [_vm._v("get_app")])], 1), _c('v-btn', {
            attrs: {
              "icon": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.clickUploadInput('confirm_img', i);
              }
            }
          }, [_c('v-icon', [_vm._v("backup")])], 1), _c('v-btn', {
            attrs: {
              "icon": "",
              "color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.removeImg('confirm_img', i);
              }
            }
          }, [_c('v-icon', [_vm._v("delete")])], 1)], 1) : _vm._e()], 1)], 1)];
        }
      }], null, true)
    })], 1);
  }), _c('v-card', {
    staticClass: "ma-3 pa-6",
    attrs: {
      "outlined": "",
      "tile": "",
      "hover": ""
    },
    on: {
      "click": function click($event) {
        return _vm.clickUploadInput('confirm_img', -1);
      }
    }
  }, [_c('v-icon', [_vm._v("add")])], 1)], 2)], 1)], 1)], 1) : _vm._e()], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _vm.isEdit ? _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.saveSubmit
    }
  }, [_vm._v("保存")]) : _vm._e()], 1)], 1), _c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": ".jpg, .jpeg, .png, .gif, application/msword, application/pdf, .zip, .rar, application/x-rar-compressed, application/zip, application/x-zip-compressed, application/octet-stream",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "550px"
    },
    model: {
      value: _vm.Upload.uploading,
      callback: function callback($$v) {
        _vm.$set(_vm.Upload, "uploading", $$v);
      },
      expression: "Upload.uploading"
    }
  }, [_c('v-card', {
    attrs: {
      "color": "secondary",
      "dark": ""
    }
  }, [_c('v-card-title', [_vm._v("文件上传中...")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-progress-linear', {
    attrs: {
      "color": "white",
      "value": _vm.Upload.progressUpload
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
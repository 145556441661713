var render = function render(){
  var _vm$settleData$agency, _vm$settleData$settle, _vm$settleData$settle2, _vm$settleData$settle3, _vm$settleData$settle4, _vm$settleData$produc, _vm$settleData$produc2, _vm$settleData$agency2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('table', {
    staticClass: "receiptTable caption",
    attrs: {
      "cellspacing": "0"
    }
  }, [_c('tr', [_c('th', {
    staticClass: "title py-4",
    attrs: {
      "colspan": "7"
    }
  }, [_vm._v(" " + _vm._s(_vm.settleData.settleTitle) + " ")])]), _vm._m(0), _c('tr', [_c('td', {
    staticClass: "text-center",
    staticStyle: {
      "width": "10%"
    }
  }, [_vm._v(_vm._s((_vm$settleData$agency = _vm.settleData.agency) === null || _vm$settleData$agency === void 0 ? void 0 : _vm$settleData$agency.name))]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(new Date((_vm$settleData$settle = _vm.settleData.settleList[0]) === null || _vm$settleData$settle === void 0 ? void 0 : _vm$settleData$settle.date).getFullYear()) + "年")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(new Date((_vm$settleData$settle2 = _vm.settleData.settleList[0]) === null || _vm$settleData$settle2 === void 0 ? void 0 : _vm$settleData$settle2.date).getMonth() + 1) + "月")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.project_income_total)))]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(Number((_vm$settleData$settle3 = _vm.settleData.settleList[0]) === null || _vm$settleData$settle3 === void 0 ? void 0 : (_vm$settleData$settle4 = _vm$settleData$settle3.agency_ratio) === null || _vm$settleData$settle4 === void 0 ? void 0 : _vm$settleData$settle4.$numberDecimal).toFixed(2)) + "%")]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_service_fee_no_tax_total)))]), _c('td', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm._f("formatMoney")(_vm.agency_fee_total)))])]), _c('tr', {
    staticStyle: {
      "border": "1px solid #000",
      "border-top": "0",
      "border-bottom": "0"
    }
  }, [_c('td', {
    staticClass: "text-left no-border font-weight-bold pl-6",
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      "colspan": "2"
    }
  }, [_vm._v("制表人：" + _vm._s((_vm$settleData$produc = _vm.settleData.producedBy) === null || _vm$settleData$produc === void 0 ? void 0 : (_vm$settleData$produc2 = _vm$settleData$produc.personal) === null || _vm$settleData$produc2 === void 0 ? void 0 : _vm$settleData$produc2.name))]), _c('td', {
    staticClass: "text-left no-border font-weight-bold pl-6",
    attrs: {
      "colspan": "2"
    }
  }, [_vm.comfirmors.length ? _vm._l(_vm.comfirmors, function (item, i) {
    return _c('v-row', {
      key: i
    }, [_c('v-col', {
      staticClass: "pr-0",
      attrs: {
        "cols": "6",
        "align": "right"
      }
    }, [i === 0 ? _c('span', [_vm._v("确认人：")]) : _vm._e()]), _c('v-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "6",
        "align": "left"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }) : [_vm._v(" 确认人： ")]], 2), _c('td', {
    staticClass: "text-left no-border font-weight-bold pl-6",
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      "colspan": "2"
    }
  }, [_vm.approvers.length ? _vm._l(_vm.approvers, function (item, i) {
    return _c('v-row', {
      key: i
    }, [_c('v-col', {
      staticClass: "pr-0",
      attrs: {
        "cols": "6",
        "align": "right"
      }
    }, [i === 0 ? _c('span', [_vm._v("核定人：")]) : _vm._e()]), _c('v-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "6",
        "align": "left"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }) : [_vm._v(" 核定人： ")]], 2), _c('td', {
    staticClass: "text-left no-border font-weight-bold pl-6",
    staticStyle: {
      "vertical-align": "top"
    },
    attrs: {
      "colspan": "2"
    }
  }, [_vm.reviewers.length ? _vm._l(_vm.reviewers, function (item, i) {
    return _c('v-row', {
      key: i
    }, [_c('v-col', {
      staticClass: "pr-0",
      attrs: {
        "cols": "6",
        "align": "right"
      }
    }, [i === 0 ? _c('span', [_vm._v("复核人：")]) : _vm._e()]), _c('v-col', {
      staticClass: "pl-0",
      attrs: {
        "cols": "6",
        "align": "left"
      }
    }, [_vm._v(" " + _vm._s(item) + " ")])], 1);
  }) : [_vm._v(" 复核人： ")]], 2)]), _c('tbody', {
    staticStyle: {
      "border": "1px solid #000"
    }
  }, [_c('tr', [_c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("甲方：" + _vm._s((_vm$settleData$agency2 = _vm.settleData.agency) === null || _vm$settleData$agency2 === void 0 ? void 0 : _vm$settleData$agency2.name))]), _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("乙方：北京鑫泰能源股份有限公司")])]), _c('tr', [this.reviewTime ? _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("日期：" + _vm._s(_vm.reviewTime.getFullYear() + '年' + (_vm.reviewTime.getMonth() + 1) + '月' + _vm.reviewTime.getDate() + '日'))]) : _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "3"
    }
  }, [_vm._v("日期："), _c('span', {
    staticClass: "pl-10",
    staticStyle: {
      "letter-spacing": "1.5rem"
    }
  }, [_vm._v("年月日")])]), this.reviewTime ? _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("日期：" + _vm._s(_vm.reviewTime.getFullYear() + '年' + (_vm.reviewTime.getMonth() + 1) + '月' + _vm.reviewTime.getDate() + '日'))]) : _c('td', {
    staticClass: "text-center no-border",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v("日期："), _c('span', {
    staticClass: "pl-10",
    staticStyle: {
      "letter-spacing": "1.5rem"
    }
  }, [_vm._v("年月日")])])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', {
    attrs: {
      "width": "15%"
    }
  }, [_vm._v("客户名称")]), _c('th', [_vm._v("年份")]), _c('th', [_vm._v("月份")]), _c('th', [_vm._v("当月总收益（含税13%）")]), _c('th', [_vm._v("甲方分成比例")]), _c('th', [_vm._v("甲方当月收益（不含税）")]), _c('th', [_vm._v("甲方当月收益（含税合计）")])]);

}]

export { render, staticRenderFns }
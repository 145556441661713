var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-snackbar', {
    attrs: {
      "multi-line": "",
      "color": _vm.color,
      "timeout": _vm.timeout,
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(_ref) {
        var attrs = _ref.attrs;
        return [_c('v-btn', _vm._b({
          attrs: {
            "text": "",
            "icon": ""
          },
          on: {
            "click": function click($event) {
              _vm.show = false;
            }
          }
        }, 'v-btn', attrs, false), [_c('v-icon', [_vm._v("highlight_off")])], 1)];
      }
    }]),
    model: {
      value: _vm.show,
      callback: function callback($$v) {
        _vm.show = $$v;
      },
      expression: "show"
    }
  }, [_vm._v(" " + _vm._s(_vm.message) + " "), _c('v-spacer')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
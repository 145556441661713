var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "width": "550"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({
          on: {
            "click": _vm.checkData
          }
        }, 'span', attrs, false), on), [_vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2)];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "width": "600"
    }
  }, [_c('v-card-title', [_c('span', [_vm._v("合作客户")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-6"
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.saveSubmit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-combobox', {
    ref: "customerBox",
    attrs: {
      "loading": _vm.customerLoading,
      "items": _vm.customerList,
      "search-input": _vm.searchCustomer,
      "item-text": "name",
      "item-value": "_id",
      "return-object": "",
      "autocomplete": "off",
      "outlined": "",
      "dense": "",
      "label": "客户名称*",
      "hint": "请查找并选择客户",
      "prepend-icon": "emoji_people"
    },
    on: {
      "update:searchInput": function updateSearchInput($event) {
        _vm.searchCustomer = $event;
      },
      "update:search-input": function updateSearchInput($event) {
        _vm.searchCustomer = $event;
      }
    },
    model: {
      value: _vm.contractItem.customer,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "customer", $$v);
      },
      expression: "contractItem.customer"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "项目名称",
      "outlined": "",
      "dense": "",
      "prepend-icon": "gavel"
    },
    model: {
      value: _vm.contractItem.project_name,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "project_name", $$v);
      },
      expression: "contractItem.project_name"
    }
  }), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on,
          attrs = _ref2.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "收益开始时间",
            "readonly": "",
            "outlined": "",
            "dense": "",
            "clearable": "",
            "prepend-icon": "event_available"
          },
          model: {
            value: _vm.contractItem.income_start,
            callback: function callback($$v) {
              _vm.$set(_vm.contractItem, "income_start", $$v);
            },
            expression: "contractItem.income_start"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.income_start_menu,
      callback: function callback($$v) {
        _vm.income_start_menu = $$v;
      },
      expression: "income_start_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.income_start_menu = false;
      }
    },
    model: {
      value: _vm.contractItem.income_start,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "income_start", $$v);
      },
      expression: "contractItem.income_start"
    }
  })], 1), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on,
          attrs = _ref3.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "项目确认单开始时间*",
            "readonly": "",
            "outlined": "",
            "rules": [_vm.rules.required],
            "dense": "",
            "clearable": "",
            "prepend-icon": "event_available"
          },
          model: {
            value: _vm.contractItem.contract_start,
            callback: function callback($$v) {
              _vm.$set(_vm.contractItem, "contract_start", $$v);
            },
            expression: "contractItem.contract_start"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.contract_start_menu,
      callback: function callback($$v) {
        _vm.contract_start_menu = $$v;
      },
      expression: "contract_start_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.contract_start_menu = false;
      }
    },
    model: {
      value: _vm.contractItem.contract_start,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "contract_start", $$v);
      },
      expression: "contractItem.contract_start"
    }
  })], 1), _c('v-menu', {
    ref: "sign_date",
    attrs: {
      "close-on-content-click": false,
      "transition": "scale-transition",
      "offset-y": "",
      "min-width": "auto"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref4) {
        var on = _ref4.on,
          attrs = _ref4.attrs;
        return [_c('v-text-field', _vm._g(_vm._b({
          attrs: {
            "label": "项目确认单结束时间*",
            "readonly": "",
            "outlined": "",
            "rules": [_vm.rules.required],
            "dense": "",
            "clearable": "",
            "prepend-icon": "event_busy"
          },
          model: {
            value: _vm.contractItem.contract_end,
            callback: function callback($$v) {
              _vm.$set(_vm.contractItem, "contract_end", $$v);
            },
            expression: "contractItem.contract_end"
          }
        }, 'v-text-field', attrs, false), on))];
      }
    }]),
    model: {
      value: _vm.contract_end_menu,
      callback: function callback($$v) {
        _vm.contract_end_menu = $$v;
      },
      expression: "contract_end_menu"
    }
  }, [_c('v-date-picker', {
    attrs: {
      "no-title": "",
      "scrollable": ""
    },
    on: {
      "input": function input($event) {
        _vm.contract_end_menu = false;
      }
    },
    model: {
      value: _vm.contractItem.contract_end,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "contract_end", $$v);
      },
      expression: "contractItem.contract_end"
    }
  })], 1), _c('v-text-field', {
    attrs: {
      "label": "客户合作条件*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "prepend-icon": "gavel"
    },
    model: {
      value: _vm.contractItem.condition,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "condition", $$v);
      },
      expression: "contractItem.condition"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "允许客户偏差免考范围*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "suffix": "%",
      "prepend-icon": "unfold_less"
    },
    model: {
      value: _vm.contractItem.allow_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "allow_ratio", $$v);
      },
      expression: "contractItem.allow_ratio"
    }
  }), _c('v-select', {
    attrs: {
      "items": _vm.signType,
      "label": "签约方式*",
      "rules": [_vm.rules.selected],
      "item-text": "name",
      "item-value": "code",
      "outlined": "",
      "dense": "",
      "prepend-icon": "checklist"
    },
    model: {
      value: _vm.contractItem.sign_type,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "sign_type", $$v);
      },
      expression: "contractItem.sign_type"
    }
  }), _vm.contractItem.sign_type === 5 ? _c('v-text-field', {
    attrs: {
      "label": "签约方式说明",
      "outlined": "",
      "dense": "",
      "prepend-icon": "assignment"
    },
    model: {
      value: _vm.contractItem.sign_other,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "sign_other", $$v);
      },
      expression: "contractItem.sign_other"
    }
  }) : _vm._e(), _vm.contractItem.sign_type === 1 ? _c('v-text-field', {
    attrs: {
      "label": "鑫泰比例*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number",
      "suffix": "%",
      "prepend-icon": "group_add"
    },
    model: {
      value: _vm.contractItem.xt_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "xt_ratio", $$v);
      },
      expression: "contractItem.xt_ratio"
    }
  }) : _vm._e(), _vm.contractItem.sign_type === 1 ? _c('v-text-field', {
    attrs: {
      "label": "代理商比例*",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number",
      "suffix": "%",
      "prepend-icon": "group_remove"
    },
    model: {
      value: _vm.contractItem.agency_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "agency_ratio", $$v);
      },
      expression: "contractItem.agency_ratio"
    }
  }) : _vm._e(), _vm.contractItem.sign_type === 3 ? _c('v-text-field', {
    attrs: {
      "label": "固定单价（尖）*",
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.negative],
      "dense": "",
      "type": "number",
      "suffix": "元/兆瓦时",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.contractItem.sharp_price,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "sharp_price", $$v);
      },
      expression: "contractItem.sharp_price"
    }
  }) : _vm._e(), _vm.contractItem.sign_type === 3 ? _c('v-text-field', {
    attrs: {
      "label": "固定单价（峰）*",
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.negative],
      "dense": "",
      "type": "number",
      "suffix": "元/兆瓦时",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.contractItem.peak_price,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "peak_price", $$v);
      },
      expression: "contractItem.peak_price"
    }
  }) : _vm._e(), _vm.contractItem.sign_type === 3 ? _c('v-text-field', {
    attrs: {
      "label": "固定单价（平）*",
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.negative],
      "dense": "",
      "type": "number",
      "suffix": "元/兆瓦时",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.contractItem.plain_price,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "plain_price", $$v);
      },
      expression: "contractItem.plain_price"
    }
  }) : _vm._e(), _vm.contractItem.sign_type === 3 ? _c('v-text-field', {
    attrs: {
      "label": "固定单价（谷）*",
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.negative],
      "dense": "",
      "type": "number",
      "suffix": "元/兆瓦时",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.contractItem.valley_price,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "valley_price", $$v);
      },
      expression: "contractItem.valley_price"
    }
  }) : _vm._e(), _vm.contractItem.sign_type === 2 ? _c('v-text-field', {
    attrs: {
      "label": "固定服务费金额*",
      "outlined": "",
      "rules": [_vm.rules.required, _vm.rules.negative],
      "dense": "",
      "type": "number",
      "suffix": "元",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.contractItem.fixed_fee,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "fixed_fee", $$v);
      },
      expression: "contractItem.fixed_fee"
    }
  }) : _vm._e(), _c('v-textarea', {
    attrs: {
      "label": "备注",
      "autocomplete": "off",
      "clearable": "",
      "outlined": "",
      "dense": "",
      "no-resize": "",
      "prepend-icon": "comment"
    },
    model: {
      value: _vm.contractItem.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.contractItem, "remark", $$v);
      },
      expression: "contractItem.remark"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.saveSubmit
    }
  }, [_vm._v("保存")])], 1)], 1), _c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
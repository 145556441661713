<template>
  <v-card-text v-if="notesList">
    <v-list subheader dense>
      <template v-for="(item, i) in notesList.filter(file => file.status === 0)">
        <v-list-item :key="'files_' + i">
          <v-list-item-avatar v-if="item.suffix === 'jpeg' || item.suffix === 'jpg' || item.suffix === 'png' || item.suffix === 'gif'">
            <v-img :src="item.preUrl && item.preUrl" @click="zoomFile(item)"></v-img>
          </v-list-item-avatar>
          <v-list-item-avatar v-else>
            <v-icon>text_snippet</v-icon>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ item.author._id === currentUser._id ? "我" : item.author.personal.name }} 于 {{ item.createTime | formatTime }} 上传</v-list-item-title>
            <v-list-item-subtitle>{{ item.fileName }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action class="d-inline">
            <v-btn icon v-if="item.author._id === currentUser._id && item.status === 0 && item._id" @click="deleteFile(item._id)"><v-icon color="grey lighten-1">delete</v-icon></v-btn>
            <v-btn icon v-if="item.preUrl"><v-icon color="grey lighten-1" @click="zoomFile(item)">zoom_in</v-icon></v-btn>
            <v-btn icon ><v-icon color="grey lighten-1" @click="downloadFile(item.fileLink)">get_app</v-icon></v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider :key="'files_divider_' + i" inset></v-divider>
      </template>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title align="end">
            <v-btn text @click="createAttach()">
              <v-icon left>attach_file</v-icon>上传附件</v-btn>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-dialog v-model="editDialog" fullscreen hide-overlay scrollable transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>{{ activeFile.fileName }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="downloadFile(activeFile.fileLink)"><v-icon color="grey lighten-1">get_app</v-icon></v-btn>
          <v-btn icon @click="closeEditDialog()"><v-icon>close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text>
          <v-img :src="activeFile.preUrl" aspect-ratio="1" contain></v-img>
        </v-card-text>
        <v-card-actions v-if="activeFile.author">
          <v-subheader>{{ activeFile.author._id === currentUser._id ? "我" : activeFile.author.personal.name }} 于 {{ activeFile.createTime | formatTime }} 上传</v-subheader>
          <v-spacer></v-spacer>
          <v-btn text @click="downloadFile(activeFile.fileLink)">下载</v-btn>
          <v-btn text @click="closeEditDialog()">关闭</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dlgAttach" persistent max-width="550px">
      <v-card v-if="editedAttach.progressUpload && editedAttach.progressUpload.length" color="secondary" dark>
        <v-card-title>文件上传中...</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" v-for="(progress, i) in editedAttach.progressUpload" :key="'progress_'+ i">
              {{editedAttach.files[i].name}}
              <v-progress-linear
                color="white"
                :value="progress"
              >
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card v-else>
        <v-card-title>
          上传附件
          <v-spacer></v-spacer>
          <v-btn icon @click="closeAttach()"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row>
<!--            <v-col cols="12">-->
<!--              <v-switch v-model="editedAttach.public" :label="`公开可见: ${editedAttach.public ? '是' : '否'}`"></v-switch>-->
<!--            </v-col>-->
            <v-col cols="12">
              <v-file-input
                v-model="editedAttach.files"
                label="单击此处选择文件上传"
                outlined
                dense
                small-chips
                multiple
                show-size
                counter
              ></v-file-input>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeAttach()">取消</v-btn>
          <v-btn text color="secondary"
                 :disabled="!editedAttach.files.length"
                 @click="addAttach()">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card-text>
  <v-overlay v-else :value="notesLoading" z-index="9999">
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>
<script>
import axios from "axios";
import store from "@/store";
import {
  FETCH_NOTES_LIST,
  FETCH_UPLOAD,
  FETCH_URL,
  PUBLISH_NOTES,
  REMOVE_NOTES
} from "@/store/actions.type";
import { mapGetters } from "vuex";
import { SET_ERROR } from "@/store/mutations.type";

export default {
  props: {
    entityType: {
      type: String,
      default: 'Case'
    },
    entityId: {
      type: String,
      required: true
    },
    type: {
      type: Number,
      default: 1
    }
  },
  data() {
    return {
      notesList:[],
      notesLoading: true,
      editDialog: false,
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      activeFile: {},

      dlgAttach: false,
      editedAttach: {
        files: [],
        progressUpload: [],
        public: false
      },
      defaultAttach: {
        files: [],
        progressUpload: [],
        public: false
      },
    }
  },
  computed: {
    ...mapGetters([
      "currentUser",
      "uploadSign",
    ]),
  },
  mounted() {},
  watch: {
    'editedAttach.progressUpload': {
      handler: function (val) {
        if (val.reduce((a, b) => a + b, 0) / val.length === 100) this.closeAttach()

      },
      deep: true
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.notesLoading = true
      store.dispatch(FETCH_NOTES_LIST, "entityType="+ this.entityType +"&entityId="+ this.entityId +"&type="+ this.type)
        .then((data) => {
          if (data && data.list) {
            this.notesList = data.list
            data.list.forEach((file) => {
              if (file.suffix.toLowerCase() === "jpeg" ||
                file.suffix.toLowerCase() === "jpg" ||
                file.suffix.toLowerCase() === "png" ||
                file.suffix.toLowerCase() === "gif") {
                store.dispatch(FETCH_URL, file.fileLink).then((fileurl) => {
                  file.preUrl = fileurl;
                  this.$forceUpdate();
                });
              }
            });
            this.notesLoading = false
          }
        })
    },
    zoomFile(objFile) {
      this.activeFile = objFile;
      this.editDialog = true;
    },
    downloadFile(fileLink) {
      store.dispatch(FETCH_URL, fileLink)
        .then((fileurl) => {
          window.location.href = fileurl;
        })
        .catch((err) => {
          store.commit(SET_ERROR, { msg: err });
        });
    },
    deleteFile(id) {
      store.dispatch(REMOVE_NOTES, id)
        .then(() => {
          this.getList()
          store.commit(SET_ERROR, {msg: '删除成功。', color: 'primary'});
        })
        .catch((err) => {
          store.commit(SET_ERROR, {msg: err});
        });
    },
    closeEditDialog() {
      this.activeFile = {};
      this.editDialog = false;
    },
    createAttach() {
      this.dlgAttach = true
    },
    async addAttach() {
      if (this.editedAttach.files && this.editedAttach.files.length) {
        let filePublic = this.editedAttach.public
        store.dispatch(FETCH_UPLOAD, "private").then(() => {
          this.editedAttach.progressUpload = new Array(this.editedAttach.files.length).fill(0);
          Promise.all(this.editedAttach.files.map((file, index) => {
            this.uploadAttach(file, index).then((result) => {
              let objNewFile = {}
              objNewFile['entityType'] = this.entityType
              objNewFile['entityId'] = this.entityId
              objNewFile['type'] = this.type
              objNewFile['suffix'] = result.suffix
              objNewFile['fileName'] = result.name
              objNewFile['fileLink'] = result.href
              objNewFile['public'] = filePublic
              store.dispatch(PUBLISH_NOTES, objNewFile)
                .then(() => {
                  this.getList()
                })
                .catch(({response}) => {
                  store.commit(SET_ERROR, { msg: response.data.message })
                });
            })
          }))
        })
      }
    },
    uploadAttach(file, index) {
      return new Promise((resolve, reject) => {
        let fileFullName = file.name,
          fileSuffix = fileFullName.substring(fileFullName.lastIndexOf(".") + 1);
        fileFullName = fileFullName.replace(/[%#&]/g,"");

        let param = new FormData();
        param.append("name", fileFullName);
        param.append("key", "filing/" + this.uploadSign.key + "/" + fileFullName);
        param.append("policy", this.uploadSign.policy);
        param.append("OSSAccessKeyId", this.uploadSign.OSSAccessKeyId);
        param.append("success_action_status", 200);
        param.append("signature", this.uploadSign.signature);
        param.append("file", file, fileFullName);

        let config = {
          headers: { "Content-Type": "multipart/form-data" },
          onUploadProgress: (progressEvent) => {
            this.$set(this.editedAttach.progressUpload,index,Math.floor(
              (progressEvent.loaded / progressEvent.total) * 100
            ))
          },
        };
        axios.post(this.uploadSign.host, param, config)
          .then(() => {
            resolve({
              suffix: fileSuffix,
              name: fileFullName,
              href: param.get("key")
            })
          })
          .catch((error) => {
            reject(error.message)
          });
      });
    },
    closeAttach() {
      this.dlgAttach = false
      this.$nextTick(() =>{
        this.editedAttach = Object.assign({}, this.defaultAttach)
      })
    },
  },
}
</script>
<style scoped>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: 0.5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>

<template>
  <v-container>
    <v-card>
      <v-card-title>常用入口</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="10">
            <v-slide-group class="pa-4" show-arrows>
              <v-slide-item v-for="item in userItem" :key="item.code" v-slot="">
                <v-card :color="item.color" class="ma-4" height="100" width="100" @click="gotoLink(item.link)">
                  <v-row class="fill-height" align="center" justify="center">
                    <v-icon color="white" size="48" class="mt-5" v-text="item.icon"></v-icon>
                    <v-subheader v-text="item.name" dark></v-subheader>
                  </v-row>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </v-col>
          <v-col cols="2" class="d-flex align-center">
            <v-card outlined flat height="100" width="100" @click="addLink()">
              <v-row class="fill-height" align="center" justify="center">
                <v-icon v-text="'add'" class="mt-5"></v-icon>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dlgEdit" persistent scrollable max-width="350px">
      <v-card>
        <v-card-title>选择快捷入口</v-card-title>
        <v-card-text>
          <v-container>
            <v-chip-group v-model="editItem" column multiple>
              <v-chip outlined filter v-for="item in shortcutsLink" :key="item.code" :value="item.code">{{item.name}}</v-chip>
            </v-chip-group>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="close">取消</v-btn>
          <v-btn color="primary" text @click="submit">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import store from "@/store";
import {mapGetters} from "vuex";
import Util from "@/common/util";
import {
  CHECK_AUTH,
  EDIT_ACCOUNT,
} from '@/store/actions.type'
import {SET_ERROR} from "@/store/mutations.type";

export default {
  data: () => ({
    version: 1,
    dlgEdit: false,
    userItem: [],
    editItem: [],
  }),
  computed: {
    ...mapGetters([
      "currentUser",
    ]),
    shortcutsLink() {
      const sysLink = Util.categories('shortcutsLink')
      if (!this.currentUser.hasAdmin.includes('distributor')) {
        sysLink.map(item => {
          if (this.currentUser.permission.includes(item.roles)) return item
        })
      }
      return sysLink
    },
  },
  created() {
    this.getLink()
  },
  methods: {
    getLink() {
      this.editItem = this.currentUser['shortcutsLink'] ? this.currentUser['shortcutsLink'].content : [];
      this.userItem = this.editItem.map(item => {
        return this.shortcutsLink.find(link => link.code === item)
      })
    },
    addLink() {
      this.dlgEdit = true
    },
    close () {
      this.dlgEdit = false
      this.getLink()
    },
    submit() {
      let strUpdate = {}
      strUpdate['shortcutsLink'] = {}
      strUpdate['shortcutsLink'].version = this.version
      strUpdate['shortcutsLink'].content = this.editItem
      store.dispatch(EDIT_ACCOUNT, strUpdate)
        .then(() => {
          store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
          store.dispatch(CHECK_AUTH)
            .then(() => {
              this.close()
            })
            .catch((error) => {
              store.commit(SET_ERROR, {msg: error.response.data.message});
            })
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    gotoLink(link) {
      this.$router.push({path: link});
    }
  }
}
</script>

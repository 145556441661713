<template>
  <v-row>
    <v-col cols=12>
      <v-card class="mx-auto">
        <v-card-title class="title font-weight-light">
          <v-col class="text-no-wrap">
            收益分析
          </v-col>
          <v-spacer></v-spacer>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon class="mr-2" :disabled="!report || !report.length" @click="downloadReport">
                <v-icon>download</v-icon>
              </v-btn>
            </template>
            <span>下载数据</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click.stop="filter_item">
                <v-icon>filter_list</v-icon>
              </v-btn>
            </template>
            <span>过滤数据</span>
          </v-tooltip>
          <v-navigation-drawer v-model="incomeDrawer" app right>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title">
                  过滤条件：收益
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon @click="filter_item"><v-icon>close</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider></v-divider>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="query.business_type"
                    :items="userBusinessType"
                    label="业务类型"
                    item-text="name"
                    item-value="code"
                    outlined
                    dense
                    hide-details
                    @change="resetChartData()"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="query.person_in_charge"
                    :items="accountList"
                    item-text="personal.name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    clearable
                    label="内部负责人"
                    small-chips
                    multiple
                    hide-details
                    @change="resetChartData()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="query.agency"
                    :loading="agencyLoading"
                    :items="agencyList"
                    :search-input.sync="searchAgency"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    dense
                    clearable
                    hide-details
                    label="代理商名称"
                    @change="resetChartData()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-autocomplete
                    v-model="query.customer"
                    :loading="customerLoading"
                    :items="customerList"
                    :search-input.sync="searchCustomer"
                    item-text="name"
                    item-value="_id"
                    autocomplete="off"
                    outlined
                    hide-details
                    clearable
                    dense
                    label="客户名称"
                    @change="resetChartData()"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <v-select
                    v-model="query.area"
                    :items="areaList"
                    label="所属区域"
                    item-text="name"
                    item-value="_id"
                    outlined
                    dense
                    clearable
                    hide-details
                    @change="resetChartData()"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-menu ref="sign_date" v-model="settle_start_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="query.start_date"
                        label="结算日期开始月份"
                        readonly
                        outlined
                        dense
                        clearable
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="resetQuery('start_date')"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="query.start_date" no-title scrollable type="month" width="225" @input="settle_start_menu = false" @change="resetChartData()">
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-menu ref="sign_date" v-model="settle_end_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="query.end_date"
                        label="结算日期结束月份"
                        readonly
                        outlined
                        dense
                        clearable
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                        @click:clear="resetQuery('end_date')"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="query.end_date" no-title scrollable type="month" width="225" @input="settle_end_menu = false" @change="resetChartData()">
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-card-text>
            <template v-slot:append>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="filter_item">隐藏</v-btn>
                <v-btn color="secondary" text @click="clear_filter">清空</v-btn>
              </v-card-actions>
            </template>
          </v-navigation-drawer>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-toolbar flat>
            <v-tabs v-model="incomeType" @change="resetChartData">
              <v-tab>毛利额(元)</v-tab>
              <v-tab>毛利率(%)</v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
          </v-toolbar>
          <div id="income_analysis" :style="{ width: width, height: height }"></div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import * as echarts from 'echarts';
import "@/common/echarts.theme";
import store from "@/store";
import ApiService from "@/common/http";
import {
  FETCH_ACCOUNT_LIST,
  FETCH_AGENCY_LIST, FETCH_CUSTOMER_LIST,
  FETCH_REPORT_INCOME,
} from "@/store/actions.type";
import {mapGetters} from "vuex";
import {SET_ERROR, SET_REPORT_DRAWER} from "@/store/mutations.type";
export default {
  data() {
    return {
      report: '',
      query: {
        business_type: '',
        person_in_charge: '',
        area: '',
        agency: '',
        customer: '',
        start_date: '',
        end_date: '',
      },
      defaultQuery: {
        business_type: '',
        person_in_charge: '',
        area: '',
        agency: '',
        customer: '',
        start_date: '',
        end_date: '',
      },
      searchAccount: null,
      searchAgency: null,
      searchCustomer: null,
      settle_start_menu: false,
      settle_end_menu: false,
      incomeType: 0,
    }
  },
  props: {
    data: {
      type: Array,
    },
    label: {
      type: Array,
    },
    title: {
      type: String,
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getReportIncome()
    })
  },
  watch: {
    data() {
      this.drawLine();
    },
    searchAccount (val) {
      val && val !== this.query.person_in_charge && this.searchEmployee(val)
    },
    searchAgency (val) {
      val && val !== this.query.agency && this.getAgencyList(val)
    },
    searchCustomer (val) {
      val && val !== this.query.customer && this.getCustomerList(val)
    },
  },
  computed: {
    ...mapGetters([
      "currentUser", "userBusinessType", "agencyList", "agencyLoading", "areaList", "accountLoading", "accountList", "customerLoading", "customerList"
    ]),
    incomeDrawer: {
      get() {
        return store.state.report.incomeDrawer
      },
      set() {}
    },
  },
  created() {
    if (this.userBusinessType && this.userBusinessType.length) {
      this.query.business_type = this.userBusinessType[0].code;
    }
  },
  methods: {
    filter_item() {
      store.commit(SET_REPORT_DRAWER, 'income')
    },
    clear_filter() {
      this.query = Object.assign({business_type: this.query.business_type}, this.defaultQuery)
      this.getReportIncome()
    },
    getCustomerList (key) {
      store.dispatch(FETCH_CUSTOMER_LIST, {
        query:
          "page=1" +
          "&limit=50" +
          "&key=" + key
      })
    },
    downloadReport() {
      let urlQuery = ''
      if (this.query.business_type) urlQuery += '&business_type=' + this.query.business_type
      if (this.query.person_in_charge && this.query.person_in_charge.length) urlQuery += '&person_in_charge=' + this.query.person_in_charge
      if (this.query.agency) urlQuery += '&agency=' + this.query.agency
      if (this.query.customer) urlQuery += '&customer=' + this.query.customer
      if (this.query.area) urlQuery += '&area=' + this.query.area
      if (this.query.start_date) urlQuery += '&start_date=' + this.query.start_date
      if (this.query.end_date) urlQuery += '&end_date=' + this.query.end_date
      ApiService.download('/report/stats/exportProfit?'+urlQuery, {
        responseType:'blob'
      })
        .then(resp => {
          let fileName = '收益分析数据导出.xlsx';
          let contentDisposition = resp.headers['content-disposition'];
          if (contentDisposition) {
            fileName = window.decodeURI(resp.headers['content-disposition'].split('=')[1]);
          }
          let blob = new Blob([resp.data], {type: 'application/octet-stream'});
          if (window.navigator.msSaveOrOpenBlob) { //支持IE
            navigator.msSaveBlob(blob, fileName);
          } else {
            let link = document.createElement('a');
            link.style.display = "none";
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            this.downloading = false
            //释放内存
            window.URL.revokeObjectURL(link.href);
            document.body.removeChild(link);
          }
        })
        .catch(err => { // 请求失败处理
          this.downloading = false
          store.commit(SET_ERROR, {msg: err});
        });
    },
    resetQuery(p) {
      this.query[p] = ''
      this.getReportIncome()
    },
    resetChartData() {
      this.getReportIncome()
    },
    getReportIncome() {
      if (this.query.business_type) {
        let urlQuery = ''
        if (this.query.business_type) urlQuery += '&business_type=' + this.query.business_type
        if (this.query.person_in_charge && this.query.person_in_charge.length) urlQuery += '&person_in_charge=' + this.query.person_in_charge
        if (this.query.agency) urlQuery += '&agency=' + this.query.agency
        if (this.query.customer) urlQuery += '&customer=' + this.query.customer
        if (this.query.area) urlQuery += '&area=' + this.query.area
        if (this.query.start_date) urlQuery += '&start_date=' + this.query.start_date
        if (this.query.end_date) urlQuery += '&end_date=' + this.query.end_date
        store.dispatch(FETCH_REPORT_INCOME, urlQuery).then( res => {
          this.report = res.sort(sortNum('month'))
          this.drawLine();
        }).catch( err => {
          store.commit(SET_ERROR, { msg: err.data })
        })
      }
    },
    searchEmployee (key) {
      store.dispatch(FETCH_ACCOUNT_LIST, '&key='+ key)
    },
    getAgencyList (key) {
      store.dispatch(FETCH_AGENCY_LIST, {
        query:
          "page=1" +
          "&limit=50" +
          "&key=" + key
      })
    },
    drawLine() {
      let xAxisData = [];
      let data1 = [];
      let legendName = ''
      let color = ''
      this.report.map(item => {
        xAxisData.push(item.month + '月')
        if (this.incomeType === 0) {
          legendName = '毛利额'
          color = '#FF8100FF'
          data1.push(Number(item.gross_profit).toFixed(2))
        } else {
          color = '#1DAB77FF'
          legendName = '毛利率'
          data1.push(Number(item.gross_profit_rate).toFixed(2))
        }
      })
      let emphasisStyle = {
        itemStyle: {
          shadowBlur: 10,
          shadowColor: 'rgba(0,0,0,0.3)'
        }
      }
      let myChart = echarts.init(document.getElementById('income_analysis'));
      myChart.setOption({
        legend: {
          data: [legendName],
          right: '3%'
        },
        tooltip: {
          trigger: 'axis',
          formatter:function(params) {
            let str = legendName + '<br>'
            let per = ''
            legendName === '毛利率' ? per = '%<br>' : per = ''
            for(let item of params) {
              str += item.marker + item.name + ' : ' + item.value + per
            }
            return str;
          }
        },
        xAxis: {
          data: xAxisData,
          name: '',
          axisLine: { onZero: true },
          splitLine: { show: false },
          splitArea: { show: false }
        },
        yAxis: {
          splitLine: {
            lineStyle: {
              type: 'dashed'
            }
          }
        },
        grid: {
          left: '10%',
          right: '10%',
          bottom: '20',
        },
        series: [
          {
            name: legendName,
            type: 'line',
            itemStyle: {
              color: color,
              lineStyle: {
                color: color
              },
            },
            emphasis: emphasisStyle,
            data: data1
          },
        ]
      })
      window.addEventListener('resize', () => {
        myChart.resize()
      })
    }
  }
}
function sortNum(property) {
  return function (a,b) {
    let value1 = a[property]
    let value2 = b[property]
    return value1 - value2
  }
}
</script>

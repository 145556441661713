var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', [_vm._v(" " + _vm._s(_vm.id ? '编辑人员信息' : '创建人员资料') + " "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEdit();
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitEdit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "start",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "2"
    }
  }, [_c('input', {
    ref: "uploadInput",
    attrs: {
      "id": "files",
      "type": "file",
      "name": "file",
      "accept": "image/*",
      "multiple": false
    },
    on: {
      "change": function change($event) {
        return _vm.detectFiles($event);
      }
    }
  }), _c('v-hover', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hover = _ref.hover;
        return [_c('v-avatar', [_c('v-img', {
          attrs: {
            "src": _vm.editedEmployee.profile ? _vm.ossURL + '/' + _vm.editedEmployee.profile : '/static/grey_silhouette.png',
            "alt": "设置照片",
            "aspect-ratio": "1"
          }
        }, [_vm.Upload.uploading ? _c('v-progress-circular', {
          attrs: {
            "color": "secondary",
            "rotate": "360",
            "size": "48",
            "width": "5",
            "value": _vm.Upload.progressUpload
          }
        }) : _vm._e()], 1), hover ? _c('div', {
          staticClass: "show_upload",
          staticStyle: {
            "height": "100%",
            "cursor": "pointer"
          }
        }, [_c('v-icon', {
          on: {
            "click": function click($event) {
              return _vm.uploadAvatar();
            }
          }
        }, [_vm._v("add_a_photo")])], 1) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": "text",
      "label": "员工姓名",
      "hint": "员工姓名为用户的真实姓名",
      "outlined": ""
    },
    model: {
      value: _vm.editedEmployee.name,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "name", $$v);
      },
      expression: "editedEmployee.name"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required, _vm.rules.phonenum],
      "error-messages": _vm.editedEmployee.msgName,
      "clearable": _vm.editedEmployee.msgName !== '',
      "disabled": !!this.id,
      "prepend-icon": "https",
      "type": "text",
      "label": "用户账号",
      "hint": "用户账号将作为登陆名称使用",
      "outlined": "",
      "required": ""
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('v-scale-transition', {
          attrs: {
            "leave-absolute": ""
          }
        }, [_vm.editedEmployee.chkName ? _c('v-progress-circular', {
          attrs: {
            "width": 2,
            "size": "24",
            "color": "secondary",
            "indeterminate": ""
          }
        }) : _vm._e()], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.editedEmployee.username,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "username", $$v);
      },
      expression: "editedEmployee.username"
    }
  }), _c('v-text-field', {
    attrs: {
      "rules": [_vm.rules.required],
      "type": _vm.editedEmployee.showPass ? 'text' : 'password',
      "append-icon": _vm.editedEmployee.showPass ? 'visibility_off' : 'visibility',
      "disabled": !!this.id,
      "prepend-icon": "vpn_key",
      "label": "账号密码",
      "hint": "请牢记此密码，密码将作为登陆凭证",
      "outlined": "",
      "required": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.editedEmployee.showPass = !_vm.editedEmployee.showPass;
      }
    },
    model: {
      value: _vm.editedEmployee.password,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "password", $$v);
      },
      expression: "editedEmployee.password"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.flatDeptList(_vm.departments[0]),
      "rules": [_vm.rules.selected],
      "prepend-icon": "account_tree",
      "item-text": "name",
      "item-value": "value",
      "autocomplete": "off",
      "outlined": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择所属部门",
      "multiple": ""
    },
    model: {
      value: _vm.editedEmployee.department,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "department", $$v);
      },
      expression: "editedEmployee.department"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.flatGroupRoles(_vm.groupList),
      "rules": [_vm.rules.selected],
      "prepend-icon": "admin_panel_settings",
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择所属角色",
      "multiple": ""
    },
    scopedSlots: _vm._u([{
      key: "item",
      fn: function fn(data) {
        return [!data.item.name ? [_c('v-list-item-content', {
          domProps: {
            "textContent": _vm._s(data.item)
          }
        })] : [_c('v-list-item-action', [_c('v-checkbox', {
          attrs: {
            "input-value": data.attrs.inputValue
          }
        })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
          domProps: {
            "innerHTML": _vm._s(data.item.name)
          }
        })], 1)]];
      }
    }]),
    model: {
      value: _vm.editedEmployee.roles,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "roles", $$v);
      },
      expression: "editedEmployee.roles"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.dictBusinessGroup,
      "rules": [_vm.rules.selected],
      "prepend-icon": "groups",
      "item-text": "name",
      "item-value": "code",
      "autocomplete": "off",
      "outlined": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择所属业务群组",
      "multiple": ""
    },
    model: {
      value: _vm.editedEmployee.group,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "group", $$v);
      },
      expression: "editedEmployee.group"
    }
  }), _c('v-autocomplete', {
    attrs: {
      "items": _vm.areaList,
      "rules": [_vm.rules.selected],
      "prepend-icon": "crop",
      "item-text": "name",
      "item-value": "_id",
      "autocomplete": "off",
      "outlined": "",
      "chips": "",
      "small-chips": "",
      "label": "请选择所属区域",
      "multiple": ""
    },
    model: {
      value: _vm.editedEmployee.area,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "area", $$v);
      },
      expression: "editedEmployee.area"
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "disabled": !_vm.valid
    },
    on: {
      "click": function click($event) {
        return _vm.submitEdit();
      }
    }
  }, [_vm._v("确认")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
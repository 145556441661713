<template>
  <table class="receiptTable caption" cellspacing="0">
    <tr>
      <th colspan="13" class="title py-4">
        {{ settleData.settleTitle }}
      </th>
    </tr>
    <tr>
      <th>代理商名称</th>
      <th>项目名称</th>
      <th>年份</th>
      <th>月份</th>
      <th>甲方收益（含税）</th>
      <th>甲方收益（不含税）</th>
      <th>代理商<br>分成比例</th>
      <th>代理费用（不含税）</th>
      <th>减：增值税差 - 税金及附加（元）</th>
      <th>代理商不含税合计</th>
      <th>发票类型</th>
      <th>代理商<br>发票税率</th>
      <th>价税合计</th>
    </tr>
    <tr v-for="(item,i) in settleData.settleList" :key="i">
      <td v-if="i === 0" :rowspan="settleData.settleList.length + 1" class="text-center" style="width: 10%;">{{ settleData.agency?.name }}</td>
      <td class="text-center" style="width: 10%; border-left: 0;">{{ item.customer?.name }}</td>
      <td class="text-center" nowrap="true">{{ new Date(item.date).getFullYear() }}年</td>
      <td class="text-center">{{ new Date(item.date).getMonth()+1 }}月</td>
      <td class="text-right">{{ item.xt_service_fee.$numberDecimal | formatMoney }}</td>
      <td class="text-right">{{ item.xt_service_fee_no_tax.$numberDecimal | formatMoney }}</td>
      <td v-if="i === 0" :rowspan="settleData.settleList.length" class="text-center">{{ item.agency_ratio?Number(item.agency_ratio?.$numberDecimal).toFixed(2)+'%':'-' }}</td>
      <td class="text-right">{{ item.agency_service_fee_no_tax.$numberDecimal | formatMoney }}</td>
      <td class="text-right">{{ item.tax.$numberDecimal | formatMoney }}</td>
      <td class="text-right">{{ item.agency_fee_no_tax.$numberDecimal | formatMoney }}</td>
      <td v-if="i === 0" :rowspan="settleData.settleList.length" class="text-center">{{ settleData.settleList?.[0]?.agency_invoice_type === 1 ? '专票' : '普票' }}</td>
      <td v-if="i === 0" :rowspan="settleData.settleList.length" class="text-center">{{ settleData.settleList?.[0]?.agency_tax?.$numberDecimal * 1 }}%</td>
      <td class="text-right">{{ item.agency_fee.$numberDecimal | formatMoney }}</td>
    </tr>
    <tr>
      <td colspan="3" class="text-center" style="border-left: 0;">合计</td>
      <td align="right">{{ xt_service_fee_total | formatMoney }}</td>
      <td align="right">{{ xt_service_fee_no_tax_total | formatMoney }}</td>
      <td align="center">-</td>
      <td align="right">{{ agency_service_fee_no_tax_total | formatMoney }}</td>
      <td align="right">{{ tax_total | formatMoney }}</td>
      <td align="right">{{ agency_fee_no_tax_total | formatMoney }}</td>
      <td align="center">-</td>
      <td align="center">-</td>
      <td align="right">{{ agency_fee_total | formatMoney }}</td>
    </tr>
    <tr style="border-left: 1px solid #000; border-right: 1px solid #000;">
      <td colspan="3" class="text-center no-border font-weight-bold" style="vertical-align: top;">制表人：{{ settleData.producedBy?.personal?.name }}</td>
      <td colspan="3" class="text-center no-border font-weight-bold">
        <template v-if="comfirmors.length">
          <v-row v-for="(item, i) in comfirmors" :key="i">
            <v-col cols="6" align="right" class="pr-0">
              <span v-if="i === 0">确认人：</span>
            </v-col>
            <v-col cols="6" align="left" class="pl-0">
              {{ item }}
            </v-col>
          </v-row>
        </template>
        <template v-else>
          确认人：
        </template>
      </td>
      <td colspan="3" class="text-center no-border font-weight-bold" style="vertical-align: top;">
        <template v-if="approvers.length">
          <v-row v-for="(item, i) in approvers" :key="i">
            <v-col cols="6" align="right" class="pr-0">
              <span v-if="i === 0">核定人：</span>
            </v-col>
            <v-col cols="6" align="left" class="pl-0">
              {{ item }}
            </v-col>
          </v-row>
        </template>
        <template v-else>
          核定人：
        </template>
      </td>
      <td colspan="4" class="text-center no-border font-weight-bold" style="vertical-align: top;">
        <template v-if="reviewers.length">
          <v-row v-for="(item, i) in reviewers" :key="i">
            <v-col cols="6" align="right" class="pr-0">
              <span v-if="i === 0">复核人：</span>
            </v-col>
            <v-col cols="6" align="left" class="pl-0">
              {{ item }}
            </v-col>
          </v-row>
        </template>
        <template v-else>
          复核人：
        </template>
      </td>
    </tr>
    <tbody style="border: 1px solid #000;">
      <tr>
        <td colspan="6" class="text-center no-border">甲方：北京鑫泰能源股份有限公司</td>
        <td colspan="7" class="text-center no-border">乙方：{{ settleData.agency?.name }}</td>
      </tr>
      <tr>
        <td colspan="6" class="text-center no-border" v-if="this.reviewTime">日期：{{ reviewTime.getFullYear() + '年' + (reviewTime.getMonth()+1)+ '月' + reviewTime.getDate() + '日' }}</td>
        <td colspan="6" class="text-center no-border" v-else>日期：<span style="letter-spacing: 1.5rem;" class="pl-10">年月日</span></td>
        <td colspan="7" class="text-center no-border" v-if="this.reviewTime">日期：{{ reviewTime.getFullYear() + '年' + (reviewTime.getMonth()+1)+ '月' + reviewTime.getDate() + '日' }}</td>
        <td colspan="7" class="text-center no-border" v-else>日期：<span style="letter-spacing: 1.5rem;" class="pl-10">年月日</span></td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      xt_service_fee_total: 0,
      xt_service_fee_no_tax_total: 0,
      agency_service_fee_no_tax_total: 0,
      tax_total: 0,
      agency_fee_total: 0,
      agency_fee_no_tax_total: 0,
      comfirmors: [],
      approvers: [],
      reviewers: [],
      reviewTime: '',
    }
  },
  props: {
    getType: {
      type: Number,
      require: true,
      default: null,
    },
    getId: {
      type: String,
      require: true,
      default: '',
    },
    settleData: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  watch: {
    settleData() {
      this.checkData()
    },
  },
  created() {
    this.checkData()
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    checkData() {
      this.resetData()
      this.settleData.settleList.map(item => {
        this.xt_service_fee_total += Number(item.xt_service_fee.$numberDecimal)
        this.xt_service_fee_no_tax_total += Number(item.xt_service_fee_no_tax.$numberDecimal)
        this.agency_service_fee_no_tax_total += Number(item.agency_service_fee_no_tax.$numberDecimal)
        this.tax_total += Number(item.tax.$numberDecimal)
        this.agency_fee_total += Number(item.agency_fee.$numberDecimal)
        this.agency_fee_no_tax_total += Number(item.agency_fee_no_tax.$numberDecimal)
      })
      this.settleData.tasks_list.map((item, i) => {
        item.record?.map((op,j) => {
          if (op.action === 1) {
            if (i === 0) this.comfirmors.push(op.operator.personal.name)
            if (i === 1) this.approvers.push(op.operator.personal.name)
            if (i === 2) {
              if (j === 0) this.reviewTime = new Date(op.optime)
              this.reviewers.push(op.operator.personal.name)
            }
          }
        })
      })
      this.comfirmors = [...new Set(this.comfirmors)]
      this.approvers = [...new Set(this.approvers)]
      this.reviewers = [...new Set(this.reviewers)]
    },
    resetData() {
      this.dialog = false
      this.comfirmors = []
      this.approvers = []
      this.reviewers = []
      this.xt_service_fee_total = 0
      this.xt_service_fee_no_tax_total = 0
      this.agency_service_fee_no_tax_total = 0
      this.tax_total = 0
      this.agency_fee_total = 0
      this.agency_fee_no_tax_total = 0
      this.year = ''
      this.month = []
      this.minMonth = ''
      this.maxMonth = ''
    },
    downloadReceipt() {
      console.log('download')
    },
  },
  directives: {}
}
</script>

<style scoped>
.receiptTable {
  width: 1px;
  min-width: 100%;
  border-collapse: collapse;
}
.receiptTable td,
.receiptTable th {
  padding: 0 4px;
  height: 30px;
  border: 1px solid rgb(0, 0, 0);
  color: #000 !important;
}
.receiptTable th {
  word-break: keep-all;
}
.receiptTable td.no-border {
  border: 0;
  padding: 20px 4px;
}
.receiptTable>tr>th+th {
  border-left-width: 0;
}
.receiptTable>tr+tr>th {
  border-top-width: 0;
}
.receiptTable>tr>td+td {
  border-left-width: 0;
}
.receiptTable>tr+tr>td {
  border-top-width: 0;
}
</style>

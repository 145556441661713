//登录、注销、生成路由信息
export const CHECK_AUTH = 'checkAuth';
export const LOGIN_PASS = 'loginByPassword';
export const LOGIN_CODE = 'loginBySMSCode';
export const LOGOUT = 'logout';
export const CREATE_ROUTER = 'getRouter';
//主体管理
export const FETCH_DISTRIBUTOR_LIST = 'getDistributorList';
export const FETCH_DISTRIBUTOR_AGENT = 'getDistributorAgentList'
export const CHECK_DISTRIBUTOR_CODE = 'checkDistributorInviteCode';
export const AUTH_DISTRIBUTOR = 'authDistributor';
export const BLOCK_DISTRIBUTOR = 'blockDistributor';
export const BOOKMARK_DISTRIBUTOR = 'setDefaultDistributor';
export const DELETE_DISTRIBUTOR = 'deleteDistributor';
export const FETCH_DISTRIBUTOR = 'getDistributor';
export const PUBLISH_DISTRIBUTOR = 'createDistributor';
export const EDIT_DISTRIBUTOR = 'updateDistributor';
//账号相关
export const CREATE_ACCOUNT = 'register';
export const FETCH_ACCOUNT_LIST = 'getAccountList';
export const CHECK_USERNAME = 'checkUserName';
export const CHECK_USERPHONE = 'checkUserPhone';
export const EDIT_ACCOUNT = 'updateMineAccount';
//部门管理
export const FETCH_DEPT_LIST = 'getDeptList';
export const CREATE_DEPT = 'createDept';
export const EDIT_DEPT = 'updateDept';
export const REMOVE_DEPT = 'deleteDept';
//角色分组
export const FETCH_GROUP_LIST = 'getGroupList';
export const CREATE_GROUP = 'createGroup';
export const EDIT_GROUP = 'updateGroup';
export const REMOVE_GROUP = 'deleteGroup';
//角色管理
export const FETCH_ROLE_LIST = 'getRoleList';
export const CREATE_ROLE = 'createRole';
export const EDIT_ROLE = 'updateRole';
export const REMOVE_ROLE = 'deleteRole';
//员工管理
export const FETCH_EMPLOYEE_LIST = 'getEmployeeList';
export const FETCH_ADMIN_LIST = 'getAdminList';
export const CREATE_ADMIN = 'createAdmin';
export const REMOVE_ADMIN = 'deleteAdmin';
export const FETCH_EMPLOYEE = 'getEmployee';
export const CREATE_EMPLOYEE = 'createEmployee';
export const EDIT_EMPLOYEE = 'updateEmployee';
export const BLOCK_EMPLOYEE = 'blockEmployee';
export const REMOVE_EMPLOYEE = 'deleteEmployee';
//日志
export const FETCH_LOGS_LIST = 'getLogsList';
//主题
export const CHANGE_THEME = 'changeTheme';
//工具
export const FETCH_UPLOAD = 'getUploadSign';
export const DELETE_FILE = 'delFile';
export const FETCH_URL = 'getUrlSign';
export const CHECK_ADDR = 'checkAddress';
export const SEND_SMS_CODE = 'sendSMSValidCode';
export const CHECK_SMS_CODE = 'checkSMSValidCode';
export const SEND_EMAIL_CODE = 'sendEmailValidCode';

//客户管理
export const CREATE_CUSTOMER = 'createCustomer';
export const CREATE_CUSTOMER_QUICK = 'createCustomerQuick';
export const FETCH_CUSTOMER_LIST = 'getCustomerList';
export const FETCH_CUSTOMER = 'getCustomer';
export const EDIT_CUSTOMER = 'updateCustomer';
export const REMOVE_CUSTOMER = 'removeCustomer';

//代理商管理
export const CREATE_AGENCY = 'createAgency';
export const FETCH_AGENCY_LIST = 'getAgencyList';
export const FETCH_AGENCY = 'getAgency';
export const EDIT_AGENCY = 'updateAgency';
export const REMOVE_AGENCY = 'removeAgency';

//合同管理
export const CREATE_CONTRACT = 'createContract';
export const FETCH_CONTRACT_LIST = 'getContractList';
export const FETCH_CONTRACT_CUSTOMER = 'getContractCustomerList';
export const FETCH_CONTRACT = 'getContract';
export const EDIT_CONTRACT = 'updateContract';
export const REMOVE_CONTRACT = 'removeContract';

//结算管理
export const CREATE_SETTLE = 'createSettle';
export const FETCH_SETTLE_LIST = 'getSettleList';
export const FETCH_SETTLE = 'getSettle';
export const EDIT_SETTLE = 'updateSettle';
export const REMOVE_SETTLE = 'removeSettle';

//工单管理
export const INIT_CASE = 'initCase';
export const CREATE_CASE = 'createCase';
export const FETCH_CASE_LIST = 'getCaseList';
export const FETCH_CASE = 'getCase';
export const EDIT_CASE = 'updateCase';
export const EDIT_CASE_SETTLE = 'updateCaseSettle';
export const SUBMIT_CASE = 'submitCase';
export const REJECT_CASE = 'rejectCase';
export const REMOVE_CASE = 'removeCase';
export const PRINT_CASE = 'printCase';
export const DOWNLOAD_CASE_PAYMENT = 'downloadCasePayment'
export const DOWNLOAD_CASE_SUMMARY = 'downloadCaseSummary'

//数据统计
export const FETCH_REPORT_SUMMARY = 'getReportSummary';
export const FETCH_REPORT_ELEC = 'getReportElec';
export const FETCH_REPORT_SERVICE = 'getReportService';
export const FETCH_REPORT_INCOME = 'getReportIncome';
export const FETCH_REPORT_SETTLE = 'getReportSettle';
export const FETCH_REPORT_SETTLE_SUMMARY = 'getReportSettleSummary';
export const FETCH_REPORT_AREA_SUMMARY = 'getReportAreaSummary';
export const FETCH_REPORT_AREA_LIST = 'getReportAreaList';
export const EDIT_REPORT_AREA_SUMMARY = 'updateReportAreaSummary';

//区域管理
export const FETCH_AREA_LIST = 'getAreaList';
export const PUBLISH_AREA = 'createArea';
export const EDIT_AREA = 'updateArea';
export const ACTIV_AREA = 'enableArea';

//任务模版
export const FETCH_TASK_LIST = 'getTaskList';
export const ORDER_TASK_LIST = 'orderTaskList';
export const PUBLISH_TASK = 'createTask';
export const FETCH_TASK = 'getTask';
export const EDIT_TASK = 'updateTask';
export const ACTIV_TASK = 'enableTask';

//储备客户
export const FETCH_FILING_LIST = 'getFilingList';
export const FETCH_FILING = 'getFiling';
export const PUBLISH_FILING = 'createFiling';
export const EDIT_FILING = 'updateFiling';

//附件备注
export const PUBLISH_NOTES = 'createNotes';
export const FETCH_NOTES_LIST = 'getNotesList';
export const FETCH_NOTES = 'getNotes';
export const EDIT_NOTES = 'editNotes';
export const REMOVE_NOTES = 'deleteNotes';

//版本发布
// Setting - Release
export const FETCH_RELEASE_LIST = 'getReleaseList';
export const PUBLISH_RELEASE = 'createRelease';
export const DELETE_RELEASE = 'deleteRelease';
export const EDIT_RELEASE = 'updateRelease';
export const FETCH_RELEASE = 'getRelease';

//BUG管理
export const PUBLISH_BUG = 'createBug';
export const FETCH_BUG_LIST = 'getBugList';
export const FETCH_BUG = 'getBug';




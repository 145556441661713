var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "width": "475"
    },
    on: {
      "input": function input($event) {
        return _vm.getEmployee(_vm.account);
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({
          attrs: {
            "color": _vm.btnColor,
            "dark": "",
            "text": "",
            "ripple": false
          }
        }, 'span', attrs, false), on), [_vm.avatar ? _c('v-avatar', {
          staticClass: "mr-2"
        }, [_c('v-img', {
          attrs: {
            "src": _vm.ossURL + '/' + _vm.avatar,
            "aspect-ratio": "1"
          }
        })], 1) : _vm._e(), _vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2)];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [!_vm.isEdited ? _c('v-card', [_c('v-card-title', [_vm.employeeInfo.personal ? _c('v-avatar', {
    staticClass: "mr-2",
    attrs: {
      "size": "52"
    }
  }, [_c('v-img', {
    attrs: {
      "src": _vm.employeeInfo.personal.profile ? _vm.ossURL + '/' + _vm.employeeInfo.personal.profile : '/static/grey_silhouette.png',
      "aspect-ratio": "1",
      "contain": ""
    }
  })], 1) : _vm._e(), _c('v-toolbar-title', [_vm._v(_vm._s(_vm.employeeInfo.personal && _vm.employeeInfo.personal.name ? _vm.employeeInfo.personal.name : '用户信息'))]), _vm.employeeInfo.isBlock ? _c('v-chip', {
    staticClass: "mx-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("已注销")]) : _vm._e(), _vm.employeeInfo.status === 9 ? _c('v-chip', {
    staticClass: "mx-2",
    attrs: {
      "small": ""
    }
  }, [_vm._v("已删除")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _vm.employeeInfo.status !== 9 ? _c('v-card-text', [_c('v-container', [_vm.employeeInfo.personal && _vm.employeeInfo.employer ? _c('v-row', [_c('v-col', {
    staticClass: "text--secondary text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("姓名")]), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.employeeInfo.personal.name))])]), _c('v-col', {
    staticClass: "text--secondary text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("手机")]), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.employeeInfo.phone))])]), _c('v-col', {
    staticClass: "text--secondary text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("部门")]), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.employeeInfo.employer.department.map(function (item) {
    return item.name;
  })))])]), _c('v-col', {
    staticClass: "text--secondary text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("角色")]), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.employeeInfo.employer.roles.map(function (item) {
    return item.name;
  })))])]), _c('v-col', {
    staticClass: "text--secondary text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("业务群组")]), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.employeeInfo.employer.group.map(function (code) {
    return _vm.dictBusinessGroup.find(function (o) {
      return o.code === code;
    }).name;
  })))])]), _c('v-col', {
    staticClass: "text--secondary text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("所属区域")]), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.employeeInfo.employer.area.map(function (item) {
    return item.name;
  })))])]), _c('v-col', {
    staticClass: "text--secondary text-right",
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("员工编号")]), _c('v-col', {
    attrs: {
      "cols": "9"
    }
  }, [_c('strong', [_vm._v(_vm._s(_vm.employeeInfo.employer.employeeNo))])])], 1) : _vm._e()], 1)], 1) : _vm._e(), _vm.employeeInfo.status !== 9 ? _c('v-divider') : _vm._e(), _vm.employeeInfo.status !== 9 && (_vm.currentUser.hasAdmin.includes('admin') || _vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('System_Organize')) ? _c('v-card-actions', [!_vm.employeeInfo.isBlock ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.resetPass
    }
  }, [_vm._v("重置密码")]) : _vm._e(), !_vm.employeeInfo.isBlock ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.blockAccount(true);
      }
    }
  }, [_vm._v("注销用户")]) : _vm._e(), !_vm.employeeInfo.isBlock ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.removeAccount
    }
  }, [_vm._v("删除用户")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("取消")]), !_vm.employeeInfo.isBlock ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.editEmployee
    }
  }, [_vm._v("编辑")]) : _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.blockAccount(false);
      }
    }
  }, [_vm._v("恢复")])], 1) : _vm.currentUser._id === _vm.employeeInfo._id ? _c('v-card-actions', [!_vm.employeeInfo.isBlock ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.resetPass
    }
  }, [_vm._v("重置密码")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_vm._v("取消")]), !_vm.employeeInfo.isBlock && (_vm.currentUser.hasAdmin.includes('admin') || _vm.currentUser.hasAdmin.includes('distributor') || _vm.currentUser.permission.includes('System_Organize')) ? _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": _vm.editEmployee
    }
  }, [_vm._v("编辑")]) : _vm._e()], 1) : _vm._e()], 1) : _c('account-edit', {
    attrs: {
      "id": _vm.employeeInfo._id
    },
    on: {
      "Close": function Close($event) {
        return _vm.closeEdit();
      }
    }
  }), _c('v-dialog', {
    attrs: {
      "width": "350"
    },
    model: {
      value: _vm.dialogPass,
      callback: function callback($$v) {
        _vm.dialogPass = $$v;
      },
      expression: "dialogPass"
    }
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submitPass.apply(null, arguments);
      }
    },
    model: {
      value: _vm.validPass,
      callback: function callback($$v) {
        _vm.validPass = $$v;
      },
      expression: "validPass"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "headline"
  }, [_vm._v("更新密码")])]), _c('v-card-text', [_c('v-container', [_c('v-text-field', {
    attrs: {
      "label": "请输入新的密码",
      "rules": [_vm.rules.required],
      "type": _vm.showPass ? 'text' : 'password',
      "append-icon": _vm.showPass ? 'visibility_off' : 'visibility',
      "outlined": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.showPass = !_vm.showPass;
      }
    },
    model: {
      value: _vm.editedEmployee.password,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "password", $$v);
      },
      expression: "editedEmployee.password"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "请再次输入密码",
      "rules": [_vm.rules.required, _vm.rules.confirmpass],
      "type": _vm.showPass ? 'text' : 'password',
      "append-icon": _vm.showPass ? 'visibility_off' : 'visibility',
      "outlined": ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.showPass = !_vm.showPass;
      }
    },
    model: {
      value: _vm.editedEmployee.repassword,
      callback: function callback($$v) {
        _vm.$set(_vm.editedEmployee, "repassword", $$v);
      },
      expression: "editedEmployee.repassword"
    }
  })], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.dialogPass = false;
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": "",
      "disabled": !_vm.validPass
    },
    on: {
      "click": _vm.submitPass
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
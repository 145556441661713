import {SettingService} from "@/common/http";

import {
  FETCH_TASK_LIST,
  ORDER_TASK_LIST,
  PUBLISH_TASK,
  EDIT_TASK,
  ACTIV_TASK
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_TASK_LIST,
  SET_TASK_LIST,
  SET_TASK_ALL_LIST
} from "./mutations.type"

const state = {
  taskLoading: true,
  taskList: [],
  taskCount: 0,
};
const getters = {
  taskLoading: state => state.taskLoading,
  taskList: state => state.taskList,
  taskCount: state => state.taskCount
};

const mutations = {
  [LOAD_TASK_LIST] (state) {
    state.taskLoading = true;
  },
  [SET_TASK_LIST](state, list) {
    state.taskCount = list.query.count;
    state.taskList = list.docs;
    state.taskLoading = false;
  },
  [SET_TASK_ALL_LIST](state, list) {
    state.taskList = list.docs;
    state.taskLoading = false;
  }
};

const actions = {
  [FETCH_TASK_LIST]({ commit }, slug) {
    commit(LOAD_TASK_LIST);
    return new Promise((resolve, reject) => {
      SettingService.get('/task/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_TASK_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_TASK](context, data) {
    return SettingService.create('/task', data);
  },
  [EDIT_TASK](context, data) {
    return SettingService.update('/task', data._id, data);
  },
  [ACTIV_TASK](context, data) {
    return SettingService.enable('/task/', data._id +'/'+ data.reply);
  },
  [ORDER_TASK_LIST]({ commit }, dataList) {
    commit(SET_TASK_ALL_LIST, dataList);
    return SettingService.order('task', { list: dataList });
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

<template>
  <v-card>
    <v-card-title>
      批量导入人员信息
      <v-spacer></v-spacer>
      <v-btn icon @click="closeEdit()"><v-icon>close</v-icon></v-btn>
    </v-card-title>
    <v-card-text>
      <v-btn color="primary" depressed block @click="downloadTemplate()"><v-icon left>download</v-icon>第一步：下载模版</v-btn>
      <input
        id="import_file"
        type="file"
        name="import_file"
        ref="uploadInput"
        accept=".xls, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        :multiple="false"
        @change="importFiles($event)"/>
      <v-btn color="primary" depressed block @click="startImport()" :loading="!importEnd" class="my-4"><v-icon left>upload</v-icon>第二步：上传名单</v-btn>
      <v-alert outlined class="my-2" type="error" text v-if="importFailed && importFailed.length">
        导入失败：<ul><li v-for="(fail,index) in importFailed" :key="index">行：{{fail.line}} 错误：{{fail.error}}</li></ul>
      </v-alert>
      <v-alert outlined class="my-2" type="success" text v-if="importSuccess > 0">
        成功导入：{{importSuccess}} 条记录。
      </v-alert>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="closeEdit">取消</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import * as XLSX from "xlsx";
import {mapGetters} from "vuex";
import ApiService from "@/common/http";
import {SET_ERROR} from "@/store/mutations.type";

export default {
  data() {
    return {
      importExcelHeader: [
        '*姓名', '*手机号码', '*登录密码', '*所属部门',
        '*所属角色', '所属业务群', '所属区域', '是否启用'
      ],
      importExcelNotice: [['角色、业务群和区域多个用,分割。']],
      importSuccess: 0,
      importFailed: [],
      importEnd: true
    }
  },
  computed: {
    ...mapGetters([
      'currentUser',
      'uploadSign',
      'currentEmployer']),
  },
  methods: {
    closeEdit() {
      this.importSuccess = 0
      this.importFailed.length = 0
      this.importEnd = false
      this.$emit('Close')
    },
    downloadTemplate() {
      let ws = XLSX.utils.aoa_to_sheet(this.importExcelNotice)
      XLSX.utils.sheet_add_json(ws,
        [],
        { header: this.importExcelHeader, origin: 'A2'}
      )
      ws['!merges'] = [{s: {r:0, c:0}, e: {r:0, c:this.importExcelHeader.length - 1}}]
      ws.A1.s = {alignment:{ wrapText: true }};
      ws.D2.c = [{t: '部门上下级用-符号分割'}];
      ws.D2.c.hidden = true;

      let wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, '人员模版')
      XLSX.writeFile(wb, this.currentEmployer.ownerId.name +'_人员模版.xlsx')
    },
    startImport() {
      this.$refs.uploadInput.click()
    },
    importFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.importEmployee(fileList[x]);
      })
    },
    importEmployee(file) {
      let param = new FormData();
      param.append('file', file);
      ApiService.post('/employee/import', param)
        .then((data) => {
          this.importSuccess = data.data.Success
          this.importFailed = data.data.Failed
          this.$refs.uploadInput.value = '';
          this.importEnd = true
        })
        .catch((err) => {
          this.$refs.uploadInput.value = '';
          this.importSuccess = 0
          this.importFailed = []
          this.importEnd = true
          let arrErr = [];
          if (err.response.data) {
            if (err.response.data.code === 11000) {
              arrErr = err.response.data.writeErrors.map(obj => { return obj.op.personal.name });
            }
          }
          if (arrErr.length > 0) {
            this.$store.commit(SET_ERROR, {msg: '请检查以下数据：'+ arrErr});
          } else {
            this.importFailed = [{line: 2, error: err.response.data.message}]
          }
        });
    },
  }
}
</script>
<style scoped>
.nowrap {
  white-space:nowrap; overflow:hidden; text-overflow:ellipsis;
}
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>


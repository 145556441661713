import { EmployeeService} from "@/common/http";

import {
  FETCH_EMPLOYEE_LIST,
  CREATE_ADMIN,
  CREATE_EMPLOYEE,
  EDIT_EMPLOYEE, FETCH_EMPLOYEE, REMOVE_ADMIN,
  BLOCK_EMPLOYEE, REMOVE_EMPLOYEE,
  FETCH_ADMIN_LIST,
} from "./actions.type";
import {
  LOAD_EMPLOYEE,
  SET_EMPLOYEE,
  SET_ADMIN_LIST,
  SET_EMPLOYEE_LIST,
  SET_ERROR,
} from "./mutations.type";

const state = {
  employeeLoading: false,
  employee: {},
  adminList: [],
  employeeList: [],
  ownEnterpriseList: [],
  employeeCount: 0,
};
const getters = {
  employee: state => state.employee,
  adminList: state => state.adminList,
  employeeLoading: state => state.employeeLoading,
  employeeList: state => state.employeeList,
  ownEnterpriseList: state => state.ownEnterpriseList,
  employeeCount: state => state.employeeCount,
};
const actions = {
  [FETCH_EMPLOYEE_LIST]({ commit }, slug) {
    commit(LOAD_EMPLOYEE);
    return new Promise((resolve, reject) => {
      EmployeeService.get('/list', '?'+ slug)
        .then(({ data }) => {
          commit(SET_EMPLOYEE_LIST, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(SET_ERROR, {msg: response});
          reject(response);
        });
    })
  },
  [FETCH_ADMIN_LIST]({ commit }, eid) {
    return EmployeeService.get('/admin/list', eid)
      .then(({ data }) => {
        commit(SET_ADMIN_LIST, data);
      })
      .catch(error => {
        commit(SET_ERROR, { msg: error });
      })
  },
  [FETCH_EMPLOYEE]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      EmployeeService.get('', slug)
        .then(({ data }) => {
          commit(SET_EMPLOYEE, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [CREATE_EMPLOYEE](context, credentials) {
    return new Promise((resolve, reject) => {
      EmployeeService.create('', credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  [EDIT_EMPLOYEE](context, credentials) {
    return new Promise((resolve, reject) => {
      EmployeeService.update('', credentials._id, credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CREATE_ADMIN](context, credentials) {
    return new Promise((resolve, reject) => {
      EmployeeService.create("/admin", credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  [REMOVE_ADMIN](context, admin) {
    return new Promise((resolve, reject) => {
      EmployeeService.destroy("/admin?admin="+ admin.userid + '&ownertype='+ admin.type +'&ownerid='+ admin.ownerid)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        })
    });
  },
  [BLOCK_EMPLOYEE](context, data) {
    return EmployeeService.batch('/block', data);
  },
  [REMOVE_EMPLOYEE](context, data) {
    return EmployeeService.batch('/remove', data);
  },
};

const mutations = {
  [SET_EMPLOYEE_LIST](state, list) {
    state.employeeList = list.employees;
    state.ownEnterpriseList = list.arrEnterprise;
    state.employeeCount = list.query.count;
    state.employeeLoading = false;
  },
  [LOAD_EMPLOYEE](state) {
    state.employeeLoading = true;
  },
  [SET_EMPLOYEE](state, employee) {
    state.employee = employee;
    state.employeeLoading = false;
  },
  [SET_ADMIN_LIST](state, list) {
    state.adminList = list.employees;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

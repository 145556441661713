<template>
  <v-dialog v-model="dialog" scrollable persistent width="550">
    <template v-slot:activator="{ on, attrs }">
    <span v-bind="attrs" v-on="on" @click="checkData">
      <!--<slot v-if="$slots.default"></slot>-->
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    </template>
    <v-card width="600">
      <v-card-title>
        <span>编辑结算数据</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeEdit">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6">
        <v-form v-model="valid" @submit.prevent="saveSubmit">
          <v-text-field
            v-model="settleItem.agency_service_fee"
            label="代理商服务费（含税）"
            outlined
            :rules="[rules.required]"
            dense
            type="number"
            suffix="元"
            prepend-icon="currency_yen"
          ></v-text-field>
          <v-text-field
            v-model="settleItem.tax"
            label="税金及附加"
            outlined
            :rules="[rules.required]"
            dense
            type="number"
            suffix="元"
            prepend-icon="add_card"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="closeEdit">取消</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="saveSubmit" :disabled="!valid">保存</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
// import Util from "@/common/util";
// import axios from "axios";
import {EDIT_SETTLE} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
//import {mapGetters} from "vuex";
export default {
  data() {
    return {
      valid: true,
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
        counter: value => value.length <= 80 || '最多输入80个字。',
      },
      dialog: false,
      sign_date_menu: false,
      contract_start_menu: false,
      contract_end_menu: false,
      settleItem: {
        agency_service_fee: '',
        tax: '',
        // report_elec_sharp: '',
        // report_elec_peak: '',
        // report_elec_plain: '',
        // report_elec_valley: '',
        // read_elec_sharp: '',
        // read_elec_peak: '',
        // read_elec_plain: '',
        // read_elec_valley: '',
        // settle_elec_sharp: '',
        // settle_elec_peak: '',
        // settle_elec_plain: '',
        // settle_elec_valley: '',
        // diff_bill: '',
        // xt_service_fee: '',
        // share_income: '',
      },
      defaultItem: {
        agency_service_fee: '',
        tax: '',
      },
    }
  },
  props: {
    editData: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  created() {
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    checkData() {
      if(this.editData) {
        this.settleItem = JSON.parse(JSON.stringify(this.editData))
        this.settleItem.agency_service_fee = (this.settleItem.agency_service_fee).toFixed(2)
        this.settleItem.tax = (this.settleItem.tax).toFixed(2)
      }
    },
    closeEdit() {
      this.settleItem = JSON.parse(JSON.stringify(this.defaultItem))
      this.dialog = false
    },
    resetData() {
      this.settleItem.agency_service_fee = Number(Number(this.settleItem.agency_service_fee).toFixed(2))
      this.settleItem.tax = Number(Number(this.settleItem.tax).toFixed(2))
      return this.settleItem
    },
    saveSubmit() {
      let subData = this.resetData()
      let json = {}
      json.id = subData._id
      json.business_type = 3
      json.item = {
        agency_service_fee: subData.agency_service_fee,
        tax: subData.tax
      }
      this.$store.dispatch(EDIT_SETTLE, json).then(() => {
        this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
        this.$emit('reload')
        this.closeEdit()
      })
    },
  },
  directives: {}
}
</script>

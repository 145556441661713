<template>
  <v-dialog v-model="dialog" scrollable persistent width="1000">
    <template v-slot:activator="{ on, attrs }">
    <span v-bind="attrs" v-on="on" @click="checkData">
      <!--<slot v-if="$slots.default"></slot>-->
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    </template>
    <v-card width="600" light>
      <v-card-title>
        <span>结算明细单</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeEdit">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6">
        <div ref="report" class="pa-6">
          <case-receipt-detailed-selling v-if="getType === 3" :settleData="settleData"></case-receipt-detailed-selling>
          <case-receipt-detailed-energy v-if="getType === 1" :settleData="settleData"></case-receipt-detailed-energy>
          <case-receipt-detailed-zhike v-if="getType === 4" :settleData="settleData"></case-receipt-detailed-zhike>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="closeEdit">取消</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="downloadReceipt">下载</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import PDF from "@/common/pdf";
export default {
  data() {
    return {
      dialog: false,
    }
  },
  props: {
    getType: {
      type: Number,
      require: true,
      default: null,
    },
    getId: {
      type: String,
      require: true,
      default: '',
    },
    settleData: {
      type: Object,
      default: null,
    },
    dlgWidth: {
      type: String,
      default: '100%'
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  created() {
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    checkData() {
      console.log('do sth')
    },
    closeEdit() {
      this.dialog = false
    },
    downloadReceipt() {
      this.$nextTick(() => {
        this.$vuetify.goTo(0)
        setTimeout(() => {
          PDF.downloadPDF(this.$refs.report, this.settleData?.agency?.name, 'pic')
        }, 500)
      })
    },
  },
  directives: {}
}
</script>


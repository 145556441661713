import { AccountService } from "@/common/http";
import {
  CHECK_USERNAME,
  CHECK_USERPHONE,
  CREATE_ACCOUNT,
  FETCH_ACCOUNT_LIST,
  EDIT_ACCOUNT,
} from "./actions.type";
import {GET_ACCOUNTS, SET_ACCOUNTS, SET_ERROR} from "./mutations.type";

const state = {
  accountLoading: false,
  accountList: [],
  accountCount: 0,
};

const getters = {
  accountLoading: state => state.accountLoading,
  accountList: state => state.accountList,
  accountCount: state => state.accountCount,
};

const actions = {
  [EDIT_ACCOUNT](context, credentials) {
    return new Promise((resolve, reject) => {
      AccountService.update('', credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [FETCH_ACCOUNT_LIST]({ commit }, slug) {
    commit(GET_ACCOUNTS);
    return new Promise((resolve, reject) => {
      AccountService.get('/list', '?'+ slug)
        .then(({ data }) => {
          commit(SET_ACCOUNTS, data);
          resolve(data);
        })
        .catch(({ response }) => {
          commit(SET_ERROR, {msg: response});
          reject(response);
        });
    })
  },
  [CHECK_USERNAME](context, username) {
    return new Promise((resolve, reject) => {
      AccountService.get("/check/name", username)
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        })
    });
  },
  [CHECK_USERPHONE](context, username) {
    return new Promise((resolve, reject) => {
      AccountService.get("/check/phone", username)
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        })
    });
  },
  [CREATE_ACCOUNT](context, credentials) {
    return new Promise((resolve, reject) => {
      AccountService.create("", credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};

const mutations = {
  [GET_ACCOUNTS](state) {
    state.accountLoading = true;
  },
  [SET_ACCOUNTS](state, list) {
    state.accountList = list.employees;
    state.accountCount = list.query.count;
    state.accountLoading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}



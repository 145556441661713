<template>
  <v-dialog v-model="dialog" scrollable persistent width="475" @input="getEmployee(account)">
    <template v-slot:activator="{ on, attrs }">
      <span :color="btnColor" dark v-bind="attrs" v-on="on" text :ripple="false">
        <v-avatar v-if="avatar" class="mr-2">
          <v-img :src="ossURL +'/'+ avatar" aspect-ratio="1"></v-img>
        </v-avatar>
        <!--<slot v-if="$slots.default"></slot>-->
        <slot></slot> <span v-if="divider">{{divider}}</span>
      </span>
    </template>
    <v-card v-if="!isEdited">
      <v-card-title>
        <v-avatar size="52" class="mr-2" v-if="employeeInfo.personal">
          <v-img :src="employeeInfo.personal.profile ? ossURL +'/'+ employeeInfo.personal.profile : '/static/grey_silhouette.png'" aspect-ratio="1" contain></v-img>
        </v-avatar>
        <v-toolbar-title>{{employeeInfo.personal && employeeInfo.personal.name ? employeeInfo.personal.name : '用户信息'}}</v-toolbar-title>
        <v-chip v-if="employeeInfo.isBlock" class="mx-2" small>已注销</v-chip>
        <v-chip v-if="employeeInfo.status === 9" class="mx-2" small>已删除</v-chip>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
      </v-card-title>
      <v-card-text v-if="employeeInfo.status !== 9">
        <v-container>
          <v-row v-if="employeeInfo.personal && employeeInfo.employer">
            <v-col cols="3" class="text--secondary text-right">姓名</v-col>
            <v-col cols="9"><strong>{{employeeInfo.personal.name}}</strong></v-col>
            <v-col cols="3" class="text--secondary text-right">手机</v-col>
            <v-col cols="9"><strong>{{employeeInfo.phone}}</strong></v-col>
            <v-col cols="3" class="text--secondary text-right">部门</v-col>
            <v-col cols="9"><strong>{{employeeInfo.employer.department.map(item => item.name)}}</strong></v-col>
            <v-col cols="3" class="text--secondary text-right">角色</v-col>
            <v-col cols="9"><strong>{{employeeInfo.employer.roles.map(item => item.name)}}</strong></v-col>
            <v-col cols="3" class="text--secondary text-right">业务群组</v-col>
            <v-col cols="9"><strong>{{employeeInfo.employer.group.map(code => dictBusinessGroup.find(o => o.code === code).name)}}</strong></v-col>
            <v-col cols="3" class="text--secondary text-right">所属区域</v-col>
            <v-col cols="9"><strong>{{employeeInfo.employer.area.map(item => item.name)}}</strong></v-col>
            <v-col cols="3" class="text--secondary text-right">员工编号</v-col>
            <v-col cols="9"><strong>{{employeeInfo.employer.employeeNo}}</strong></v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider v-if="employeeInfo.status !== 9"></v-divider>
      <v-card-actions v-if="employeeInfo.status !== 9 && (currentUser.hasAdmin.includes('admin') || currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('System_Organize'))">
        <v-btn text @click="resetPass" v-if="!employeeInfo.isBlock">重置密码</v-btn>
        <v-btn text @click="blockAccount(true)" v-if="!employeeInfo.isBlock">注销用户</v-btn>
        <v-btn text @click="removeAccount" v-if="!employeeInfo.isBlock">删除用户</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog">取消</v-btn>
        <v-btn color="primary" text @click="editEmployee" v-if="!employeeInfo.isBlock">编辑</v-btn>
        <v-btn color="primary" text @click="blockAccount(false)" v-else>恢复</v-btn>
      </v-card-actions>
      <v-card-actions v-else-if="currentUser._id === employeeInfo._id">
        <v-btn text @click="resetPass" v-if="!employeeInfo.isBlock">重置密码</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="closeDialog">取消</v-btn>
        <v-btn color="primary" text @click="editEmployee" v-if="!employeeInfo.isBlock && (currentUser.hasAdmin.includes('admin') || currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('System_Organize'))">编辑</v-btn>
      </v-card-actions>
    </v-card>
    <account-edit v-else @Close="closeEdit()" :id="employeeInfo._id"></account-edit>
    <v-dialog v-model="dialogPass" width="350">
      <v-form v-model="validPass" @submit.prevent="submitPass">
        <v-card>
          <v-card-title>
            <span class="headline">更新密码</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-text-field
                v-model="editedEmployee.password"
                label="请输入新的密码"
                :rules="[rules.required]"
                :type="showPass ? 'text' : 'password'"
                :append-icon="showPass ? 'visibility_off' : 'visibility'"
                @click:append="showPass = !showPass"
                outlined
              >
              </v-text-field>
              <v-text-field
                v-model="editedEmployee.repassword"
                label="请再次输入密码"
                :rules="[rules.required, rules.confirmpass]"
                :type="showPass ? 'text' : 'password'"
                :append-icon="showPass ? 'visibility_off' : 'visibility'"
                @click:append="showPass = !showPass"
                outlined
              >
              </v-text-field>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text @click="dialogPass = false">取消</v-btn>
            <v-btn color="primary" text @click="submitPass" :disabled="!validPass">保存</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-dialog>
</template>
<script>
import { SET_ERROR } from "@/store/mutations.type";
import { mapGetters } from 'vuex'
import {
  FETCH_EMPLOYEE,
  EDIT_EMPLOYEE,
  BLOCK_EMPLOYEE,
  REMOVE_EMPLOYEE,
  LOGOUT
} from "@/store/actions.type";
import store from "@/store";
import Util from "@/common/util";
export default {
  props: {
    account: {
      type: String,
      required: true,
    },
    btnColor: {
      type: String,
      default: "primary"
    },
    avatar: {
      type: String,
    },
    divider: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      dialogPass: false,
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        phonenum: value => {
          const pattern = /^1[3456789]\d{9}$/;
          return pattern.test(value) || '请输入正确的电话号码'
        },
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || '错误的电子邮件地址'
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
        confirmpass: () => {
          if (this.editedEmployee.password !== this.editedEmployee.repassword) {
            return '两次输入必须一致'
          } else {
            return true
          }
        }
      },
      validPass: true,
      showPass: false,
      showErr: '',
      employeeInfo: {},
      isEdited: false,
      editedEmployee: {},
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    dictBusinessGroup () {
      return Util.categories('businessGroup')
    },
  },
  mounted() {},
  watch: {},
  methods: {
    getEmployee(id) {
      return new Promise((resolve, reject) => {
        store.dispatch(FETCH_EMPLOYEE, id)
          .then((data) => {
            this.employeeInfo = Object.assign({}, data)
            resolve(data)
          })
          .catch(err => {
            reject(err)
          })
      })
    },
    closeDialog () {
      this.getEmployee(this.employeeInfo._id)
      this.dialog = false
    },
    closeEdit () {
      this.getEmployee(this.employeeInfo._id)
      this.isEdited = false
    },
    async editEmployee () {
      this.isEdited = true
    },
    resetPass() {
      this.dialogPass = true
    },
    submitPass() {
      let reqEmployee = {}
      reqEmployee._id = this.employeeInfo._id
      reqEmployee.password = this.editedEmployee.password
      this.$store.dispatch(EDIT_EMPLOYEE, reqEmployee)
        .then(() => {
          this.$store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
          if (this.currentUser._id === this.employeeInfo._id) {
            this.$store.dispatch(LOGOUT).then(() => {
              location.reload()
            })
          } else {
            this.editedEmployee.password = ''
            this.editedEmployee.repassword = ''
            this.dialogPass = false
          }
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    blockAccount(block) {
      store.dispatch(BLOCK_EMPLOYEE, {ids: [this.employeeInfo._id], block})
        .then(() => {
          store.commit(SET_ERROR, {msg: '修改完成', color: 'primary'});
          this.getEmployee(this.employeeInfo._id)
            .then((data => {
              this.$emit('UpdateEmployee', data)
            }))
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    removeAccount() {
      store.dispatch(REMOVE_EMPLOYEE, {ids: [this.employeeInfo._id]})
        .then(() => {
          store.commit(SET_ERROR, {msg: '修改完成', color: 'primary'});
          this.getEmployee(this.employeeInfo._id)
            .then((data => {
              this.$emit('UpdateEmployee', data)
            }))
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
  },
}
</script>

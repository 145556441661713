import { RoleService } from "@/common/http";

import {
  FETCH_ROLE_LIST,
  CREATE_ROLE,
  EDIT_ROLE,
  REMOVE_ROLE,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_ROLE_LIST,
  SET_ROLE_LIST,
  SET_ROLE
} from "./mutations.type"

const state = {
  roleLoading: true,
  roleList: [],
  role: {},
};

const getters = {
  roleList: state => state.roleList,
  role: state => state.role,
  roleLoading: state => state.roleLoading,
};

const actions = {
  [FETCH_ROLE_LIST]({ commit }, slug) {
    commit(LOAD_ROLE_LIST);
    return new Promise((resolve, reject) => {
      RoleService.get('/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_ROLE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [CREATE_ROLE](context, credentials) {
    return new Promise((resolve, reject) => {
      RoleService.create('', credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  [EDIT_ROLE](context, credentials) {
    return new Promise((resolve, reject) => {
      RoleService.update('', credentials._id, credentials)
        .then(({ data }) => {
          context.commit(SET_ROLE, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [REMOVE_ROLE](context, credentials) {
    return new Promise((resolve, reject) => {
      RoleService.destroy(credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
};

const mutations = {
  [LOAD_ROLE_LIST] (state) {
    state.roleLoading = true;
  },
  [SET_ROLE_LIST](state, data) {
    state.roleList = data;
    state.roleLoading = false;
  },
  [SET_ROLE](state, data) {
    state.role = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

import { ReportService } from "@/common/http";
import {
  FETCH_REPORT_SUMMARY,
  FETCH_REPORT_ELEC,
  FETCH_REPORT_SERVICE,
  FETCH_REPORT_INCOME,
  FETCH_REPORT_SETTLE,
  FETCH_REPORT_AREA_SUMMARY,
  FETCH_REPORT_AREA_LIST,
  EDIT_REPORT_AREA_SUMMARY,
  FETCH_REPORT_SETTLE_SUMMARY,
} from "./actions.type";
import {
  SET_REPORT_SETTLE,
  LOAD_REPORT_SETTLE, SET_ERROR,
  SET_REPORT_DRAWER,
  LOAD_REPORT_AREA_LIST, SET_REPORT_AREA_LIST,
  SET_REPORT_SETTLE_SUMMARY,
  LOAD_REPORT_SETTLE_SUMMARY,
} from "./mutations.type.js";

const state = {
  elecDrawer: false,
  serviceDrawer: false,
  incomeDrawer: false,
  reportSettleLoading: false,
  reportSettle: [],
  reportSettleCount: 0,
  reportSettleSummaryLoading: false,
  reportSettleSummary: [],
  reportSettleSummaryCount: 0,
  reportAreaListLoading: false,
  reportAreaList: [],
  reportAreaListCount: 0,
};
const getters = {
  elecDrawer: state => state.elecDrawer,
  serviceDrawer: state => state.serviceDrawer,
  incomeDrawer: state => state.incomeDrawer,
  reportSettleLoading: state => state.reportSettleLoading,
  reportSettle: state => state.reportSettle,
  reportSettleCount: state => state.reportSettleCount,
  reportSettleSummaryLoading: state => state.reportSettleSummaryLoading,
  reportSettleSummary: state => state.reportSettleSummary,
  reportSettleSummaryCount: state => state.reportSettleSummaryCount,
  reportAreaListLoading: state => state.reportAreaListLoading,
  reportAreaList: state => state.reportAreaList,
  reportAreaListCount: state => state.reportAreaListCount,
};

const actions = {
  async [FETCH_REPORT_SUMMARY](context, slug) {
    const { data } = await ReportService.get('/summary', slug);
    return data;
  },
  async [FETCH_REPORT_ELEC](context, slug) {
    const { data } = await ReportService.get('/elec', '?'+slug);
    return data;
  },
  async [FETCH_REPORT_SERVICE](context, slug) {
    const { data } = await ReportService.get('/fee', '?'+slug);
    return data;
  },
  async [FETCH_REPORT_INCOME](context, slug) {
    const { data } = await ReportService.get('/profit', '?'+slug);
    return data;
  },
  async [FETCH_REPORT_AREA_SUMMARY](context, slug) {
    const { data } = await ReportService.get('/areaDataSummary', '?'+slug);
    return data;
  },
  [FETCH_REPORT_SETTLE]({ commit }, params) {
    commit(LOAD_REPORT_SETTLE);
    return new Promise((resolve, reject) => {
      ReportService.get('/settle', '?'+params.query)
        .then(({ data }) => {
          commit(SET_REPORT_SETTLE, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_REPORT_SETTLE_SUMMARY]({ commit }, params) {
    commit(LOAD_REPORT_SETTLE_SUMMARY);
    return new Promise((resolve, reject) => {
      ReportService.get('/settleSummary', '?'+params.query)
        .then(({ data }) => {
          commit(SET_REPORT_SETTLE_SUMMARY, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [FETCH_REPORT_AREA_LIST]({ commit }, params) {
    commit(LOAD_REPORT_AREA_LIST);
    return new Promise((resolve, reject) => {
      ReportService.get('/areaDataList', '?'+params.query)
        .then(({ data }) => {
          commit(SET_REPORT_AREA_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [EDIT_REPORT_AREA_SUMMARY](context, params) {
    return ReportService.update('/areaDataSummary/' + params.id, params.item);
  },
};

const mutations = {
  [SET_REPORT_DRAWER](state, property) {
    switch (property) {
      case 'elec': {
        state.elecDrawer = !state.elecDrawer
        state.serviceDrawer = false
        state.incomeDrawer = false
        break
      }
      case 'service': {
        state.serviceDrawer = !state.serviceDrawer
        state.elecDrawer = false
        state.incomeDrawer = false
        break
      }
      case 'income': {
        state.incomeDrawer = !state.incomeDrawer
        state.elecDrawer = false
        state.serviceDrawer = false
        break
      }
      default: {
        state.incomeDrawer = false
        state.elecDrawer = false
        state.serviceDrawer = false
      }
    }
  },
  [LOAD_REPORT_SETTLE] (state) {
    state.reportSettleLoading = true;
  },
  [SET_REPORT_SETTLE] (state, list) {
    state.reportSettle = list.docs?.map(item => {
      item.list = Array.from(Array(12).keys(), month =>
        item.list.find(m => +m.month === month+1) || { month: month+1, done: 0, other: 0 }
      )
      return item
    })
    state.reportSettleCount = list.query.count
    state.reportSettleLoading = false;
  },
  [LOAD_REPORT_SETTLE_SUMMARY] (state) {
    state.reportSettleSummaryLoading = true;
  },
  [SET_REPORT_SETTLE_SUMMARY] (state, list) {
    state.reportSettleSummary = list.docs?.map(item => {
      item.list = Array.from(Array(12).keys(), month =>
        item.list.find(m => +m.month === month+1) || { month: month+1, done: 0, other: 0, total: 0 }
      )
      return item
    })
    state.reportSettleSummaryCount = list.query.count
    state.reportSettleSummaryLoading = false;
  },
  [LOAD_REPORT_AREA_LIST] (state) {
    state.reportAreaListLoading = true;
  },
  [SET_REPORT_AREA_LIST] (state, list) {
    state.reportAreaList = list.docs
    state.reportAreaListCount = list.query.count
    state.reportAreaListLoading = false
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};


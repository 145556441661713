var render = function render(){
  var _vm$settleData$case_n, _vm$settleData$agency, _vm$settleData$settle, _vm$settleData$settle2, _vm$currentUser$perso, _vm$settleData$agency2, _vm$settleData$agency3, _vm$settleData$agency4, _vm$settleData$agency5, _vm$settleData$agency6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "width": "900"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({
          on: {
            "click": _vm.checkData
          }
        }, 'span', attrs, false), on), [_vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2)];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "width": "600",
      "light": ""
    }
  }, [_c('v-card-title', [_c('span', [_vm._v("付款审批单")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-6"
  }, [_c('div', {
    ref: "report",
    staticClass: "pa-12 pt-0",
    attrs: {
      "id": "report"
    }
  }, [_c('v-container', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('img', {
    attrs: {
      "src": "/static/logofont.png",
      "width": "200"
    }
  })]), _c('v-col', {
    staticClass: "d-flex justify-center align-center text-h5 font-weight-black",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v("付款审批单")]), _c('v-col', {
    attrs: {
      "cols": "4"
    }
  })], 1), _c('v-row', [_c('v-col', {
    staticClass: "d-flex justify-start",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("单位：")]), _vm._v(_vm._s(_vm.isAreaGD ? '北京鑫泰能源股份有限公司广东分公司' : '北京鑫泰能源股份有限公司'))]), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "6"
    }
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("流程编号：")]), _vm._v(_vm._s((_vm$settleData$case_n = _vm.settleData.case_number) === null || _vm$settleData$case_n === void 0 ? void 0 : _vm$settleData$case_n.replace('SC', 'RB')))])], 1), _c('v-row', [_c('table', {
    staticClass: "receiptTable caption"
  }, [_c('tr', [_c('td', {
    staticClass: "align-center",
    staticStyle: {
      "width": "55%"
    },
    attrs: {
      "colspan": "3",
      "rowspan": "5"
    }
  }, [_c('table', {
    staticStyle: {
      "width": "100%"
    }
  }, [_c('tr', [_c('td', {
    staticClass: "no-border text-right font-weight-bold",
    staticStyle: {
      "width": "40%"
    }
  }, [_vm._v("代理商名称")]), _c('td', {
    staticClass: "no-border"
  }, [_vm._v(_vm._s((_vm$settleData$agency = _vm.settleData.agency) === null || _vm$settleData$agency === void 0 ? void 0 : _vm$settleData$agency.name))])]), _c('tr', [_c('td', {
    staticClass: "no-border text-right font-weight-bold",
    staticStyle: {
      "width": "40%"
    }
  }, [_vm._v("业务类别及月份")]), _c('td', {
    staticClass: "no-border"
  }, [_vm._v(" " + _vm._s(this.getType === 1 ? '能源' : this.getType === 2 ? '调峰' : this.getType === 3 ? '售电' : '智科') + " " + _vm._s((_vm$settleData$settle = _vm.settleData.settleList[0]) === null || _vm$settleData$settle === void 0 ? void 0 : (_vm$settleData$settle2 = _vm$settleData$settle.area) === null || _vm$settleData$settle2 === void 0 ? void 0 : _vm$settleData$settle2.name) + " " + _vm._s(_vm.dateArr.length > 1 ? _vm.minDate.getFullYear() + '年' + (_vm.minDate.getMonth() + 1) + '月' + '-' + _vm.maxDate.getFullYear() + '年' + (_vm.maxDate.getMonth() + 1) + '月' : new Date(_vm.dateArr[0]).getFullYear() + '年' + (new Date(_vm.dateArr[0]).getMonth() + 1) + '月') + " ")])]), _c('tr', [_c('td', {
    staticClass: "no-border text-right font-weight-bold",
    staticStyle: {
      "width": "40%"
    }
  }, [_vm._v("本次申请支付")]), _vm.getType === 3 ? _c('td', {
    staticClass: "no-border"
  }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(_vm.settleData.settleSumData.agency_fee_total)) + "元")]) : _c('td', {
    staticClass: "no-border"
  }, [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(_vm.agency_fee_has_tax_total)) + "元")])])])]), _c('td', {
    staticClass: "font-weight-bold text-center",
    staticStyle: {
      "width": "15%"
    }
  }, [_vm._v("日期")]), _c('td', [_vm._v(_vm._s(new Date().getFullYear()) + " 年 " + _vm._s(new Date().getMonth() + 1) + " 月 " + _vm._s(new Date().getDate()) + " 日")])]), _c('tr', [_c('td', {
    staticClass: "font-weight-bold text-center no-border-left"
  }, [_vm._v("经办人")]), _c('td', [_vm._v(_vm._s((_vm$currentUser$perso = _vm.currentUser.personal) === null || _vm$currentUser$perso === void 0 ? void 0 : _vm$currentUser$perso.name))])]), _c('tr', [_c('td', {
    staticClass: "font-weight-bold text-center no-border-left"
  }, [_vm._v("财务审核")]), _c('td')]), _c('tr', [_c('td', {
    staticClass: "font-weight-bold text-center no-border-left"
  }, [_vm._v("付款方式")]), _c('td', [_c('input', {
    staticClass: "ma-2",
    attrs: {
      "type": "checkbox"
    }
  }), _c('span', {
    staticClass: "caption black--text"
  }, [_vm._v("现金")]), _c('input', {
    staticClass: "ma-2",
    attrs: {
      "type": "checkbox"
    }
  }), _c('span', {
    staticClass: "caption black--text"
  }, [_vm._v("网银")]), _c('input', {
    staticClass: "ma-2",
    attrs: {
      "type": "checkbox"
    }
  }), _c('span', {
    staticClass: "caption black--text"
  }, [_vm._v("支票")])])]), _c('tr', [_c('td', {
    staticClass: "font-weight-bold text-center no-border-left"
  }, [_vm._v("合计（小写）")]), _vm.getType === 3 ? _c('td', [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(_vm.settleData.settleSumData.agency_fee_total)) + "元")]) : _c('td', [_vm._v("¥" + _vm._s(_vm._f("formatMoney")(_vm.agency_fee_has_tax_total)) + "元")])]), _c('tr', [_c('td', {
    staticClass: "text-right font-weight-bold"
  }, [_vm._v("大写金额：人民币")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v(_vm._s(_vm.NumInChs))])]), _c('tr', [_c('td', {
    staticClass: "text-right font-weight-bold"
  }, [_vm._v("全称")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v(_vm._s((_vm$settleData$agency2 = _vm.settleData.agency) === null || _vm$settleData$agency2 === void 0 ? void 0 : _vm$settleData$agency2.name))])]), _c('tr', [_c('td', {
    staticClass: "text-right font-weight-bold"
  }, [_vm._v("账号")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v(_vm._s((_vm$settleData$agency3 = _vm.settleData.agency) === null || _vm$settleData$agency3 === void 0 ? void 0 : (_vm$settleData$agency4 = _vm$settleData$agency3.invoice_info) === null || _vm$settleData$agency4 === void 0 ? void 0 : _vm$settleData$agency4.bank_account))])]), _c('tr', [_c('td', {
    staticClass: "text-right font-weight-bold"
  }, [_vm._v("开户行")]), _c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_vm._v(_vm._s((_vm$settleData$agency5 = _vm.settleData.agency) === null || _vm$settleData$agency5 === void 0 ? void 0 : (_vm$settleData$agency6 = _vm$settleData$agency5.invoice_info) === null || _vm$settleData$agency6 === void 0 ? void 0 : _vm$settleData$agency6.bank_name))])]), _vm.getType !== 3 ? _c('tr', [_c('td', {
    staticClass: "font-weight-bold px-0",
    attrs: {
      "colspan": "5"
    }
  }, [_c('div', {
    staticClass: "pa-0 ma-0"
  }, [_c('table', {
    staticClass: "receiptTable caption font-weight-bold"
  }, [_c('tr', [_c('td', {
    staticClass: "no-border sign_block",
    staticStyle: {
      "border-right": "1px solid #000"
    }
  }, [_vm._v("部门负责人")]), _c('td', {
    staticClass: "no-border sign_block",
    staticStyle: {
      "border-right": "1px solid #000"
    }
  }, [_vm._v("副总（风控）")]), _c('td', {
    staticClass: "no-border sign_block",
    staticStyle: {
      "border-right": "1px solid #000"
    }
  }, [_vm._v("总经理")]), _c('td', {
    staticClass: "no-border sign_block"
  }, [_vm._v("副董事长")])])])])])]) : _c('tr', [_c('td', {
    staticClass: "font-weight-bold px-0",
    attrs: {
      "colspan": "5"
    }
  }, [_c('div', {
    staticClass: "pa-0 ma-0"
  }, [_c('table', {
    staticClass: "receiptTable caption font-weight-bold"
  }, [_c('tr', [_vm.getType === 3 && !_vm.isAreaHB ? _c('td', {
    staticClass: "no-border sign_block",
    staticStyle: {
      "position": "relative",
      "border-right": "1px solid #000"
    }
  }, [_vm._v("区域负责人 "), _c('div', {
    staticStyle: {
      "position": "absolute",
      "right": "0",
      "top": "0",
      "background": "linear-gradient(-24.5deg, transparent 49.5%, black 49.5%, black 50.5%, transparent 50.5%)",
      "width": "100%",
      "height": "100%"
    }
  })]) : _c('td', {
    staticClass: "no-border sign_block",
    staticStyle: {
      "border-right": "1px solid #000"
    }
  }, [_vm._v("区域负责人")]), _c('td', {
    staticClass: "no-border sign_block",
    staticStyle: {
      "border-right": "1px solid #000"
    }
  }, [_vm._v("副总（营销）")]), _c('td', {
    staticClass: "no-border sign_block",
    staticStyle: {
      "border-right": "1px solid #000"
    }
  }, [_vm._v("副总（风控）")]), _c('td', {
    staticClass: "no-border sign_block",
    staticStyle: {
      "border-right": "1px solid #000"
    }
  }, [_vm._v("总经理")]), _c('td', {
    staticClass: "no-border sign_block"
  }, [_vm._v("副董事长")])])])])])])])])], 1)], 1)]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": _vm.printing
    },
    on: {
      "click": _vm.printReceipt
    }
  }, [_vm._v("打印")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": _vm.downloading
    },
    on: {
      "click": _vm.downloadReceipt
    }
  }, [_vm._v("下载")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
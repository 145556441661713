<template>
  <v-dialog v-model="dialog" scrollable persistent width="900">
    <template v-slot:activator="{ on, attrs }">
    <span v-bind="attrs" v-on="on" @click="checkData">
      <!--<slot v-if="$slots.default"></slot>-->
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    </template>
    <v-card width="600" light>
      <v-card-title>
        <span>付款审批单</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeEdit">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6">
        <div id="report" ref="report" class="pa-12 pt-0">
          <v-container>
            <v-row>
              <v-col cols="4"><img src="/static/logofont.png" width="200"></v-col>
              <v-col cols="4" class="d-flex justify-center align-center text-h5 font-weight-black">付款审批单</v-col>
              <v-col cols="4"></v-col>
            </v-row>
            <v-row>
              <v-col cols="6" class="d-flex justify-start"><span class="font-weight-bold">单位：</span>{{ isAreaGD?'北京鑫泰能源股份有限公司广东分公司':'北京鑫泰能源股份有限公司' }}</v-col>
              <v-col cols="6" class="d-flex justify-end"><span class="font-weight-bold">流程编号：</span>{{ settleData.case_number?.replace('SC', 'RB') }}</v-col>
            </v-row>
            <v-row>
              <table class="receiptTable caption">
                <tr>
                  <td colspan="3" rowspan="5" style="width: 55%;" class="align-center">
                    <table style="width: 100%;">
                      <tr>
                        <td class="no-border text-right font-weight-bold" style="width: 40%">代理商名称</td>
                        <td class="no-border">{{ settleData.agency?.name }}</td>
                      </tr>
                      <tr>
                        <td class="no-border text-right font-weight-bold" style="width: 40%">业务类别及月份</td>
                        <td class="no-border">
                          {{ this.getType === 1 ? '能源' : this.getType === 2 ? '调峰' : this.getType === 3 ? '售电' : '智科' }}
                          {{ settleData.settleList[0]?.area?.name }}
                          {{ dateArr.length>1 ? minDate.getFullYear() + '年' + (minDate.getMonth()+1) + '月' + '-' + maxDate.getFullYear() + '年' + (maxDate.getMonth()+1) + '月' : new Date(dateArr[0]).getFullYear() + '年' + (new Date(dateArr[0]).getMonth()+1) + '月' }}
                        </td>
                      </tr>
                      <tr>
                        <td class="no-border text-right font-weight-bold" style="width: 40%">本次申请支付</td>
                        <td class="no-border" v-if="getType === 3">¥{{ settleData.settleSumData.agency_fee_total | formatMoney }}元</td>
                        <td class="no-border" v-else>¥{{ agency_fee_has_tax_total | formatMoney }}元</td>
                      </tr>
<!--                        <tr>-->
<!--                          <td class="no-border text-right font-weight-bold" style="width: 40%">本年累计支付</td>-->
<!--                          <td class="no-border" v-if="getType === 3">¥{{ (settleData.paidYear?.$numberDecimal * 1 + settleData.settleSumData.agency_fee_total * 1) | formatMoney }}元</td>-->
<!--                          <td class="no-border" v-else>¥{{ (settleData.paidYear?.$numberDecimal * 1 + agency_fee_has_tax_total) | formatMoney }}元</td>-->
<!--                        </tr>-->
                    </table>
                  </td>
                  <td style="width: 15%" class="font-weight-bold text-center">日期</td>
                  <td>{{ new Date().getFullYear() }}&nbsp;年&nbsp;{{ new Date().getMonth()+1 }}&nbsp;月&nbsp;{{ new Date().getDate() }}&nbsp;日</td>
                </tr>
                <tr>
                  <td class="font-weight-bold text-center no-border-left">经办人</td>
                  <td>{{ currentUser.personal?.name}}</td>
                </tr>
                <tr>
                  <td class="font-weight-bold text-center no-border-left">财务审核</td>
                  <td></td>
                </tr>
                <tr>
                  <td class="font-weight-bold text-center no-border-left">付款方式</td>
                  <td>
                      <input type="checkbox" class="ma-2" />
                      <span class="caption black--text">现金</span>
                      <input type="checkbox" class="ma-2" />
                      <span class="caption black--text">网银</span>
                      <input type="checkbox" class="ma-2" />
                      <span class="caption black--text">支票</span>
                  </td>
                </tr>
                <tr>
                  <td class="font-weight-bold text-center no-border-left">合计（小写）</td>
                  <td v-if="getType === 3">¥{{ settleData.settleSumData.agency_fee_total | formatMoney }}元</td>
                  <td v-else>¥{{ agency_fee_has_tax_total | formatMoney }}元</td>
                </tr>
                <tr>
                  <td class="text-right font-weight-bold">大写金额：人民币</td>
                  <td colspan="4" class="text-center">{{ NumInChs }}</td>
                </tr>
                <tr>
                  <td class="text-right font-weight-bold">全称</td>
                  <td colspan="4" class="text-center">{{ settleData.agency?.name}}</td>
                </tr>
                <tr>
                  <td class="text-right font-weight-bold">账号</td>
                  <td colspan="4" class="text-center">{{ settleData.agency?.invoice_info?.bank_account }}</td>
                </tr>
                <tr>
                  <td class="text-right font-weight-bold">开户行</td>
                  <td colspan="4" class="text-center">{{ settleData.agency?.invoice_info?.bank_name }}</td>
                </tr>
                <tr v-if="getType !== 3">
                  <td class="font-weight-bold px-0" colspan="5">
                    <div class="pa-0 ma-0">
                      <table class="receiptTable caption font-weight-bold">
                        <tr>
                          <td class="no-border sign_block" style="border-right: 1px solid #000;">部门负责人</td>
                          <td class="no-border sign_block" style="border-right: 1px solid #000;">副总（风控）</td>
                          <td class="no-border sign_block" style="border-right: 1px solid #000;">总经理</td>
                          <td class="no-border sign_block">副董事长</td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
                <tr v-else>
                  <td class="font-weight-bold px-0" colspan="5">
                    <div class="pa-0 ma-0">
                      <table class="receiptTable caption font-weight-bold">
                        <tr>
                          <td class="no-border sign_block" style="position: relative; border-right: 1px solid #000;" v-if="getType === 3 && !isAreaHB">区域负责人
                            <div style="position: absolute; right: 0; top: 0; background: red; width: 100%; height: 100%; background: linear-gradient(-24.5deg, transparent 49.5%, black 49.5%, black 50.5%, transparent 50.5%);"></div>
                          </td>
                          <td v-else class="no-border sign_block" style="border-right: 1px solid #000;">区域负责人</td>
                          <td class="no-border sign_block" style="border-right: 1px solid #000;">副总（营销）</td>
                          <td class="no-border sign_block" style="border-right: 1px solid #000;">副总（风控）</td>
                          <td class="no-border sign_block" style="border-right: 1px solid #000;">总经理</td>
                          <td class="no-border sign_block">副董事长</td>
                        </tr>
                      </table>
                    </div>
                  </td>
                </tr>
              </table>
            </v-row>
          </v-container>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="closeEdit">取消</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="printReceipt" :disabled="printing">打印</v-btn>
        <v-btn text color="primary" @click="downloadReceipt" :disabled="downloading">下载</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import {PRINT_CASE, DOWNLOAD_CASE_PAYMENT} from "@/store/actions.type";
import PDF from "@/common/pdf";
// import Index from "@/views/distributor/filing/index.vue";
import {mapGetters} from "vuex";
export default {
  // components: {Index},
  data() {
    return {
      dialog: false,
      type: ['现金', '网银', '支票'],
      agency_fee_has_tax_total: 0,
      NumInChs: '',
      customersList: [],
      isAreaHB: false,
      isAreaGD: false,
      dateArr: [],
      minDate: '',
      maxDate: '',
      printing: false,
      downloading: false,
    }
  },
  props: {
    getType: {
      type: Number,
      require: true,
      default: null,
    },
    getId: {
      type: String,
      require: true,
      default: '',
    },
    settleData: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  watch: {
    settleData() {
      this.checkData()
    }
  },
  created() {
    this.checkData()
  },
  computed: {
    ...mapGetters(["currentUser"])
  },
  mounted() {

  },
  methods: {
    checkData() {
      this.agency_fee_has_tax_total = 0
      this.settleData.settleList?.map((item, i) => {
        if (i === 0) {
          this.isAreaHB = item.area?.name === '湖北' || item.area?.name === '北京' || item.area?.name === '山东'
          this.isAreaGD = item.area?.name === '广东'
        }
        this.dateArr.push(Date.parse(item.date))
        this.customersList.push(item.customer.name)
        this.agency_fee_has_tax_total += Number(item.agency_fee?.$numberDecimal)
      })
      if (this.getType === 3) {
        this.NumInChs = this.$options.filters['NumToChs'](Number(this.settleData.settleSumData.agency_fee_total).toFixed(2))
      } else {
        this.NumInChs = this.$options.filters['NumToChs'](this.agency_fee_has_tax_total.toFixed(2))
      }
      this.dateArr = [...new Set(this.dateArr)]
      if (this.dateArr.length > 1) {
        this.minDate = new Date(Math.min(...this.dateArr))
        this.maxDate = new Date(Math.max(...this.dateArr))
      }
      this.customersList = [...new Set(this.customersList)]
    },
    closeEdit() {
      this.agency_fee_has_tax_total = 0
      this.dialog = false
    },
    downloadReceipt() {
      this.downloading = true
      this.$nextTick(() => {
        this.$vuetify.goTo(0)
        setTimeout(() => {
          PDF.downloadPDF(this.$refs.report, this.settleData.agency?.name + '-付款审批单', 'pic')
          this.$store.dispatch(DOWNLOAD_CASE_PAYMENT, this.getId).then(() => {
            this.downloading = false
          })
        }, 500)
      })
    },
    async printReceipt() {
      // await this.$htmlToPaper('report');
      this.printing = true
      this.$htmlToPaper('report', { windowTitle: this.settleData.agency?.name + '-付款审批单' }, () => {
        this.$store.dispatch(PRINT_CASE, this.getId).then(() => {
          this.printing = false
        })
      });
    }
  },
  directives: {}
}
</script>

<style scoped>
.receiptTable {
  width: 1px;
  min-width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.receiptTable td,
.receiptTable th {
  padding: 0 4px;
  height: 30px;
  border: 1px solid rgb(0, 0, 0);
  color: #000 !important;
}
.receiptTable td.no-border {
  border: 0;
  padding: 20px 4px;
}
.receiptTable>tr>th+th {
  border-left-width: 0;
}
.receiptTable>tr+tr>th {
  border-top-width: 0;
}
.receiptTable>tr>td+td {
  border-left-width: 0;
}
.receiptTable>tr+tr>td {
  border-top-width: 0;
}
.receiptTable .no-border-right {
  border-right: 0 !important;
}
.receiptTable .no-border-left {
  border-left: 0 !important;
}
.receiptTable  .no-border-bottom {
  border-bottom: 0 !important;
}
.receiptTable .no-border-top {
  border-top: 0 !important;
}
.receiptTable td.sign_block {
  padding: 0 4px;
  height: 68px;
  vertical-align: top;
}
</style>

<template>
  <v-dialog v-model="dialog" scrollable persistent width="550">
    <template v-slot:activator="{ on, attrs }">
    <span v-bind="attrs" v-on="on" @click="checkData">
      <!--<slot v-if="$slots.default"></slot>-->
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    </template>
    <v-card width="600">
      <v-card-title>
        <span>编辑合同</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeEdit">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6">
        <v-form v-model="valid" @submit.prevent="saveSubmit">
          <v-select
            v-if="(getType === 'customer' || getType === 'agency') && !reSign"
            v-model="contractItem.business_type"
            :items="userBusinessType"
            label="合同类型*"
            :rules="[rules.selected]"
            item-text="name"
            item-value="code"
            outlined
            dense
            prepend-icon="list"
            :disabled="(getType === 'agency' && !isEdit && checkOnly) || contractItem.isForce"
          ></v-select>
          <v-text-field
            v-if="getType === 'customer' && !reSign"
            v-model="contractItem.contract_name"
            label="合同名称*"
            outlined
            :rules="[rules.required]"
            dense
            prepend-icon="gavel"
          ></v-text-field>
          <v-text-field
            v-if="!reSign"
            v-model="contractItem.file_no"
            label="档案号"
            outlined
            dense
            prepend-icon="pin"
            :disabled="(getType === 'agency' && !isEdit && checkOnly) || contractItem.isForce"
          ></v-text-field>
          <v-text-field
            v-if="getType === 'customer' && !reSign"
            v-model="contractItem.electric_use"
            label="年用电量*"
            outlined
            :rules="[rules.required]"
            dense
            type="number"
            suffix="兆瓦时"
            prepend-icon="bolt"
          ></v-text-field>
          <v-menu ref="sign_date" v-model="sign_date_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-if="getType === 'customer' || getType === 'agency'"
                v-model="contractItem.sign_date"
                label="签约时间*"
                :rules="[rules.required]"
                readonly
                outlined
                dense
                clearable
                v-bind="attrs"
                v-on="on"
                prepend-icon="fact_check"
                :disabled="(getType === 'agency' && !isEdit && checkOnly) || contractItem.isForce"
              ></v-text-field>
            </template>
            <v-date-picker v-model="contractItem.sign_date" no-title scrollable @input="sign_date_menu = false">
            </v-date-picker>
          </v-menu>
          <v-menu ref="sign_date" v-model="contract_start_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-if="getType === 'customer' || getType === 'agency'"
                v-model="contractItem.contract_start"
                label="合同开始日期*"
                :rules="[rules.required]"
                readonly
                outlined
                dense
                clearable
                v-bind="attrs"
                v-on="on"
                prepend-icon="event_available"
                :disabled="(getType === 'agency' && !isEdit && checkOnly) || contractItem.isForce"
              ></v-text-field>
            </template>
            <v-date-picker v-model="contractItem.contract_start" no-title scrollable @input="contract_start_menu = false">
            </v-date-picker>
          </v-menu>
          <v-menu ref="sign_date" v-model="contract_end_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-if="getType === 'customer' || getType === 'agency'"
                v-model="contractItem.contract_end"
                label="合同结束日期*"
                :rules="[rules.required]"
                readonly
                outlined
                dense
                clearable
                v-bind="attrs"
                v-on="on"
                prepend-icon="event_busy"
                :disabled="(getType === 'agency' && !isEdit && checkOnly) || contractItem.isForce"
              ></v-text-field>
            </template>
            <v-date-picker v-model="contractItem.contract_end" no-title scrollable @input="contract_end_menu = false">
            </v-date-picker>
          </v-menu>
          <v-text-field
            v-if="getType === 'customer' && !reSign"
            v-model="contractItem.price"
            label="签约电价*"
            outlined
            :rules="[rules.required]"
            dense
            type="number"
            suffix="元"
            prepend-icon="currency_yen"
          ></v-text-field>
          <v-select
            v-if="getType === 'customer' && !reSign"
            v-model="contractItem.sign_type"
            :items="signType"
            label="签约方式*"
            :rules="[rules.selected]"
            item-text="name"
            item-value="code"
            outlined
            dense
            prepend-icon="checklist"
          ></v-select>
          <v-text-field
            v-if="getType === 'customer' && contractItem.sign_type === 5 && !reSign"
            v-model="contractItem.sign_other"
            label="签约方式说明"
            outlined
            dense
            prepend-icon="assignment"
          ></v-text-field>
          <v-text-field
            v-if="getType === 'customer' && contractItem.sign_type === 1 && !reSign"
            v-model="contractItem.xt_ratio"
            label="鑫泰比例*"
            outlined
            :rules="[rules.required]"
            dense
            type="number"
            suffix="%"
            prepend-icon="group_add"
          ></v-text-field>
          <v-text-field
            v-if="getType === 'customer' && contractItem.sign_type === 1 && !reSign"
            v-model="contractItem.customer_ratio"
            label="客户比例*"
            outlined
            :rules="[rules.required]"
            dense
            type="number"
            suffix="%"
            prepend-icon="group_remove"
          ></v-text-field>
          <v-text-field
            v-if="getType === 'customer' && contractItem.sign_type === 3 && !reSign"
            v-model="contractItem.fixed_price"
            label="固定单价*"
            outlined
            :rules="[rules.required]"
            dense
            type="number"
            suffix="元/兆瓦时"
            prepend-icon="currency_yen"
          ></v-text-field>
          <v-text-field
            v-if="getType === 'customer' && contractItem.sign_type === 2 && !reSign"
            v-model="contractItem.fixed_fee"
            label="固定服务费*"
            outlined
            :rules="[rules.required]"
            dense
            type="number"
            suffix="元"
            prepend-icon="currency_yen"
          ></v-text-field>
          <v-textarea
            v-if="!reSign"
            v-model="contractItem.remark"
            label="备注"
            autocomplete="off"
            clearable
            outlined
            dense
            no-resize
            prepend-icon="comment"
            :disabled="(getType === 'agency' && !isEdit && checkOnly) || contractItem.isForce"
          ></v-textarea>
          <v-list dense v-if="getType === 'customer' && !reSign">
            <v-list-item class="px-0" v-for="(n, i) in this.preAttUrl" :key="i+'_preatt'">
              <v-list-item-avatar rounded="0" size="42">
                <v-icon x-large>description</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <v-btn text small color="primary" @click="downloadFile(n)">{{contractItem.attachment_image[i].substring(contractItem.attachment_image[i].lastIndexOf('/') + 1, contractItem.attachment_image[i].length)}}</v-btn>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn icon small @click="removeImg('customer', i)"><v-icon>delete</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item class="px-0">
              <v-list-item-content>
              </v-list-item-content>
              <v-list-item-action>
                  <v-btn depressed @click="clickUploadInput('customer', -1)" small>
                    <v-icon>upload</v-icon> 上传附件
                  </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-list subheader dense v-if="getType === 'agency'">
            <v-list-item class="px-0">
              <v-list-item-avatar rounded="0" size="80">
                <v-icon x-large>description</v-icon>
<!--                <v-icon v-if="!licenseUrl" x-large>image</v-icon>-->
<!--                <v-img v-else :src="licenseUrl?licenseUrl:''" width="80" height="80"></v-img>-->
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle class="caption">
                  <div>合同文件，支持图片、PDF、Word格式及压缩包</div>
                  <div v-if="contractItem.file_name" class="text-truncate">当前文件：<span class="primary--text">{{ contractItem.file_name }}</span></div>
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-row>
                  <v-col>
                    <v-btn v-if="contractItem.file_name" icon small @click="removeImg('license_img')" :disabled="(!isEdit && checkOnly) || contractItem.isForce">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col>
                    <v-btn icon @click="clickUploadInput('license_img')" small :disabled="(!isEdit && checkOnly) || contractItem.isForce">
                      <v-icon>upload</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-list-item-action>
            </v-list-item>
            <v-row>
              <v-col cols="12">
                <v-subheader>项目确认单</v-subheader>
                <v-row align="center" justify="center">
                  <v-card v-for="(n, i) in this.preUrl" :key="i" class="ma-2" outlined tile>
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <v-img v-if="n.substring(n.lastIndexOf('.') + 1, n.indexOf('?')).toLocaleLowerCase() === 'jpg' || n.substring(n.lastIndexOf('.') + 1, n.indexOf('?')).toLocaleLowerCase() === 'png'" :src="n" class="ma-2" :max-width="125">
                          <v-fade-transition>
                            <v-overlay v-if="hover" absolute class="align-center" color="white lighten-5">
                              <v-btn icon color="primary" @click="downloadFile(n)"><v-icon>get_app</v-icon></v-btn>
                              <v-btn icon color="primary" @click="clickUploadInput('confirm_img', i)"><v-icon>backup</v-icon></v-btn>
                              <v-btn icon color="primary" @click="removeImg('confirm_img', i)"><v-icon>delete</v-icon></v-btn>
                            </v-overlay>
                          </v-fade-transition>
                        </v-img>
                        <v-sheet v-else color="white" height="50" width="200" class="d-flex align-center px-2">
                          {{contractItem.confirm_image[i].substring(contractItem.confirm_image[i].lastIndexOf('/') + 1, contractItem.confirm_image[i].length)}}
                          <v-fade-transition>
                            <v-overlay v-if="hover" absolute class="align-center" color="white lighten-5">
                              <v-btn icon color="primary" @click="downloadFile(n)"><v-icon>get_app</v-icon></v-btn>
                              <v-btn icon color="primary" @click="clickUploadInput('confirm_img', i)"><v-icon>backup</v-icon></v-btn>
                              <v-btn icon color="primary" @click="removeImg('confirm_img', i)"><v-icon>delete</v-icon></v-btn>
                            </v-overlay>
                          </v-fade-transition>
                        </v-sheet>
                      </template>
                    </v-hover>
                  </v-card>
                  <v-card class="ma-3 pa-6" outlined tile hover @click="clickUploadInput('confirm_img', -1)"><v-icon>add</v-icon></v-card>
                </v-row>
              </v-col>
            </v-row>
          </v-list>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="closeEdit">取消</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" v-if="isEdit" @click="saveSubmit" :disabled="!valid">保存</v-btn>
      </v-card-actions>
    </v-card>
    <input id="files" type="file" name="file" ref="uploadInput" accept=".jpg, .jpeg, .png, .gif, application/msword, application/pdf, .zip, .rar, application/x-rar-compressed, application/zip, application/x-zip-compressed, application/octet-stream" :multiple="false"
           @change="detectFiles($event)"/>
    <v-dialog v-model="Upload.uploading" persistent max-width="550px">
      <v-card color="secondary" dark>
        <v-card-title>文件上传中...</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-progress-linear
                color="white"
                :value="Upload.progressUpload"
              >
              </v-progress-linear>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import Util from "@/common/util";
import axios from "axios";
import {CREATE_CONTRACT, EDIT_CONTRACT, FETCH_UPLOAD, FETCH_URL} from "@/store/actions.type";
import {RELOAD_CONTRACT_LIST, SET_ERROR} from "@/store/mutations.type";
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      valid: true,
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        negative: (val) => val > 0 || "不能小于0",
        counter: value => value.length <= 80 || '最多输入80个字。',
      },
      dialog: false,
      sign_date_menu: false,
      contract_start_menu: false,
      contract_end_menu: false,
      contractItem: {
        contract_type: '',
        contract_name: '',
        file_no: '',
        electric_use: '',
        sign_date: '',
        contract_start: '',
        contract_end: '',
        price: '',
        sign_type: null,
        sign_other: '',
        xt_ratio: '',
        customer_ratio:'',
        fixed_price: '',
        fixed_fee: '',
        href: '',
        file_name: '',
        confirm_image: [],
        attachment_image:[],
        remark: ''
      },
      defaultItem: {
        business_type: '',
        contract_name: '',
        file_no: '',
        electric_use: '',
        sign_date: '',
        contract_start: '',
        contract_end: '',
        price: '',
        sign_type: null,
        sign_other: '',
        xt_ratio: '',
        customer_ratio:'',
        fixed_price: '',
        fixed_fee: '',
        href: '',
        file_name: '',
        confirm_image: [],
        attachment_image:[],
        remark: ''
      },
      Upload: {
        uploading: false,
        progressUpload: 0,
      },
      fileName: '请上传文件',
      customerUrl: '',
      preUrl: [],
      preAttUrl: [],
      uploadType: '',
      uploadIndex: -1,
      licenseUrl: '',
    }
  },
  props: {
    getType: {
      type: String,
      require: true,
      default: '',
    },
    getId: {
      type: String,
      require: true,
      default: '',
    },
    editData: {
      type: Object,
      default: null,
    },
    reSign: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    checkOnly: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  created() {

  },
  computed: {
    ...mapGetters(["uploadSign", "userBusinessType"]),
    signType () {
      return Util.categories('signType')
    },
  },
  mounted() {

  },
  methods: {
    async checkData() {
      if(this.editData) {
        this.contractItem = JSON.parse(JSON.stringify(this.editData))
        this.contractItem.sign_date = this.$options.filters['formatDate'](this.contractItem.sign_date)
        this.contractItem.contract_start = this.$options.filters['formatDate'](this.contractItem.contract_start)
        this.contractItem.contract_end = this.$options.filters['formatDate'](this.contractItem.contract_end)
        if (this.getType === 'customer') {
          if (this.contractItem.href) {
            this.$store.dispatch(FETCH_URL, this.contractItem.href).then((url) => {
              this.customerUrl = url
            })
          }
          if (Array.isArray(this.contractItem.attachment_image)) {
            for (const key of this.contractItem.attachment_image) {
              await this.$store.dispatch(FETCH_URL, key).then((url) => {
                this.preAttUrl.push(url)
              })
            }
          }
          this.contractItem.customer = this.contractItem.customer.id
        } else if (this.getType === 'agency') {
          if (!this.reSign) {
            if (Array.isArray(this.contractItem.confirm_image)) {
              for (const key of this.contractItem.confirm_image) {
                await this.$store.dispatch(FETCH_URL, key).then((url) => {
                  this.preUrl.push(url)
                })
              }
            }
            if (this.contractItem.href) {
              this.$store.dispatch(FETCH_URL, this.contractItem.href).then((url) => {
                this.licenseUrl = url
              })
            }
          } else {
            this.contractItem.sign_date = ''
            this.contractItem.contract_start = ''
            this.contractItem.contract_end = ''
            this.contractItem.confirm_image = []
            this.contractItem.attachment_image = []
            this.contractItem.href = ''
          }
          this.contractItem.agency = this.contractItem.agency.id
        }
      }
    },
    closeEdit() {
      this.contractItem = JSON.parse(JSON.stringify(this.defaultItem))
      this.contractItem.confirm_image = []
      this.contractItem.attachment_image = []
      this.preUrl = []
      this.preAttUrl = []
      this.dialog = false
    },
    detectFiles(e) {
      let fileList = e.target.files || e.dataTransfer.files;
      Array.from(Array(fileList.length).keys()).map(x => {
        this.upload(fileList[x]);
      })
    },
    clickUploadInput(type, index) {
      this.uploadType = type
      this.uploadIndex = index
      this.$store.dispatch(FETCH_UPLOAD, 'private')
        .then(this.$refs.uploadInput.click())
        .catch(err => {
          this.$store.commit(SET_ERROR, {msg: err});
        });
    },
    async upload(file) {
      this.fileName = file.name.replace(/[%#&]/g,"");
      this.Upload.uploading = true;
      let param = new FormData();
      let menu = ''
      if (this.uploadType === 'customer') {
        menu = 'contract/customer/agreement/'
      } else if (this.uploadType === 'confirm_img') {
        menu = 'contract/agency/confirm/'
      } else if (this.uploadType === 'license_img') {
        menu = 'contract/agency/agreement/'
      }
      param.append('name', file.name);
      param.append('key',  menu + this.uploadSign.key + '/' + this.fileName);
      param.append('policy', this.uploadSign.policy);
      param.append('OSSAccessKeyId', this.uploadSign.OSSAccessKeyId);
      param.append('success_action_status', 200);
      param.append('signature', this.uploadSign.signature);
      param.append('file', file, file.name);

      let config = {
        headers: {'Content-Type': 'multipart/form-data'},
        onUploadProgress: progressEvent => {
          this.Upload.progressUpload = Math.floor(progressEvent.loaded / progressEvent.total * 100)
        }
      };
      await axios.post(this.uploadSign.host, param, config)
        .then(() => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.$store.dispatch(FETCH_URL, param.get('key')).then((fileUrl) => {
            if (this.uploadType === 'customer') {
              if (this.uploadIndex > -1) {
                this.preAttUrl.splice(this.uploadIndex, 1, fileUrl)
                this.contractItem.attachment_image.splice(this.uploadIndex, 1, param.get('key'));
              } else {
                this.preAttUrl.push(fileUrl);
                this.contractItem.attachment_image.push(param.get('key'));
              }
            } else if (this.uploadType === 'confirm_img') {
              if (this.uploadIndex > -1) {
                this.preUrl.splice(this.uploadIndex, 1, fileUrl)
                this.contractItem.confirm_image.splice(this.uploadIndex, 1, param.get('key'));
              } else {
                this.preUrl.push(fileUrl);
                this.contractItem.confirm_image.push(param.get('key'));
              }
            } else if (this.uploadType === 'license_img') {
              this.licenseUrl = fileUrl;
              this.contractItem['href'] = param.get('key')
              this.contractItem.file_name = param.get('name')
            }
            this.$forceUpdate();
          });
        })
        .catch((error) => {
          this.Upload.uploading = false;
          this.Upload.progressUpload = 0;
          this.$refs.uploadInput.value = '';
          this.$store.commit(SET_ERROR, {msg: error.message});
        });
    },
    removeImg(type, index) {
      if (type === 'customer') {
        this.customerUrl = ''
        this.contractItem.href = ''
        this.contractItem.file_name = ''
        this.preAttUrl.splice(index, 1);
        this.contractItem.attachment_image.splice(index, 1);
      } else if (type === 'confirm_img') {
        this.preUrl.splice(index, 1);
        this.contractItem.confirm_image.splice(index, 1);
      } else if (type === 'license_img') {
        this.licenseUrl = ''
        this.contractItem.href = ''
        this.contractItem.file_name = ''
      }
      this.$forceUpdate()
    },
    resetData() {
      this.contractItem.customer_ratio = Number(this.contractItem.customer_ratio)
      this.contractItem.xt_ratio = Number(this.contractItem.xt_ratio)
      this.contractItem.fixed_price = Number(this.contractItem.fixed_price)
      this.contractItem.fixed_fee = Number(this.contractItem.fixed_fee)
      this.contractItem.electric_use = Number(this.contractItem.electric_use)
      this.contractItem.price = Number(this.contractItem.price)
      return this.contractItem
    },
    saveSubmit() {
      let json = {}
      json.item = this.resetData()
      if (json.item._id) {
        if (!this.reSign) {
          json.id = json.item._id
          this.$store.dispatch(EDIT_CONTRACT, json).then(() => {
            this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
            this.$store.commit(RELOAD_CONTRACT_LIST)
            this.closeEdit()
          }).catch(err => {
            this.$store.commit(SET_ERROR, {msg: err.data});
          })
        } else {
          delete json.item._id
          this.$store.dispatch(CREATE_CONTRACT, json.item).then(() => {
            this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
            this.$store.commit(RELOAD_CONTRACT_LIST)
            this.closeEdit()
          }).catch(err => {
            this.$store.commit(SET_ERROR, {msg: err.data});
          })
        }
      }else {
        if (this.getType === 'customer') {
          json.item.customer = this.getId
          json.item.contract_type = 1
        } else if (this.getType === 'agency') {
          json.item.agency = this.getId
          json.item.contract_type = 2
        }
        this.$store.dispatch(CREATE_CONTRACT, json.item).then(() => {
          this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
          this.$store.commit(RELOAD_CONTRACT_LIST)
          this.closeEdit()
        }).catch(err => {
          this.$store.commit(SET_ERROR, {msg: err.data});
        })
      }
    },
    downloadFile(fileLink) {
      // this.$store.dispatch(FETCH_URL, fileLink)
      //   .then((fileurl) => {
      window.location.href = fileLink;
      // })
      // .catch((err) => {
      //   store.commit(SET_ERROR, { msg: err });
      // });
    },
  },
  directives: {}
}
</script>

<style>
.show_upload {
  align-items: center;
  bottom: 0;
  justify-content: center;
  opacity: .5;
  position: absolute;
  width: 100%;
}
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>

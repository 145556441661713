var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-app-bar', {
    attrs: {
      "app": "",
      "clipped-left": "",
      "color": "primary",
      "dark": ""
    }
  }, [_c('v-img', {
    staticClass: "shrink mr-2",
    attrs: {
      "alt": "Logo",
      "contain": "",
      "src": "/static/logo.png",
      "transition": "scale-transition",
      "max-width": "35"
    }
  }), _c('v-toolbar-title', [_vm._v("鑫泰能源信息化系统")]), _c('core-version', {
    staticClass: "ml-2 mt-2",
    attrs: {
      "id": _vm.verisonInfo._id
    }
  }, [_vm._v("v" + _vm._s(_vm.verisonInfo.version))]), _c('v-app-bar-nav-icon', {
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        return _vm.drawerToggle.apply(null, arguments);
      }
    }
  }), _c('v-spacer'), _c('v-menu', {
    attrs: {
      "offset-y": "",
      "bottom": "",
      "left": "",
      "close-on-content-click": false
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "dark": "",
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("account_circle")])], 1)];
      }
    }])
  }, [_c('v-card', [_vm.currentUser ? _c('v-list', [_c('v-list-item', [_c('v-list-item-avatar', [_c('v-img', {
    attrs: {
      "src": _vm.currentUser.personal && _vm.currentUser.personal.profile ? _vm.ossURL + '/' + _vm.currentUser.personal.profile : '/static/grey_silhouette.png',
      "aspect-ratio": "1",
      "contain": ""
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(_vm.currentUser.personal && _vm.currentUser.personal.name || _vm.currentUser.username))]), _c('v-list-item-subtitle', [_vm._v(_vm._s(_vm.currentUser.employer && _vm.currentUser.employer.ownerId.name || '无企业信息') + "：" + _vm._s(_vm.currentUser.employer && _vm.currentUser.employer.roles.map(function (item) {
    return item.name;
  })))])], 1)], 1)], 1) : _vm._e(), _c('v-divider'), _vm.currentUser.notice ? _c('v-list', [_c('v-list-item', [_c('v-list-item-action', [_c('v-switch', {
    attrs: {
      "color": "secondary"
    },
    on: {
      "change": function change($event) {
        return _vm.editNotice();
      }
    },
    model: {
      value: _vm.currentUser.notice.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.currentUser.notice, "phone", $$v);
      },
      expression: "currentUser.notice.phone"
    }
  })], 1), _c('v-list-item-title', [_vm._v("短信通知")])], 1)], 1) : _vm._e(), _c('v-card-actions', [_c('v-spacer'), _c('account-dialogs', {
    attrs: {
      "account": _vm.currentUser._id
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    }
  }, [_vm._v("个人资料")])], 1), _c('v-btn', {
    attrs: {
      "color": "secondary",
      "text": ""
    },
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("退出登陆")])], 1)], 1)], 1), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.changeTheme(!_vm.darkTheme);
      }
    }
  }, [_c('v-icon', [_vm._v("invert_colors")])], 1), !_vm.$vuetify.breakpoint.mobile ? _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.fullScreen();
      }
    }
  }, [_c('v-icon', [_vm._v("fullscreen")])], 1) : _vm._e(), _vm.showInstall ? _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.installPWA();
      }
    }
  }, [_c('v-icon', [_vm._v("install_desktop")])], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
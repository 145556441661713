var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-footer', {
    staticClass: "subheading font-weight-light",
    staticStyle: {
      "z-index": "4"
    },
    attrs: {
      "app": ""
    }
  }, [_c('v-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }), _c('v-col', {
    staticClass: "d-flex justify-center",
    attrs: {
      "cols": "4"
    }
  }, [_c('a', {
    attrs: {
      "href": "https://beian.miit.gov.cn/",
      "target": "_blank"
    }
  }, [_vm._v("京ICP备17032708号-2")])]), _c('v-col', {
    staticClass: "d-flex justify-end",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(" 技术支持" + _vm._s(new Date().getFullYear() === 2023 ? new Date().getFullYear() : '2023 - ' + new Date().getFullYear()) + "： 非凡量子（北京）科技有限公司 ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div>
    <v-btn block :color="color" rounded @click="openDialog"><v-icon left>bug_report</v-icon><slot></slot></v-btn>
    <v-dialog v-model="dialog" persistent scrollable width="750">
      <v-card>
        <v-card-title>
          提交BUG或者建议
          <v-spacer></v-spacer>
          <v-btn icon @click="closeDialog"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form v-model="valid" @submit.prevent="submit">
              <v-row>
                <v-col cols="2" class="text-right">提交人</v-col>
                <v-col cols="4" class="text--secondary">{{editBodyData.who.personal ? editBodyData.who.personal.name : editBodyData.who.username}}</v-col>
                <v-col cols="2" class="text-right">功能模块</v-col>
                <v-col cols="4" class="text--secondary">{{editBodyData.where.meta ? editBodyData.where.meta.title : 'ESP'}}</v-col>
                <v-col cols="2" class="text-right">发生时间</v-col>
                <v-col cols="10" class="text--secondary">{{editBodyData.when | formatTime}}</v-col>
                <v-col cols="2" class="text-right">问题描述</v-col>
                <v-col cols="10">
                  <v-textarea
                    v-model="editBodyData.what"
                    :rules="[rules.required]"
                    label="请输入问题现象或建议"
                    auto-grow
                    outlined
                    rows="3"
                  ></v-textarea>
                </v-col>
                <v-col cols="2" class="text-right">期望结果</v-col>
                <v-col cols="10">
                  <v-textarea
                    v-model="editBodyData.want"
                    :rules="[rules.required]"
                    label="请输入期望的结果或效果"
                    auto-grow
                    outlined
                    rows="3"
                  ></v-textarea>
                </v-col>
                <v-col cols="2" class="text-right">页面截图</v-col>
                <v-col cols="10">
                  <v-img
                    max-width="250"
                    contain
                    :src="editBodyData.preview ? editBodyData.preview : '/static/error/empty_state.svg'"
                  ></v-img>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog()">取消</v-btn>
          <v-btn text color="secondary" @click="submit" :disabled="!valid">提交</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import store from "@/store";
import {mapGetters} from "vuex"
import html2canvas from 'html2canvas'
import { SET_ERROR } from "@/store/mutations.type";
import {
  PUBLISH_BUG
} from "@/store/actions.type";

export default {
  props: {
    color: {
      type: String,
      default: "secondary"
    },
  },
  data() {
    return {
      dialog: false,
      rules: {
        required: value => (value === 0 || !!value) || '请输入内容.',
        selected: value => {
          if (!value || value.length < 1) {
            return '请至少选择一个选项。'
          } else {
            return true;
          }
        },
        name: v => /^[A-Za-z][A-Za-z0-9@._]*$/.test(v) || '用户名第一位必须为字母，其余字母加数组组合',
        max: val => (val || '').length <=20 || '请输入4-20个字的用户名称',
        min: val => (val || '').length >=4 || '请输入4-20个字的用户名称',
      },
      valid: true,
      editBodyData: {
        who: {},
        when: new Date(),
        where: {},
        what: '',
        want: '',
        preview: '',
        ip: '127.0.0.1',
        agent: '',
      },
      defaultData: {
        who: {},
        when: new Date(),
        where: {},
        what: '',
        want: '',
        preview: '',
        ip: '',
        agent: '',
      }
    }
  },
  mounted() {
    this.getUserIP((ip) => {
      this.editBodyData.ip = ip;
    });
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {
    dialog (val) {
      val || this.closeDialog()
    },
  },
  methods: {
    openDialog() {
      this.dialog = true
      html2canvas(
        document.getElementById('app'),
        {
          allowTaint: true,
          useCORS: true,
          scale: 1,
        }
      ).then(canvas => {
        this.editBodyData.preview = canvas.toDataURL('image/jpeg')
        this.editBodyData.who = this.currentUser
        this.editBodyData.where = this.$route
      })

    },
    closeDialog() {
      this.dialog = false
      this.$nextTick(() => {
        this.editBodyData = Object.assign({}, this.defaultData)
      })
    },
    submit() {
      let strRequest = {
        preview: this.editBodyData.preview,
        who: this.editBodyData.who._id,
        when: this.editBodyData.when,
        what: this.editBodyData.what,
        want: this.editBodyData.want,
        where: {
          fullPath: this.editBodyData.where.fullPath,
          title: this.editBodyData.where.meta.title || {},
          name: this.editBodyData.where.name || '',
          path: this.editBodyData.where.path || '',
          params: this.editBodyData.params || {},
          query: this.editBodyData.query || {},
        },
        agent: navigator.userAgent,
        requestIp: this.editBodyData.ip
      }

      store.dispatch(PUBLISH_BUG, strRequest)
        .then(() => {
          store.commit(SET_ERROR, {msg: '感谢您对系统的反馈，技术团队将尽快进行处理。', color: 'primary'})
          this.closeDialog()
        })
        .catch((error) => {
          store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    getUserIP(onNewIP) {
      let MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection;
      let pc = new MyPeerConnection({
        iceServers: []
      });
      let noop = () => {
      };
      let localIPs = {};
      let ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g;
      let iterateIP = (ip) => {
        if (!localIPs[ip]) onNewIP(ip);
        localIPs[ip] = true;
      };
      pc.createDataChannel('');
      pc.createOffer().then((sdp) => {
        sdp.sdp.split('\n').forEach(function (line) {
          if (line.indexOf('candidate') < 0) return;
          line.match(ipRegex).forEach(iterateIP);
        });
        pc.setLocalDescription(sdp, noop, noop);
      }).catch(() => {
      });
      pc.onicecandidate = (ice) => {
        if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return;
        ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
      };
    }
  }
}
</script>

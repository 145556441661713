import {SettleService} from "@/common/http";

import {
  CREATE_SETTLE,
  FETCH_SETTLE_LIST,
  FETCH_SETTLE,
  EDIT_SETTLE,
  REMOVE_SETTLE,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_SETTLE_LIST,
  SET_SETTLE_LIST,
  SET_SETTLE,
  SET_SELECTED_SETTLE,
} from "./mutations.type";

const state = {
  settleLoading: false,
  settleList: [],
  settleCount: 0,
  settleInfo: {},
  settleSelected: {},
};

const getters = {
  settleLoading: state => state.settleLoading,
  settleList: state => state.settleList,
  settleCount: state => state.settleCount,
  settleInfo: state => state.settleInfo,
  settleSelected: state => state.settleSelected,
};

const actions = {
  [CREATE_SETTLE](context, params) {
    return new Promise((resolve, reject) => {
      SettleService.create('', params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response);
        })
    });
  },
  [FETCH_SETTLE]({ commit }, params) {
    commit(LOAD_SETTLE_LIST);
    return new Promise((resolve, reject) => {
      SettleService.get('', params.query)
        .then(({ data }) => {
          commit(SET_SETTLE, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [EDIT_SETTLE](context, params) {
    return SettleService.update('/' +  params.id + '?business_type=' + params.business_type, params.item);
  },
  [REMOVE_SETTLE](context, data) {
    return SettleService.delete('/' + data);
  },
  [FETCH_SETTLE_LIST]({ commit }, params) {
    commit(LOAD_SETTLE_LIST);
    return new Promise((resolve, reject) => {
      SettleService.get('/list', '?'+params.query)
        .then(({ data }) => {
          commit(SET_SETTLE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
}

const mutations = {
  [LOAD_SETTLE_LIST] (state) {
    state.settleLoading = true;
  },
  [SET_SETTLE_LIST](state, list) {
    state.settleCount = list.query.count;
    list.docs.forEach(obj => {
      if (obj.settle_status !== 1 || !obj.area) obj.isSelectable = false
    })
    state.settleList = list.docs;
    state.settleLoading = false;
  },
  [SET_SETTLE](state, settle) {
    state.settleInfo = settle.settleInfo;
    state.settleLoading = false;
  },
  [SET_SELECTED_SETTLE](state, item) {
    state.settleSelected = item;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "width": "550"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({
          on: {
            "click": _vm.checkData
          }
        }, 'span', attrs, false), on), [_vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2)];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "width": "600"
    }
  }, [_c('v-card-title', [_c('span', [_vm._v("编辑结算数据")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-6"
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.saveSubmit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "代理商服务费（含税）",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number",
      "suffix": "元",
      "prepend-icon": "currency_yen"
    },
    model: {
      value: _vm.settleItem.agency_service_fee,
      callback: function callback($$v) {
        _vm.$set(_vm.settleItem, "agency_service_fee", $$v);
      },
      expression: "settleItem.agency_service_fee"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "税金及附加",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "type": "number",
      "suffix": "元",
      "prepend-icon": "add_card"
    },
    model: {
      value: _vm.settleItem.tax,
      callback: function callback($$v) {
        _vm.$set(_vm.settleItem, "tax", $$v);
      },
      expression: "settleItem.tax"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.saveSubmit
    }
  }, [_vm._v("保存")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
export default [
  {
    path: '',
    name: 'Enterprise_Line',
    meta: {
      divider: true,
      title: '结算管理',
      icon: '',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/customer',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/customer/list'
    ),
    name: 'Customer',
    meta: {
      title: '客户管理',
      icon: 'location_city',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/customer/edit',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/customer/edit'
    ),
    name: 'Customer_Edit',
    meta: {
      title: '编辑客户',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/customer/detail/:id',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/customer/detail'
    ),
    name: 'Customer_Detail',
    meta: {
      title: '客户详情',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/customer/contract/edit',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/customer/contract/edit'
    ),
    name: 'Customer_Contract_Edit',
    meta: {
      title: '编辑客户合同',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/agency',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/agency/list'
    ),
    name: 'Agent_Line',
    meta: {
      title: '代理商管理',
      icon: 'real_estate_agent',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/agency/edit',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/agency/edit'
    ),
    hidden: true,
    name: 'Agent_Edit',
    meta: {
      title: '代理商编辑',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/agency/detail/:id',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/agency/detail'
    ),
    hidden: true,
    name: 'Agent_Detail',
    meta: {
      title: '代理商详情',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/contract',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/contract/list'
    ),
    name: 'Contract_Edit',
    meta: {
      title: '其他合同管理',
      icon: 'gavel',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/settle',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/settle/list'
    ),
    name: 'Settle',
    meta: {
      title: '结算管理',
      icon: 'paid',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/settle/case',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/settle/case'
    ),
    hidden: true,
    name: 'Settle_Line',
    meta: {
      title: '创建工单',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/workbench/case',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/case/list'
    ),
    hidden: true,
    name: 'Case',
    meta: {
      title: '工单列表',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/workbench/case/detail/:id',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/case/detail'
    ),
    hidden: true,
    name: 'Case_Edit',
    meta: {
      title: '工单详情',
      roles: ['distributor']
    }
  },
  {
    path: '',
    name: 'Report_Line',
    meta: {
      divider: true,
      title: '数据管理',
      icon: '',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/report',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/report/index'
    ),
    name: 'Report',
    meta: {
      title: '数据统计',
      icon: 'analytics',
      roles: ['distributor']
    },
  },
  {
    path: '/distributor/report/company',
    name: 'Report_Company',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/report/company'
    ),
    meta: {
      title: '数据统计-公司',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/report/agency',
    name: 'Report_Agency',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/report/agency'
    ),
    meta: {
      title: '数据统计-代理商明细',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/report/agencySummary',
    name: 'Report_Agency_Summary',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/report/agency_summary'
    ),
    meta: {
      title: '数据统计-代理商汇总',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/report/area',
    name: 'Report_Area',
    hidden: true,
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/report/area'
    ),
    meta: {
      title: '数据统计-区域汇总',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/filing',
    component: () => import('@/views/distributor/filing/index'),
    name: 'Filing',
    meta: {
      title: '储备客户',
      icon: 'inventory',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/filing/edit/:id',
    hidden: true,
    component: () => import(`@/views/distributor/filing/edit`),
    name: 'Filing_Edit',
    meta: {
      title: '编辑储备客户',
      roles: ['distributor']
    }
  },
  {
    path: '',
    name: 'System_Line',
    meta: {
      divider: true,
      title: '系统配置',
      icon: '',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/system',
    component: () => import('@/views/distributor/system/index'),
    name: 'System',
    meta: {
      title: '系统设置',
      icon: 'settings',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/system/organize',
    name: 'System_Organize',
    hidden: true,
    component: () => import('@/views/distributor/system/organize'),
    meta: {
      title: '组织权限',
      roles: ['distributor']
    },
    props: true,
    children: [
      {
        path: 'department',
        name: 'System_Organize_Dept_Edit',
        hidden: true,
        component: () => import('@/views/distributor/system/organize/department'),
        meta: {
          title: '部门管理',
          roles: ['distributor']
        }
      },
      {
        path: 'roles',
        name: 'System_Organize_Roles_Edit',
        hidden: true,
        component: () => import('@/views/distributor/system/organize/roles'),
        meta: {
          title: '角色管理',
          roles: ['distributor']
        }
      },
    ]
  },
  {
    path: '/distributor/system/area',
    name: 'System_Area',
    hidden: true,
    component: () => import('@/views/distributor/system/area'),
    meta: {
      title: '区域设置',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/system/task',
    name: 'System_Task',
    hidden: true,
    component: () => import('@/views/distributor/system/task'),
    meta: {
      title: '流程设置',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/system/release',
    name: 'System_Release',
    hidden: true,
    component: () => import('@/views/distributor/system/release'),
    meta: {
      title: '发行说明',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/system/bugs',
    name: 'System_Bugs',
    hidden: true,
    component: () => import('@/views/distributor/system/bugs'),
    meta: {
      title: '问题反馈',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/system/logs',
    name: 'System_Logs',
    hidden: true,
    component: () => import('@/views/distributor/system/logs'),
    meta: {
      title: '日志分析',
      roles: ['distributor']
    }
  },
  {
    path: '/distributor/system/test',
    name: 'System_Test',
    hidden: true,
    component: () => import('@/views/distributor/system/test'),
    meta: {
      title: '开发测试',
      roles: ['distributor']
    }
  },
]

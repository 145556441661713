var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.notesList ? _c('v-card-text', [_c('v-list', {
    attrs: {
      "subheader": "",
      "dense": ""
    }
  }, [_vm._l(_vm.notesList.filter(function (file) {
    return file.status === 0;
  }), function (item, i) {
    return [_c('v-list-item', {
      key: 'files_' + i
    }, [item.suffix === 'jpeg' || item.suffix === 'jpg' || item.suffix === 'png' || item.suffix === 'gif' ? _c('v-list-item-avatar', [_c('v-img', {
      attrs: {
        "src": item.preUrl && item.preUrl
      },
      on: {
        "click": function click($event) {
          return _vm.zoomFile(item);
        }
      }
    })], 1) : _c('v-list-item-avatar', [_c('v-icon', [_vm._v("text_snippet")])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(_vm._s(item.author._id === _vm.currentUser._id ? "我" : item.author.personal.name) + " 于 " + _vm._s(_vm._f("formatTime")(item.createTime)) + " 上传")]), _c('v-list-item-subtitle', [_vm._v(_vm._s(item.fileName))])], 1), _c('v-list-item-action', {
      staticClass: "d-inline"
    }, [item.author._id === _vm.currentUser._id && item.status === 0 && item._id ? _c('v-btn', {
      attrs: {
        "icon": ""
      },
      on: {
        "click": function click($event) {
          return _vm.deleteFile(item._id);
        }
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "grey lighten-1"
      }
    }, [_vm._v("delete")])], 1) : _vm._e(), item.preUrl ? _c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "grey lighten-1"
      },
      on: {
        "click": function click($event) {
          return _vm.zoomFile(item);
        }
      }
    }, [_vm._v("zoom_in")])], 1) : _vm._e(), _c('v-btn', {
      attrs: {
        "icon": ""
      }
    }, [_c('v-icon', {
      attrs: {
        "color": "grey lighten-1"
      },
      on: {
        "click": function click($event) {
          return _vm.downloadFile(item.fileLink);
        }
      }
    }, [_vm._v("get_app")])], 1)], 1)], 1), _c('v-divider', {
      key: 'files_divider_' + i,
      attrs: {
        "inset": ""
      }
    })];
  }), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', {
    attrs: {
      "align": "end"
    }
  }, [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.createAttach();
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("attach_file")]), _vm._v("上传附件")], 1)], 1)], 1)], 1)], 2), _c('v-dialog', {
    attrs: {
      "fullscreen": "",
      "hide-overlay": "",
      "scrollable": "",
      "transition": "dialog-bottom-transition"
    },
    model: {
      value: _vm.editDialog,
      callback: function callback($$v) {
        _vm.editDialog = $$v;
      },
      expression: "editDialog"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-toolbar-title', [_vm._v(_vm._s(_vm.activeFile.fileName))]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.downloadFile(_vm.activeFile.fileLink);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "grey lighten-1"
    }
  }, [_vm._v("get_app")])], 1), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEditDialog();
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-img', {
    attrs: {
      "src": _vm.activeFile.preUrl,
      "aspect-ratio": "1",
      "contain": ""
    }
  })], 1), _vm.activeFile.author ? _c('v-card-actions', [_c('v-subheader', [_vm._v(_vm._s(_vm.activeFile.author._id === _vm.currentUser._id ? "我" : _vm.activeFile.author.personal.name) + " 于 " + _vm._s(_vm._f("formatTime")(_vm.activeFile.createTime)) + " 上传")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.downloadFile(_vm.activeFile.fileLink);
      }
    }
  }, [_vm._v("下载")]), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeEditDialog();
      }
    }
  }, [_vm._v("关闭")])], 1) : _vm._e()], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "max-width": "550px"
    },
    model: {
      value: _vm.dlgAttach,
      callback: function callback($$v) {
        _vm.dlgAttach = $$v;
      },
      expression: "dlgAttach"
    }
  }, [_vm.editedAttach.progressUpload && _vm.editedAttach.progressUpload.length ? _c('v-card', {
    attrs: {
      "color": "secondary",
      "dark": ""
    }
  }, [_c('v-card-title', [_vm._v("文件上传中...")]), _c('v-card-text', [_c('v-row', _vm._l(_vm.editedAttach.progressUpload, function (progress, i) {
    return _c('v-col', {
      key: 'progress_' + i,
      attrs: {
        "cols": "12"
      }
    }, [_vm._v(" " + _vm._s(_vm.editedAttach.files[i].name) + " "), _c('v-progress-linear', {
      attrs: {
        "color": "white",
        "value": progress
      }
    })], 1);
  }), 1)], 1)], 1) : _c('v-card', [_c('v-card-title', [_vm._v(" 上传附件 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeAttach();
      }
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-file-input', {
    attrs: {
      "label": "单击此处选择文件上传",
      "outlined": "",
      "dense": "",
      "small-chips": "",
      "multiple": "",
      "show-size": "",
      "counter": ""
    },
    model: {
      value: _vm.editedAttach.files,
      callback: function callback($$v) {
        _vm.$set(_vm.editedAttach, "files", $$v);
      },
      expression: "editedAttach.files"
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeAttach();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.editedAttach.files.length
    },
    on: {
      "click": function click($event) {
        return _vm.addAttach();
      }
    }
  }, [_vm._v("确定")])], 1)], 1)], 1)], 1) : _c('v-overlay', {
    attrs: {
      "value": _vm.notesLoading,
      "z-index": "9999"
    }
  }, [_c('v-progress-circular', {
    attrs: {
      "indeterminate": "",
      "size": "64"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
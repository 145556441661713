<template>
  <v-dialog v-model="dialog" scrollable persistent width="1000">
    <template v-slot:activator="{ on, attrs }">
    <span v-bind="attrs" v-on="on" @click="checkData">
      <!--<slot v-if="$slots.default"></slot>-->
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    </template>
    <v-card width="600" light>
      <v-card-title>
        <span>结算汇总单</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeEdit">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6">
        <div ref="report" class="pa-6">
          <div class="black--text caption mb-2"><span class="font-weight-bold">流程编号：</span>{{ settleData.case_number?.replace('SC', 'RA') }}</div>
          <case-receipt-summary-energy v-if="getType === 1" :settleData="settleData"></case-receipt-summary-energy>
          <case-receipt-summary-peak v-if="getType === 2" :settleData="settleData"></case-receipt-summary-peak>
          <case-receipt-summary-selling v-if="getType === 3" :settleData="settleData"></case-receipt-summary-selling>
<!--          <case-receipt-summary-zhike v-if="getType === 4"></case-receipt-summary-zhike>-->
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="closeEdit">取消</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="openEditDlg" v-if="settleData?.tasks_list?.find( i => i.isActive)?.order === 0 && settleData.group === 3 && settleData.status !== 2 && settleData.producedBy?._id === currentUser._id">编辑</v-btn>
        <v-btn text color="primary" @click="downloadReceipt" :disabled="downloading">下载</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="editDlg" persistent scrollable width="600">
      <v-card>
        <v-card-title>
          编辑汇总数据
          <v-spacer></v-spacer>
          <v-btn icon @click="closeEditDlg"><v-icon>close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="text-center pt-6">
          <v-form v-model="valid" @submit.prevent="saveSubmit">
            <v-text-field
              v-model="editItem.settle_elec_total"
              label="结算电量*"
              type="number"
              outlined
              :rules="[rules.required]"
              dense
              suffix="兆瓦时"
            ></v-text-field>
            <v-text-field
              v-model="editItem.agency_service_fee"
              label="代理商服务费(含税)*"
              type="number"
              outlined
              :rules="[rules.required]"
              dense
              suffix="元"
            ></v-text-field>
            <v-text-field
              v-model="editItem.agency_service_fee_no_tax"
              label="代理商服务费(不含税)*"
              type="number"
              outlined
              :rules="[rules.required]"
              dense
              suffix="元"
            ></v-text-field>
            <v-text-field
              v-model="editItem.tax"
              label="税金及附加*"
              type="number"
              outlined
              :rules="[rules.required]"
              dense
              suffix="元"
            ></v-text-field>
            <v-text-field
              v-model="editItem.agency_fee_no_tax"
              label="代理商费用(不含税)*"
              type="number"
              outlined
              :rules="[rules.required]"
              dense
              suffix="元"
            ></v-text-field>
            <v-text-field
              v-model="editItem.agency_fee"
              label="代理商费用(含税)*"
              type="number"
              outlined
              :rules="[rules.required]"
              dense
              suffix="元"
            ></v-text-field>
            <v-text-field
              v-model="editItem.agency_fee_total"
              label="总开票金额*"
              type="number"
              outlined
              :rules="[rules.required]"
              dense
              suffix="元"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn text @click="closeEditDlg">取消</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="editSummary" :disabled="!valid">保存</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import store from "@/store";
import {
  EDIT_CASE_SETTLE,
  DOWNLOAD_CASE_SUMMARY,
} from "@/store/actions.type";
import { SET_ERROR } from "@/store/mutations.type";
import PDF from "@/common/pdf";
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      dialog: false,
      valid: true,
      editDlg: false,
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        negative: (val) => val > 0 || "不能小于0",
      },
      editItem: {
        agency_fee: '',
        agency_fee_no_tax: '',
        agency_service_fee: '',
        agency_service_fee_no_tax: '',
        settle_elec_total: '',
        tax: '',
        agency_fee_total: '',
      },
      defaultItem: {
        agency_fee: '',
        agency_fee_no_tax: '',
        agency_service_fee: '',
        agency_service_fee_no_tax: '',
        settle_elec_total: '',
        tax: '',
        agency_fee_total: '',
      },
      downloading: false,
    }
  },
  props: {
    getType: {
      type: Number,
      require: true,
      default: null,
    },
    getId: {
      type: String,
      require: true,
      default: '',
    },
    settleData: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  created() {
  },
  computed: {
    ...mapGetters([
      "currentUser"
    ]),
  },
  mounted() {

  },
  methods: {
    checkData() {
      // Object.keys(this.settleData.settleSumData).map(item => {
      //   if (this.settleData.settleSumData[item].$numberDecimal) this.settleData.settleSumData[item] = Number(this.settleData.settleSumData[item].$numberDecimal).toFixed(2)
      // })
    },
    closeEdit() {
      this.dialog = false
    },
    editSummary() {
      this.editItem.agency_fee = Number(this.editItem.agency_fee)
      this.editItem.agency_fee_no_tax = Number(this.editItem.agency_fee_no_tax)
      this.editItem.agency_service_fee = Number(this.editItem.agency_service_fee)
      this.editItem.agency_service_fee_no_tax = Number(this.editItem.agency_service_fee_no_tax)
      this.editItem.settle_elec_total = Number(this.editItem.settle_elec_total)
      this.editItem.tax = Number(this.editItem.tax)
      this.editItem.agency_fee_total = Number(this.editItem.agency_fee_total)
      let json = {}
      json.id = this.settleData._id
      json.item = this.editItem
      store.dispatch(EDIT_CASE_SETTLE, json).then(() => {
        store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'})
        this.closeEditDlg()
        this.$emit('reloadCase')
        // this.checkData()
      }).catch((err) => {
        store.commit(SET_ERROR, {msg: err.response.data.message})
      })
    },
    openEditDlg() {
      this.editItem = JSON.parse(JSON.stringify(this.settleData.settleSumData))
      this.editDlg = true
    },
    closeEditDlg() {
      this.editDlg = false
      this.editItem = Object.assign({}, this.defaultItem)
    },
    saveSubmit() {
      console.log('save')
    },
    downloadReceipt() {
      this.downloading = true
      this.$nextTick(() => {
        this.$vuetify.goTo(0)
        setTimeout(() => {
          PDF.downloadPDF(this.$refs.report, this.settleData?.agency?.name, 'pic')
          this.$store.dispatch(DOWNLOAD_CASE_SUMMARY, this.getId).then(() => {
            this.downloading = false
          })
        }, 500)
      })
    },
  },
  directives: {}
}
</script>

<style scoped>
.rotate90 {
  transform: rotate(90deg);
}
</style>

import { DepartmentService } from "@/common/http";

import {
  CREATE_DEPT,
  FETCH_DEPT_LIST,
  REMOVE_DEPT,
  EDIT_DEPT,
} from "./actions.type";

import {
  SET_ERROR,
  GET_DEPTS,
  SET_DEPTS,
  SET_DEPT
} from "./mutations.type"

const state = {
  deptLoading: true,
  departments: [],
  department: {},
};

const getters = {
  departments: state => state.departments,
  department: state => state.department,
  deptLoading: state => state.deptLoading,
};

const actions = {
  [FETCH_DEPT_LIST]({ commit }, slug) {
    commit(GET_DEPTS);
    return new Promise((resolve, reject) => {
      DepartmentService.get('/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_DEPTS, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [CREATE_DEPT](context, credentials) {
    return new Promise((resolve, reject) => {
      DepartmentService.create('', credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
  [EDIT_DEPT](context, credentials) {
    return new Promise((resolve, reject) => {
      DepartmentService.update('', credentials._id, credentials)
        .then(({ data }) => {
          context.commit(SET_DEPT, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [REMOVE_DEPT](context, credentials) {
    return new Promise((resolve, reject) => {
      DepartmentService.destroy(credentials)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        })
    });
  },
};

const mutations = {
  [GET_DEPTS] (state) {
    state.deptLoading = true;
  },
  [SET_DEPTS](state, data) {
    state.departments = data;
    state.deptLoading = false;
  },
  [SET_DEPT](state, data) {
    state.department = data;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

<template>
  <v-dialog v-model="dialog" scrollable persistent width="550">
    <template v-slot:activator="{ on, attrs }">
    <span v-bind="attrs" v-on="on" @click="checkData">
      <!--<slot v-if="$slots.default"></slot>-->
      <slot></slot> <span v-if="divider">{{divider}}</span>
    </span>
    </template>
    <v-card width="600">
      <v-card-title>
        <span>合作客户</span>
        <v-spacer></v-spacer>
        <v-btn icon @click="closeEdit">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="py-6">
        <v-form v-model="valid" @submit.prevent="saveSubmit">
          <v-combobox
            ref="customerBox"
            v-model="contractItem.customer"
            :loading="customerLoading"
            :items="customerList"
            :search-input.sync="searchCustomer"
            item-text="name"
            item-value="_id"
            return-object
            autocomplete="off"
            outlined
            dense
            label="客户名称*"
            hint="请查找并选择客户"
            prepend-icon="emoji_people"
          ></v-combobox>
          <v-text-field
            v-model="contractItem.project_name"
            label="项目名称"
            outlined
            dense
            prepend-icon="gavel"
          ></v-text-field>
          <v-menu ref="sign_date" v-model="income_start_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="contractItem.income_start"
                label="收益开始时间"
                readonly
                outlined
                dense
                clearable
                v-bind="attrs"
                v-on="on"
                prepend-icon="event_available"
              ></v-text-field>
            </template>
            <v-date-picker v-model="contractItem.income_start" no-title scrollable @input="income_start_menu = false">
            </v-date-picker>
          </v-menu>
          <v-menu ref="sign_date" v-model="contract_start_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="contractItem.contract_start"
                label="项目确认单开始时间*"
                readonly
                outlined
                :rules="[rules.required]"
                dense
                clearable
                v-bind="attrs"
                v-on="on"
                prepend-icon="event_available"
              ></v-text-field>
            </template>
            <v-date-picker v-model="contractItem.contract_start" no-title scrollable @input="contract_start_menu = false">
            </v-date-picker>
          </v-menu>
          <v-menu ref="sign_date" v-model="contract_end_menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="contractItem.contract_end"
                label="项目确认单结束时间*"
                readonly
                outlined
                :rules="[rules.required]"
                dense
                clearable
                v-bind="attrs"
                v-on="on"
                prepend-icon="event_busy"
              ></v-text-field>
            </template>
            <v-date-picker v-model="contractItem.contract_end" no-title scrollable @input="contract_end_menu = false">
            </v-date-picker>
          </v-menu>
          <v-text-field
            v-model="contractItem.condition"
            label="客户合作条件*"
            outlined
            :rules="[rules.required]"
            dense
            prepend-icon="gavel"
          ></v-text-field>
          <v-text-field
            v-model="contractItem.allow_ratio"
            label="允许客户偏差免考范围*"
            outlined
            :rules="[rules.required]"
            dense
            suffix="%"
            prepend-icon="unfold_less"
          ></v-text-field>
          <v-select
            v-model="contractItem.sign_type"
            :items="signType"
            label="签约方式*"
            :rules="[rules.selected]"
            item-text="name"
            item-value="code"
            outlined
            dense
            prepend-icon="checklist"
          ></v-select>
          <v-text-field
            v-if="contractItem.sign_type === 5"
            v-model="contractItem.sign_other"
            label="签约方式说明"
            outlined
            dense
            prepend-icon="assignment"
          ></v-text-field>
          <v-text-field
            v-if="contractItem.sign_type === 1"
            v-model="contractItem.xt_ratio"
            label="鑫泰比例*"
            outlined
            :rules="[rules.required]"
            dense
            type="number"
            suffix="%"
            prepend-icon="group_add"
          ></v-text-field>
          <v-text-field
            v-if="contractItem.sign_type === 1"
            v-model="contractItem.agency_ratio"
            label="代理商比例*"
            outlined
            :rules="[rules.required]"
            dense
            type="number"
            suffix="%"
            prepend-icon="group_remove"
          ></v-text-field>
          <v-text-field
            v-if="contractItem.sign_type === 3"
            v-model="contractItem.sharp_price"
            label="固定单价（尖）*"
            outlined
            :rules="[rules.required, rules.negative]"
            dense
            type="number"
            suffix="元/兆瓦时"
            prepend-icon="currency_yen"
          ></v-text-field>
          <v-text-field
            v-if="contractItem.sign_type === 3"
            v-model="contractItem.peak_price"
            label="固定单价（峰）*"
            outlined
            :rules="[rules.required, rules.negative]"
            dense
            type="number"
            suffix="元/兆瓦时"
            prepend-icon="currency_yen"
          ></v-text-field>
          <v-text-field
            v-if="contractItem.sign_type === 3"
            v-model="contractItem.plain_price"
            label="固定单价（平）*"
            outlined
            :rules="[rules.required, rules.negative]"
            dense
            type="number"
            suffix="元/兆瓦时"
            prepend-icon="currency_yen"
          ></v-text-field>
          <v-text-field
            v-if="contractItem.sign_type === 3"
            v-model="contractItem.valley_price"
            label="固定单价（谷）*"
            outlined
            :rules="[rules.required, rules.negative]"
            dense
            type="number"
            suffix="元/兆瓦时"
            prepend-icon="currency_yen"
          ></v-text-field>
          <v-text-field
            v-if="contractItem.sign_type === 2"
            v-model="contractItem.fixed_fee"
            label="固定服务费金额*"
            outlined
            :rules="[rules.required, rules.negative]"
            dense
            type="number"
            suffix="元"
            prepend-icon="currency_yen"
          ></v-text-field>
          <v-textarea
            v-model="contractItem.remark"
            label="备注"
            autocomplete="off"
            clearable
            outlined
            dense
            no-resize
            prepend-icon="comment"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="closeEdit">取消</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="saveSubmit" :disabled="!valid">保存</v-btn>
      </v-card-actions>
    </v-card>
    <input id="files" type="file" name="file" ref="uploadInput" accept="image/*" :multiple="false"
           @change="detectFiles($event)"/>
  </v-dialog>
</template>
<script>
import Util from "@/common/util";
import {CREATE_CONTRACT, EDIT_CONTRACT, FETCH_CUSTOMER_LIST, CREATE_CUSTOMER_QUICK} from "@/store/actions.type";
import {RELOAD_CONTRACT_LIST, SET_ERROR} from "@/store/mutations.type";
import {mapGetters} from "vuex";
import store from "@/store";
export default {
  data() {
    return {
      valid: true,
      rules: {
        required: (value) => value === 0 || !!value || "请输入内容.",
        selected: (value) => {
          if (!value || value.length < 1) {
            return "请至少选择一个选项。";
          } else {
            return true;
          }
        },
        below100: (val) => val <= 100 || "不能大于100",
        negative: (val) => val >= 0 || "不能小于0",
        counter: value => value.length <= 80 || '最多输入80个字。',
      },
      dialog: false,
      contract_start_menu: false,
      contract_end_menu: false,
      income_start_menu: false,
      contractItem: {
        business_type: '',
        contract_type: '',
        project_name: '',
        customer: '',
        income_start: '',
        contract_start: '',
        contract_end: '',
        condition: '',
        allow_ratio: '',
        sign_type: null,
        sign_other: '',
        xt_ratio: '',
        agency_ratio:'',
        sharp_price: '',
        peak_price: '',
        plain_price: '',
        valley_price: '',
        fixed_fee: '',
        remark: '',
      },
      defaultItem: {
        business_type: '',
        contract_type: '',
        project_name: '',
        customer: '',
        income_start: '',
        contract_start: '',
        contract_end: '',
        condition: '',
        allow_ratio: '',
        sign_type: null,
        sign_other: '',
        xt_ratio: '',
        agency_ratio:'',
        sharp_price: '',
        peak_price: '',
        plain_price: '',
        valley_price: '',
        fixed_fee: '',
        remark: '',
      },
      searchCustomer: null,
      createType: '',
    }
  },
  props: {
    getType: {
      type: Number,
      require: true,
      default: null,
    },
    getId: {
      type: String,
      require: true,
      default: '',
    },
    editData: {
      type: Object,
      default: null,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  watch: {
    searchCustomer (val) {
      val && val !== this.contractItem.customer && this.getCustomerList(val)
    },
  },
  created() {
    store.dispatch(FETCH_CUSTOMER_LIST, {query: ''})
  },
  computed: {
    ...mapGetters(["userBusinessType", "customerLoading", "customerList"]),
    signType () {
      return Util.categories('signType')
    },
  },
  mounted() {

  },
  methods: {
    setRatio() {
      let num = 100 - this.contractItem.xt_ratio
      if (num < 0 || this.contractItem.xt_ratio < 0) {
        this.contractItem.agency_ratio = 0
      } else {
        this.contractItem.agency_ratio = num
      }
    },
    getCustomerList (key) {
      store.dispatch(FETCH_CUSTOMER_LIST, {
        query:
          "page=1" +
          "&limit=50" +
          "&key=" + key
      })
    },
    checkData() {
      if(this.editData) {
        this.contractItem = JSON.parse(JSON.stringify(this.editData))
        this.contractItem.sign_date = this.$options.filters['formatDate'](this.contractItem.sign_date)
        this.contractItem.income_start = this.$options.filters['formatDate'](this.contractItem.income_start)
        this.contractItem.contract_start = this.$options.filters['formatDate'](this.contractItem.contract_start)
        this.contractItem.contract_end = this.$options.filters['formatDate'](this.contractItem.contract_end)
        this.contractItem.agency = this.contractItem.agency.id
        // this.contractItem.customer = this.contractItem.customer.id
      }
    },
    closeEdit() {
      this.contractItem = JSON.parse(JSON.stringify(this.defaultItem))
      this.dialog = false
    },
    resetData() {
      let contractItem = JSON.parse(JSON.stringify(this.contractItem))
      contractItem.business_type = this.getType
      contractItem.agency_ratio = Number(this.contractItem.agency_ratio)
      contractItem.xt_ratio = Number(this.contractItem.xt_ratio)
      contractItem.allow_ratio = Number(this.contractItem.allow_ratio)
      contractItem.sharp_price = Number(this.contractItem.sharp_price)
      contractItem.peak_price = Number(this.contractItem.peak_price)
      contractItem.plain_price = Number(this.contractItem.plain_price)
      contractItem.valley_price = Number(this.contractItem.valley_price)
      contractItem.fixed_fee = Number(this.contractItem.fixed_fee)
      let customerType = typeof (this.contractItem.customer)
      if (customerType === 'object') {
        this.createType = 'normal'
        contractItem.customer = this.contractItem.customer._id
      }else {
        this.createType = 'newCustomer'
      }
      return contractItem
    },
    saveSubmit() {
      this.$refs.customerBox.blur()
      this.$nextTick(() => {
        if (this.contractItem.customer) {
          let json = {}
          json.item = this.resetData()
          if (json.item._id) {
            json.id = json.item._id
            if (this.createType === 'normal') {
              this.editContract(this.contractItem.customer._id, json)
            } else if (this.createType === 'newCustomer') {
              this.createCustomerQuick(json.item.customer, json, 'edit')
            }
          }else {
            if (this.createType === 'normal') {
              this.createContract('', json.item)
            } else if (this.createType === 'newCustomer') {
              this.createCustomerQuick(json.item.customer, json.item, 'create')
            }
          }
        }else {
          store.commit(SET_ERROR, {msg: '请填写客户名称'});
        }
      })
    },
    createContract(id, contract) {
      contract.agency = this.getId
      contract.contract_type = 3
      if (id) contract.customer = id
      this.$store.dispatch(CREATE_CONTRACT, contract).then(() => {
        this.$store.commit(SET_ERROR, {msg: '创建成功', color: 'primary'});
        this.$store.commit(RELOAD_CONTRACT_LIST)
        this.contractItem = JSON.parse(JSON.stringify(this.defaultItem))
        this.dialog = false
      }).catch((err) => {
        store.commit(SET_ERROR, {msg: err.data});
      })
    },
    editContract(id, contract) {
      contract.item.customer = id
      this.$store.dispatch(EDIT_CONTRACT, contract).then(() => {
        this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
        this.$store.commit(RELOAD_CONTRACT_LIST)
        this.contractItem = JSON.parse(JSON.stringify(this.defaultItem))
        this.dialog = false
      }).catch((err) => {
        store.commit(SET_ERROR, {msg: err.data});
      })
    },
    createCustomerQuick(name, item, type) {
      this.$store.dispatch(CREATE_CUSTOMER_QUICK, { name: name }).then((res) => {
        if (type === 'edit') {
          this.editContract(res._id, item)
        } else if (type === 'create') {
          this.createContract(res._id, item)
        }
      }).catch((err) => {
        store.commit(SET_ERROR, {msg: err.data});
      })
    },
  },
  directives: {}
}
</script>

<style>
input[type="file"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
}
</style>

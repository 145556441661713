import { DistributorService } from "@/common/http";
import {
  FETCH_DISTRIBUTOR_LIST,
  FETCH_DISTRIBUTOR,
  PUBLISH_DISTRIBUTOR,
  EDIT_DISTRIBUTOR,
  AUTH_DISTRIBUTOR,
  BLOCK_DISTRIBUTOR,
  BOOKMARK_DISTRIBUTOR,
  DELETE_DISTRIBUTOR,
  CHECK_DISTRIBUTOR_CODE,
  FETCH_DISTRIBUTOR_AGENT,
} from "./actions.type";
import {
  SET_ERROR,
  GET_DISTRIBUTORS,
  SET_DISTRIBUTORS,
  SET_DISTRIBUTOR,
  SET_DISTRIBUTOR_AGENT,
} from "./mutations.type"

const state = {
  distributorLoading: true,
  distributors: [],
  distributor: {},
  distributor_city: [],
  distributor_agent: [],
};

const getters = {
  distributor: state => state.distributor,
  distributors: state => state.distributors,
  distributor_city: state => state.distributor_city,
  distributor_agent: state => state.distributor_agent,
  distributorLoading: state => state.distributorLoading,
};

const actions = {
  async [FETCH_DISTRIBUTOR_AGENT](context) {
    const { data } = await DistributorService.get('/agency/list')
    context.commit(SET_DISTRIBUTOR_AGENT, data)
  },
  [CHECK_DISTRIBUTOR_CODE](context, code) {
    return new Promise((resolve, reject) => {
      DistributorService.get("/check/invitecode", code)
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        })
    });
  },
  [FETCH_DISTRIBUTOR_LIST]({ commit }, slug) {
    commit(GET_DISTRIBUTORS);
    return DistributorService.get('/list', '?'+slug)
      .then(({ data }) => {
        commit(SET_DISTRIBUTORS, data.docs);
      })
      .catch(error => {
        commit(SET_ERROR, { msg: error });
      })
  },
  [FETCH_DISTRIBUTOR]({ commit }, slug) {
    commit(GET_DISTRIBUTORS);
    return new Promise((resolve, reject) => {
      DistributorService.get('', slug)
        .then(({ data }) => {
          commit(SET_DISTRIBUTOR, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [PUBLISH_DISTRIBUTOR](context, dealer) {
    return new Promise((resolve, reject) => {
      DistributorService.create('', dealer)
        .then(({ data }) => {
          context.commit(SET_DISTRIBUTOR, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [EDIT_DISTRIBUTOR](context, dealer) {
    return new Promise((resolve, reject) => {
      DistributorService.update('', dealer._id, dealer)
        .then(({ data }) => {
          context.commit(SET_DISTRIBUTOR, data);
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [AUTH_DISTRIBUTOR](context, update) {
    return new Promise((resolve, reject) => {
      DistributorService.auth(update._id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.message);
        });
    });
  },
  [BLOCK_DISTRIBUTOR](context, update) {
    return new Promise((resolve, reject) => {
      DistributorService.block(update._id, update.reply)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.message);
        });
    });
  },
  [BOOKMARK_DISTRIBUTOR](context, update) {
    return new Promise((resolve, reject) => {
      DistributorService.bookmark(update._id, update.reply)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response.data.message);
        });
    });
  },
  [DELETE_DISTRIBUTOR](context, update) {
    return new Promise((resolve, reject) => {
      DistributorService.delete(update._id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    })
  },
}

const mutations = {
  [GET_DISTRIBUTORS] (state) {
    state.distributorLoading = true;
  },
  [SET_DISTRIBUTORS](state, data) {
    state.distributors = data;
    state.distributor_city = [...new Set([].concat(...data.map(e => e.city)))];
    state.distributorLoading = false;
  },
  [SET_DISTRIBUTOR](state, data) {
    state.distributor = data;
    state.distributorLoading = false;
  },
  [SET_DISTRIBUTOR_AGENT](state, data) {
    state.distributor_agent = data
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};

<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="itemlist"
      :hide-default-footer="itemlist.length < 10"
    >
      <template v-slot:item.customer="{ item }">
        <span>{{ item.customer?.name}}</span>
      </template>
      <template v-slot:item.data.year="{ item }">
        <span>{{ new Date(item.date).getFullYear() }}年</span>
      </template>
      <template v-slot:item.data.month="{ item }">
        <span>{{ new Date(item.date).getMonth()+1 }}月</span>
      </template>
      <template v-slot:item.agency_invoice_type="{ item }">
        <span>{{ item.agency_invoice_type === 1 ? '专票' : '普票' }}</span>
      </template>
      <template v-slot:item.agency_fee="{ item }">
        <span>{{ item.agency_fee.$numberDecimal | formatMoney }}</span>
      </template>

      <template v-slot:item.settle_elec_total="{ item }">
        <span>{{ (item.settle_elec_total?.$numberDecimal*1).toFixed(3) }}</span>
      </template>
      <template v-slot:item.invoice_type="{ item }">
        <span>{{ item.invoice_type === 1 ? '专票' : '普票' }}</span>
      </template>
      <template v-slot:body.append="{ headers }">
        <tr>
          <td :colspan="headers.length - 1">合计</td>
          <td style="text-align: end">{{caseInfo.group === 1 ? itemlist.reduce((acc, curr) => acc + Number(curr.agency_fee.$numberDecimal), 0)
                : itemlist.reduce((acc, curr) => acc + Number(curr.agency_fee.$numberDecimal), 0)
                | formatMoney }}</td>
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>
<script>
export default {
  props: {
    caseInfo: {
      type: Object,
      default: '',
    },
  },
  data() {
    return {
      headers: [],
      itemlist: [],
      defaultEnergyHead:[
        { text: '客户名称', value: 'customer', width: '280'},
        { text: '年份', value: 'data.year', width: '80'},
        { text: '月份', value: 'data.month', width: '80'},
        { text: '发票类型', value: 'agency_invoice_type', width: '80'},
        { text: '价税合计', value: 'agency_fee', width: '80', align: 'right'},
      ],
      defaultSellingHead: [
        { text: '客户名称', value: 'customer', width: '280'},
        { text: '结算电量(兆瓦时)', value: 'settle_elec_total', width: '180'},
        { text: '发票类型', value: 'invoice_type', width: '80'},
        { text: '总开票金额（元）', value: 'agency_fee', width: '80', align: 'right'},
      ],
    }
  },
  watch: {},
  created() {
    this.initData()
  },
  computed: {},
  mounted() {},
  methods: {
    initData() {
      if (this.caseInfo.group === 1) {
        Object.assign(this.headers, this.defaultEnergyHead)
      } else {
        Object.assign(this.headers, this.defaultSellingHead)
      }
      this.itemlist = this.caseInfo.settleList
    },
  }
}
</script>

<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="300px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <v-icon small>format_line_spacing</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title><span class="heading">调整表头</span></v-card-title>
      <v-card-text style="height: 600px;">
        <v-list dense class="mx-n4">
          <draggable v-model="orderList" v-bind="dragOptions">
            <v-list-item v-for="(item, index) in orderList" :key="index">
              <v-list-item-icon>
                <v-icon style="cursor: move" class="mt-2" small>drag_indicator</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-simple-checkbox
                  :value="item.align === ' d-none' ? false : true"
                  @click="doAction('edit_headers', item)"
                ></v-simple-checkbox>
              </v-list-item-action>
            </v-list-item>
          </draggable>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn text @click="doAction('set_default')">重置</v-btn>
        <v-spacer></v-spacer>
        <v-btn text @click="doAction('set_cancel')">取消</v-btn>
        <v-btn color="primary" text @click="doAction('set_save')">确认</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import draggable from "vuedraggable";
import {
  CHECK_AUTH,
  EDIT_ACCOUNT,
} from '@/store/actions.type'
import {SET_ERROR} from "@/store/mutations.type";
import store from "@/store";
export default {
  props: {
    tableName: {
      type: String,
      default: 'settleSaleHeader',
    },
    headerList: {
      type: Array,
      required: true,
    },
    defaultHeader: {
      type: Array,
      required: true,
    },
    version: {
      type: Number,
      default: 0,
    },
    btnType: {
      type: String,
      default: "icon"
    },
  },
  components: {
    draggable,
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "header",
        disabled: false,
        ghostClass: "ghost"
      };
    },
    orderList: {
      get() {
        return this.headerList;
      },
      set(value) {
        let strUpdate = {}
        strUpdate[this.tableName] = {}
        strUpdate[this.tableName].version = this.version
        strUpdate[this.tableName].content = value
        store.dispatch(EDIT_ACCOUNT, strUpdate)
          .then(() => {
            store.dispatch(CHECK_AUTH)
              .then(() => {
                this.$emit('Save')
              })
              .catch((error) => {
                store.commit(SET_ERROR, {msg: error.response.data.message});
              })
          })
          .catch((error) => {
            store.commit(SET_ERROR, {msg: error.response.data.message});
          })
      }
    },
  },
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    close () {
      this.dialog = false
    },
    doAction(action, item = {}) {
      switch (action) {
        case 'edit_headers': {
          const index = this.headerList.indexOf(item);
          item.align === " d-none"
            ? this.$delete(this.headerList[index], "align")
            : this.$set(this.headerList[index], "align", " d-none");
          break
        }
        case 'set_save': {
          let strUpdate = {}
          strUpdate[this.tableName] = {}
          strUpdate[this.tableName].version = this.version
          strUpdate[this.tableName].content = this.headerList
          store.dispatch(EDIT_ACCOUNT, strUpdate)
            .then(() => {
              store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
              store.dispatch(CHECK_AUTH)
                .then(() => {
                  this.$emit('Save')
                  this.close()
                })
                .catch((error) => {
                  store.commit(SET_ERROR, {msg: error.response.data.message});
                })
            })
            .catch((error) => {
              store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break
        }
        case 'set_default': {
          let strUpdate = {}
          strUpdate[this.tableName] = {}
          strUpdate[this.tableName].version = this.version
          strUpdate[this.tableName].content = this.defaultHeader
          store.dispatch(EDIT_ACCOUNT, strUpdate)
            .then(() => {
              store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
              store.dispatch(CHECK_AUTH)
                .then(() => {
                  this.$emit('Save')
                  this.close()
                })
                .catch((error) => {
                  store.commit(SET_ERROR, {msg: error.response.data.message});
                })
            })
            .catch((error) => {
              store.commit(SET_ERROR, {msg: error.response.data.message});
            })
          break
        }
        case 'set_cancel': {
          this.$emit('Cancel')
          this.close()
          break
        }
        default: {

          break
        }
      }
    }
  }
}
</script>

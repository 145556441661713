import {SettingService} from "@/common/http";

import {
  FETCH_RELEASE_LIST,
  PUBLISH_RELEASE,
  EDIT_RELEASE,
  DELETE_RELEASE,
  FETCH_RELEASE
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_RELEASE_LIST,
  SET_RELEASE_LIST,
} from "./mutations.type"

const state = {
  releaseList: [],
  releaseLoading: true,
  releaseCount: 0,
};

const getters = {
  releaseLoading: state => state.releaseLoading,
  releaseList: state => state.releaseList,
  releaseCount: state => state.releaseCount,
};

const actions = {
  [FETCH_RELEASE](context, slug) {
    return new Promise((resolve, reject) => {
      SettingService.get('/release', slug)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [FETCH_RELEASE_LIST]({ commit }, slug) {
    commit(LOAD_RELEASE_LIST);
    return new Promise((resolve, reject) => {
      SettingService.get('/release/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_RELEASE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_RELEASE](context, release) {
    return SettingService.create('/release', release);
  },
  [EDIT_RELEASE](context, release) {
    return SettingService.update('/release', release._id, release);
  },
  [DELETE_RELEASE](context, release) {
    return SettingService.destroy('release/'+ release)
  }
}

const mutations = {
  [LOAD_RELEASE_LIST] (state) {
    state.releaseLoading = true;
  },
  [SET_RELEASE_LIST](state, list) {
    state.releaseCount = list.query.count;
    state.releaseList = list.release;
    state.releaseLoading = false;
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};

export default [
  {
    path: '/',
    redirect: {
      name: 'Workbench'
    },
    hidden: true,
    name: 'home',
    meta: {
      title: 'Home',
      roles: ['admin', 'distributor']
    },
  },
  // {
  //   path: '/dashboard',
  //   component: () => import(
  //     /* webpackChunkName: "routes" */
  //     '@/views/dashboard.vue'
  //   ),
  //   name: 'dashboard',
  //   meta: {
  //     title: '仪表盘',
  //     icon: 'dashboard',
  //     roles: ['admin', 'distributor']
  //   }
  // },
  {
    path: '/distributor/workbench',
    component: () => import(
      /* webpackChunkName: "routes" */
      '@/views/distributor/workbench/index.vue'
    ),
    name: 'Workbench',
    meta: {
      title: '工作台',
      icon: 'assignment',
      roles: ['distributor']
    }
  },
  {
    path: '/403',
    hidden: true,
    name: 'accessdenied',
    meta: {
      title: 'AccessDenied',
      public: true,
      breadcrumb: false,
      roles: ['admin', 'distributor', 'agency', 'enterprise']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/Deny.vue`
    )
  },
  {
    path: '/404',
    hidden: true,
    name: 'notfound',
    meta: {
      title: 'NotFound',
      public: true,
      breadcrumb: false,
      roles: ['admin', 'distributor', 'agency', 'enterprise']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/NotFound.vue`
    )
  },
  {
    path: '/500',
    hidden: true,
    name: 'servererror',
    meta: {
      title: 'ServerError',
      public: true,
      breadcrumb: false,
      roles: ['admin', 'distributor', 'agency', 'enterprise']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/Error.vue`
    )
  },
  {
    path: '/login',
    hidden: true,
    name: 'login',
    meta: {
      title: 'Login',
      public: true,
      breadcrumb: false,
      roles: ['admin', 'distributor', 'agency', 'enterprise']
    },
    component: () => import(
      /* webpackChunkName: "routes" */
      `@/views/login`
    )
  },
]

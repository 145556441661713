var render = function render(){
  var _vm$settleData$case_n, _vm$settleData, _vm$settleData$tasks_, _vm$settleData$tasks_2, _vm$settleData$produc;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "scrollable": "",
      "persistent": "",
      "width": "1000"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('span', _vm._g(_vm._b({
          on: {
            "click": _vm.checkData
          }
        }, 'span', attrs, false), on), [_vm._t("default"), _vm._v(" "), _vm.divider ? _c('span', [_vm._v(_vm._s(_vm.divider))]) : _vm._e()], 2)];
      }
    }], null, true),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', {
    attrs: {
      "width": "600",
      "light": ""
    }
  }, [_c('v-card-title', [_c('span', [_vm._v("结算汇总单")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "py-6"
  }, [_c('div', {
    ref: "report",
    staticClass: "pa-6"
  }, [_c('div', {
    staticClass: "black--text caption mb-2"
  }, [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("流程编号：")]), _vm._v(_vm._s((_vm$settleData$case_n = _vm.settleData.case_number) === null || _vm$settleData$case_n === void 0 ? void 0 : _vm$settleData$case_n.replace('SC', 'RA')))]), _vm.getType === 1 ? _c('case-receipt-summary-energy', {
    attrs: {
      "settleData": _vm.settleData
    }
  }) : _vm._e(), _vm.getType === 2 ? _c('case-receipt-summary-peak', {
    attrs: {
      "settleData": _vm.settleData
    }
  }) : _vm._e(), _vm.getType === 3 ? _c('case-receipt-summary-selling', {
    attrs: {
      "settleData": _vm.settleData
    }
  }) : _vm._e()], 1)]), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEdit
    }
  }, [_vm._v("取消")]), _c('v-spacer'), ((_vm$settleData = _vm.settleData) === null || _vm$settleData === void 0 ? void 0 : (_vm$settleData$tasks_ = _vm$settleData.tasks_list) === null || _vm$settleData$tasks_ === void 0 ? void 0 : (_vm$settleData$tasks_2 = _vm$settleData$tasks_.find(function (i) {
    return i.isActive;
  })) === null || _vm$settleData$tasks_2 === void 0 ? void 0 : _vm$settleData$tasks_2.order) === 0 && _vm.settleData.group === 3 && _vm.settleData.status !== 2 && ((_vm$settleData$produc = _vm.settleData.producedBy) === null || _vm$settleData$produc === void 0 ? void 0 : _vm$settleData$produc._id) === _vm.currentUser._id ? _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.openEditDlg
    }
  }, [_vm._v("编辑")]) : _vm._e(), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": _vm.downloading
    },
    on: {
      "click": _vm.downloadReceipt
    }
  }, [_vm._v("下载")])], 1)], 1), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "600"
    },
    model: {
      value: _vm.editDlg,
      callback: function callback($$v) {
        _vm.editDlg = $$v;
      },
      expression: "editDlg"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 编辑汇总数据 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeEditDlg
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-divider'), _c('v-card-text', {
    staticClass: "text-center pt-6"
  }, [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.saveSubmit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "结算电量*",
      "type": "number",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "suffix": "兆瓦时"
    },
    model: {
      value: _vm.editItem.settle_elec_total,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "settle_elec_total", $$v);
      },
      expression: "editItem.settle_elec_total"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "代理商服务费(含税)*",
      "type": "number",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "suffix": "元"
    },
    model: {
      value: _vm.editItem.agency_service_fee,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "agency_service_fee", $$v);
      },
      expression: "editItem.agency_service_fee"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "代理商服务费(不含税)*",
      "type": "number",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "suffix": "元"
    },
    model: {
      value: _vm.editItem.agency_service_fee_no_tax,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "agency_service_fee_no_tax", $$v);
      },
      expression: "editItem.agency_service_fee_no_tax"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "税金及附加*",
      "type": "number",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "suffix": "元"
    },
    model: {
      value: _vm.editItem.tax,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "tax", $$v);
      },
      expression: "editItem.tax"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "代理商费用(不含税)*",
      "type": "number",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "suffix": "元"
    },
    model: {
      value: _vm.editItem.agency_fee_no_tax,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "agency_fee_no_tax", $$v);
      },
      expression: "editItem.agency_fee_no_tax"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "代理商费用(含税)*",
      "type": "number",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "suffix": "元"
    },
    model: {
      value: _vm.editItem.agency_fee,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "agency_fee", $$v);
      },
      expression: "editItem.agency_fee"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": "总开票金额*",
      "type": "number",
      "outlined": "",
      "rules": [_vm.rules.required],
      "dense": "",
      "suffix": "元"
    },
    model: {
      value: _vm.editItem.agency_fee_total,
      callback: function callback($$v) {
        _vm.$set(_vm.editItem, "agency_fee_total", $$v);
      },
      expression: "editItem.agency_fee_total"
    }
  })], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": _vm.closeEditDlg
    }
  }, [_vm._v("取消")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "primary",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.editSummary
    }
  }, [_vm._v("保存")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
import {ToolsService} from "@/common/http";
import {
  FETCH_UPLOAD,
  FETCH_URL,
  CHECK_ADDR,
  SEND_SMS_CODE,
  CHECK_SMS_CODE,
  SEND_EMAIL_CODE,
  PUBLISH_BUG,
  FETCH_BUG_LIST,
  FETCH_BUG,
} from "./actions.type";

import {
  SET_ERROR,
  SET_SIGN,
  SET_URL,
  LOAD_BUG,
  SET_BUG_LIST,
  SET_BUG,
} from "./mutations.type";

const state = {
  uploadSign: "",
  urlSign: "",
  bugLoading: true,
  bugList: [],
  bugCount: 0,
  bugInfo: {},
};

const getters = {
  uploadSign: state => state.uploadSign,
  urlSign: state => state.urlSign,
  bugList: state => state.bugList,
  bugCount: state => state.bugCount,
  bugLoading: state => state.bugLoading,
  bugInfo: state => state.bugInfo,
};

const actions = {
  [FETCH_UPLOAD]({ commit }, type = 'public') {
    return new Promise((resolve, reject) => {
      ToolsService.get("/sign/upload", "?type="+ type)
        .then(({ data }) => {
          commit(SET_SIGN, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, {message: error});
          reject(error);
        });
    });
  },
  [FETCH_URL]({ commit }, name) {
    return new Promise((resolve, reject) => {
      ToolsService.get("/sign/download", "?name="+ encodeURIComponent(name))
        .then(({ data }) => {
          commit(SET_URL, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, {message: error});
          reject(error);
        });
    });
  },
  [CHECK_ADDR](context, credentials) {
    return new Promise((resolve, reject) => {
      ToolsService.get("/check/address", "?address="+ encodeURI(credentials))
        .then(({ data }) => {
          resolve(data);
        }).catch(({ response }) => {
          reject(response);
        })
    });
  },
  [SEND_SMS_CODE](context, sms) {
    return new Promise((resolve, reject) => {
      ToolsService.get("/sms/"+ sms.phone, "?template="+ sms.type)
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        })
    });
  },
  [SEND_EMAIL_CODE](context, email) {
    return new Promise((resolve, reject) => {
      ToolsService.get("/email/"+ email.to, "?template="+ email.type)
        .then((data) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response.data);
        })
    });
  },
  [CHECK_SMS_CODE](context, sms) {
    return new Promise((resolve, reject) => {
      let form = new FormData();
      form.append('code', sms.code);
      let param = new URLSearchParams(form);
      ToolsService.put("/sms/"+ sms.phone +"?template="+ sms.type, param)
        .then(({ data }) => {
          resolve(data);
        }).catch(({ response }) => {
          reject(response);
        })
    });
  },
  [PUBLISH_BUG](context, params) {
    return new Promise((resolve, reject) => {
      ToolsService.create('/bug', params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [FETCH_BUG_LIST]({ commit }, slug) {
    commit(LOAD_BUG);
    return new Promise((resolve, reject) => {
      ToolsService.get('/bug', 'list?'+slug)
        .then(({ data }) => {
          commit(SET_BUG_LIST, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [FETCH_BUG]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      ToolsService.get('/bug', slug)
        .then(({ data }) => {
          commit(SET_BUG, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
};

const mutations = {
  [SET_SIGN](state, sign) {
    state.uploadSign = sign;
  },
  [SET_URL](state, sign) {
    state.urlSign = sign;
  },
  [LOAD_BUG] (state) {
    state.bugLoading = true;
  },
  [SET_BUG_LIST](state, list) {
    state.bugList = list.bugs;
    state.bugCount = list.query.count;
    state.bugLoading = false;
  },
  [SET_BUG](state, bug) {
    state.bugInfo = bug;
    state.bugLoading = false;
  },
};

export default {
  state,
  getters,
  actions,
  mutations
}

//登录、注销、生成路由信息
export const SET_AUTH = "setAuth";
export const SET_USER = "setUser";
export const PURGE_AUTH = "logOut";
export const SET_ERROR = "setError";
export const SET_ROUTERS = 'setRouters';
//账号相关
export const GET_ACCOUNTS = 'startAccountList';
export const SET_ACCOUNTS = 'setAccountList';
//主体管理
export const GET_DISTRIBUTORS = 'startDistributorList';
export const SET_DISTRIBUTORS = 'setDistributorList';
export const SET_DISTRIBUTOR_AGENT = 'setDistributorAgent';
export const SET_DISTRIBUTOR = 'setDistributor';
//部门管理
export const GET_DEPTS = 'startDeptList';
export const SET_DEPTS = 'setDeptList';
export const SET_DEPT = 'setDeptInfo';
//角色分组
export const LOAD_GROUP_LIST = 'startGroupList';
export const SET_GROUP_LIST = 'setGroupList';
export const SET_GROUP = 'setGroupInfo';
//分组管理
export const LOAD_ROLE_LIST = 'startRoleList';
export const SET_ROLE_LIST = 'setRoleList';
export const SET_ROLE = 'setRoleInfo';
//员工管理
export const SET_EMPLOYEE_LIST = 'setEmployeeList';
export const SET_ADMIN_LIST = 'setAdminList';
export const LOAD_EMPLOYEE = 'startEmployee';
export const SET_EMPLOYEE = 'setEmployee';
//日志
export const SET_LOGS = 'addLog';
export const LOAD_LOGS = 'startLogsList';
export const SET_LOGS_LIST = 'setLogsList';
//主题
export const SET_THEME = 'setTheme';
//工具
export const SET_SIGN = 'setUploadSign';
export const SET_URL = 'setUrlSign';

//客户管理
export const LOAD_CUSTOMER_LIST = 'startCustomerList';
export const SET_CUSTOMER_LIST = 'setCustomerList';
export const SET_CUSTOMER = 'startCustomer';

//代理商管理
export const LOAD_AGENCY_LIST = 'startAgencyList';
export const SET_AGENCY_LIST = 'setAgencyList';
export const SET_AGENCY = 'startAgency';

//合同管理
export const LOAD_CONTRACT_LIST = 'startContractList';
export const SET_CONTRACT_LIST = 'setContractList';
export const SET_CONTRACT_CUSTOMER = 'setContractCustomerList';
export const SET_CONTRACT = 'startContract';
export const RELOAD_CONTRACT_LIST = 'reloadContractList';

//结算管理
export const LOAD_SETTLE_LIST = 'startSettleList';
export const SET_SETTLE_LIST = 'setSettleList';
export const SET_SETTLE = 'startSettle';
export const SET_SELECTED_SETTLE = 'setSelectedSettleList';

//工单管理
export const SET_CASE_INIT = 'setInitCase';
export const LOAD_CASE_LIST = 'startCaseList';
export const SET_CASE_LIST = 'setCaseList';
export const SET_CASE = 'startCase';

//数据统计
export const SET_REPORT_DRAWER = 'setReportDrawer';
export const LOAD_REPORT_SETTLE = 'startReportSettle';
export const SET_REPORT_SETTLE = 'setReportSettle';
export const LOAD_REPORT_SETTLE_SUMMARY = 'startReportSettleSummary';
export const SET_REPORT_SETTLE_SUMMARY = 'setReportSettleSummary';
export const LOAD_REPORT_AREA_LIST = 'startReportAreaList';
export const SET_REPORT_AREA_LIST = 'setReportAreaList';

//区域管理
export const LOAD_AREA_LIST = 'startAreaList';
export const SET_AREA_LIST = 'setAreaList';
export const SET_AREA_ALL_LIST = 'setAreaAllList';

//任务模版
export const LOAD_TASK_LIST = 'startTaskList';
export const SET_TASK_LIST = 'setTaskList';
export const SET_TASK_ALL_LIST = 'setTaskAllList';

//储备客户
export const LOAD_FILINGS = 'startFilingList';
export const SET_FILING_LIST = 'setFilingList';
export const SET_FILING = 'setFiling';

//附件备注
export const LOAD_NOTES_LIST = 'startNotesList';
export const SET_NOTES_LIST = 'setNotesList';
export const SET_NOTES = 'setNotes';

//版本发布
export const LOAD_RELEASE_LIST = 'startReleaseList';
export const SET_RELEASE_LIST = 'setReleaseList';

//BUG管理
export const LOAD_BUG = 'startBugList';
export const SET_BUG_LIST = 'setBugList';
export const SET_BUG = 'setBug';

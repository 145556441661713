import {CaseService} from "@/common/http";

import {
  INIT_CASE,
  CREATE_CASE,
  FETCH_CASE_LIST,
  FETCH_CASE,
  EDIT_CASE,
  EDIT_CASE_SETTLE,
  SUBMIT_CASE,
  REJECT_CASE,
  REMOVE_CASE,
  PRINT_CASE,
  DOWNLOAD_CASE_PAYMENT,
  DOWNLOAD_CASE_SUMMARY,
} from "./actions.type";

import {
  SET_ERROR,
  SET_CASE_INIT,
  LOAD_CASE_LIST,
  SET_CASE_LIST,
  SET_CASE,
} from "./mutations.type";

const state = {
  caseLoading: false,
  process_info: {},
  caseList: [],
  caseCount: 0,
  createCount: 0,
  remainCount: 0,
  editedCount: 0,
  caseInfo: {},
  caseSelected: {},
};

const getters = {
  caseLoading: state => state.caseLoading,
  process_info: state => state.process_info,
  caseList: state => state.caseList,
  caseCount: state => state.caseCount,
  createCount: state => state.createCount,
  remainCount: state => state.remainCount,
  editedCount: state => state.editedCount,
  caseInfo: state => state.caseInfo,
  caseSelected: state => state.caseSelected,
};

const actions = {
  [INIT_CASE]({ commit }, params) {
    return new Promise((resolve, reject) => {
      CaseService.get('/initialize', '?'+params.query)
        .then(({ data }) => {
          commit(SET_CASE_INIT, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [CREATE_CASE](context, params) {
    return new Promise((resolve, reject) => {
      CaseService.create('', params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response);
        })
    });
  },
  [FETCH_CASE]({ commit }, params) {
    commit(LOAD_CASE_LIST);
    return new Promise((resolve, reject) => {
      CaseService.get('/settle', params.query)
        .then(({ data }) => {
          commit(SET_CASE, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [EDIT_CASE](context, params) {
    return CaseService.update('/' +  params.id, params.item);
  },
  [EDIT_CASE_SETTLE](context, params) {
    return CaseService.update('/settle/settleSumData/' +  params.id, params.item);
  },
  [SUBMIT_CASE](context, data) {
    return CaseService.submit('/' + data);
  },
  [REJECT_CASE](context, data) {
    return CaseService.reject('/' + data);
  },
  [REMOVE_CASE](context, data) {
    return CaseService.delete('/' + data);
  },
  [PRINT_CASE](context, id) {
    return CaseService.update('/settle/print/payment/' + id);
  },
  [DOWNLOAD_CASE_PAYMENT](context, id) {
    return CaseService.update('/settle/download/payment/' + id);
  },
  [DOWNLOAD_CASE_SUMMARY](context, id) {
    return CaseService.update('/settle/download/summary/' + id);
  },
  [FETCH_CASE_LIST]({ commit }, params) {
    commit(LOAD_CASE_LIST);
    return new Promise((resolve, reject) => {
      CaseService.get('/settle/list', '?'+params.query)
        .then(({ data }) => {
          commit(SET_CASE_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
}

const mutations = {
  [SET_CASE_INIT](state, process) {
    state.process_info = process;
  },
  [LOAD_CASE_LIST] (state) {
    state.caseLoading = true;
  },
  [SET_CASE_LIST](state, list) {
    state.caseCount = list.query.count;
    state.createCount = list.query.createCount;
    state.remainCount = list.query.remainCount;
    state.editedCount = list.query.editedCount;
    state.caseList = list.docs;
    state.caseLoading = false;
  },
  [SET_CASE](state, data) {
    state.caseInfo = data.caseInfo;
    state.caseLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

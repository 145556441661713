var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "mx-auto"
  }, [_c('v-list-item', [_c('v-list-item-avatar', {
    attrs: {
      "color": "primary",
      "size": "30"
    }
  }, [_c('v-icon', {
    attrs: {
      "dark": "",
      "small": ""
    },
    domProps: {
      "textContent": _vm._s(_vm.icon)
    }
  })], 1), _c('v-list-item-content', [_c('v-list-item-title', {
    staticClass: "body-1 font-weight-light mb-2",
    domProps: {
      "textContent": _vm._s(_vm.count)
    }
  }), _c('v-list-item-subtitle', {
    staticClass: "caption font-weight-light",
    domProps: {
      "textContent": _vm._s(_vm.label)
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-app-bar app clipped-left color="primary" dark>
    <v-img alt="Logo" class="shrink mr-2" contain src="/static/logo.png" transition="scale-transition" max-width="35"></v-img>
    <v-toolbar-title>鑫泰能源信息化系统</v-toolbar-title>
    <core-version class="ml-2 mt-2" :id="verisonInfo._id">v{{verisonInfo.version}}</core-version>
    <v-app-bar-nav-icon @click.stop="drawerToggle"></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-menu offset-y bottom left :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark icon v-bind="attrs" v-on="on">
          <v-icon>account_circle</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-list v-if="currentUser">
          <v-list-item>
            <v-list-item-avatar>
              <v-img :src="currentUser.personal && currentUser.personal.profile ? ossURL +'/'+ currentUser.personal.profile : '/static/grey_silhouette.png'" aspect-ratio="1" contain></v-img>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{currentUser.personal && currentUser.personal.name || currentUser.username}}</v-list-item-title>
              <v-list-item-subtitle>{{currentUser.employer && currentUser.employer.ownerId.name || '无企业信息'}}：{{currentUser.employer && currentUser.employer.roles.map(item => item.name)}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider></v-divider>
        <v-list v-if="currentUser.notice">
          <v-list-item>
            <v-list-item-action>
              <v-switch v-model="currentUser.notice.phone" color="secondary" @change="editNotice()"></v-switch>
            </v-list-item-action>
            <v-list-item-title>短信通知</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
          <account-dialogs :account="currentUser._id"><v-btn text>个人资料</v-btn></account-dialogs>
          <v-btn color="secondary" text @click="logout">退出登陆</v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-btn icon @click="changeTheme(!darkTheme)"><v-icon>invert_colors</v-icon></v-btn>
    <v-btn icon v-if="!$vuetify.breakpoint.mobile" @click="fullScreen()"><v-icon>fullscreen</v-icon></v-btn>
    <v-btn icon v-if="showInstall" @click="installPWA()"><v-icon>install_desktop</v-icon></v-btn>
  </v-app-bar>

</template>
<script>
import Util from '@/common/util';
import {
  LOGOUT,
  CHECK_AUTH,
  EDIT_ACCOUNT,
  CHANGE_THEME,
  FETCH_RELEASE
} from '@/store/actions.type'
import { SET_ERROR } from "@/store/mutations.type";
import { mapGetters } from 'vuex'
import store from "@/store";

export default {
  data: () => ({
    verisonInfo: {},
    showInstall: false,
  }),
  computed: {
    ...mapGetters(['darkTheme', 'currentUser']),
  },
  beforeMount() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.installEvent = e
      this.showInstall = true
    })
  },
  created() {
    store.dispatch(FETCH_RELEASE, 'last')
      .then((data) => {
        this.verisonInfo = Object.assign({}, data)
      })
  },
  methods: {
    fullScreen () {
      Util.toggleFullScreen();
    },
    drawerToggle() {
      window.getApp.$emit("APP_DRAWER_TOGGLED");
    },
    changeTheme (dark) {
      this.$store.dispatch(CHANGE_THEME, dark)
      this.$vuetify.theme.dark = dark
    },
    logout() {
      this.$store.dispatch(LOGOUT).then(() => {
        location.reload()
      })
    },
    updateInfo() {
      this.$store.dispatch(CHECK_AUTH)
    },
    editNotice() {
      this.$store.dispatch(EDIT_ACCOUNT, {noticePhone: this.currentUser.notice.phone, noticeEmail: this.currentUser.notice.email})
        .then(() => {
          this.$store.commit(SET_ERROR, {msg: '更新成功', color: 'primary'});
          this.updateInfo()
        })
        .catch((error) => {
          this.$store.commit(SET_ERROR, {msg: error.response.data.message});
        })
    },
    installPWA() {
      this.installEvent.prompt()
      this.installEvent.userChoice.then((choice) => {
        this.dismissPrompt() // Hide the banner once the user's clicked
        if (choice.outcome === 'accepted') {
          // Do something additional if the user chose to install
        } else {
          // Do something additional if the user declined
        }
      })
    },
    dismissPrompt() {
      this.showInstall = false
    }
  }
};
</script>

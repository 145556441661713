import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/common/jwt'

const API_URL = process.env.VUE_APP_BASE_API || 'http://localhost:9800/v1'

const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = API_URL;
  },
  setHeader() {
    Vue.axios.defaults.headers.common[
      "Authorization"
    ] = JwtService.getToken();
  },
  query(resource, params) {
    return Vue.axios.get(resource, params);
  },
  get(resource, slug = "") {
    return Vue.axios.get(`${resource}/${slug}`);
  },
  post(resource, params, config) {
    return Vue.axios.post(`${resource}`, params, config);
  },
  update(resource, slug, params) {
    return Vue.axios.put(`${resource}/${slug}`, params);
  },
  put(resource, params) {
    return Vue.axios.put(`${resource}`, params);
  },
  delete(resource, params) {
    return Vue.axios.delete(resource, params);
  },
  download(resource, config){
    return Vue.axios.get(resource, config);
  }
};
export default ApiService;

//主体服务
export const DistributorService = {
  get(resource, slug) {
    return ApiService.get('/distributor'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/distributor'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/distributor'+ resource, params);
  },
  delete(slug) {
    return ApiService.delete('/distributor/'+ slug);
  },
  auth(slug) {
    return ApiService.update('/distributor/auth', slug);
  },
  block(slug, params) {
    return ApiService.put('/distributor/block/'+slug+'/'+ params);
  },
  bookmark(slug, params) {
    return ApiService.put('/distributor/bookmark/'+slug+'/'+ params);
  }
};

//日志服务
export const LogService = {
  get(resource, slug) {
    return ApiService.get('/logs'+ resource, slug);
  },
  add(params) {
    return ApiService.post('/logs', params)
  }
}

//账号服务
export const AccountService = {
  get(resource, slug) {
    return ApiService.get('/account'+ resource, slug);
  },
  create(resource, params) {
    return ApiService.post('/account'+ resource, params);
  },
  update(slug, params) {
    return ApiService.update('/account', slug, params);
  },
  remove(resource) {
    return ApiService.delete('/account'+ resource);
  }
};
//员工管理
export const EmployeeService = {
  get(resource, slug) {
    return ApiService.get('/employee'+ resource, slug);
  },
  create(resource, params) {
    return ApiService.post('/employee'+ resource, params);
  },
  update(resource, slug, params) {
    return ApiService.update('/employee'+ resource, slug, params);
  },
  batch(resource, params) {
    return ApiService.put('/employee/batch'+ resource, params)
  },
  destroy(resource) {
    return ApiService.delete('/employee'+ resource);
  },
};
//部门管理
export const DepartmentService = {
  get(resource, slug) {
    return ApiService.get('/department'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/department'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/department'+ resource, params);
  },
  destroy(slug) {
    return ApiService.delete('/department/'+ slug);
  }
};
//角色分组管理
export const GroupService = {
  get(resource, slug) {
    return ApiService.get('/group'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/group'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/group'+ resource, params);
  },
  destroy(slug) {
    return ApiService.delete('/group/'+ slug);
  }
};
//角色管理
export const RoleService = {
  get(resource, slug) {
    return ApiService.get('/role'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/role'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/role'+ resource, params);
  },
  destroy(slug) {
    return ApiService.delete('/role/'+ slug);
  }
};
//工具类
export const ToolsService = {
  create(resource, params) {
    return ApiService.post('/tools'+ resource, params);
  },
  get(resource, slug = "") {
    return ApiService.get("/tools" + resource, slug);
  },
  put(resource, params) {
    return ApiService.put('/tools'+ resource, params);
  },
  destroy(resource) {
    return ApiService.delete(resource);
  },
};
//客户管理
export const CustomerService = {
  get(resource, slug) {
    return ApiService.get('/customer'+ resource, slug);
  },
  update(resource, params) {
    return ApiService.put('/customer'+ resource, params);
  },
  create(resource, params) {
    return ApiService.post('/'+ resource, params);
  },
  delete(slug) {
    return ApiService.delete('/customer'+ slug);
  },
};

//代理商管理
export const AgencyService = {
  get(resource, slug) {
    return ApiService.get('/agency'+ resource, slug);
  },
  update(resource, params) {
    return ApiService.put('/agency'+ resource, params);
  },
  create(resource, params) {
    return ApiService.post('/agency'+ resource, params);
  },
  delete(slug) {
    return ApiService.delete('/agency'+ slug);
  },
};

//合同管理
export const ContractService = {
  get(resource, slug) {
    return ApiService.get('/contract' + resource, slug);
  },
  update(resource, params) {
    return ApiService.put('/contract' + resource, params);
  },
  create(resource, params) {
    return ApiService.post('/contract' + resource, params);
  },
  delete(slug) {
    return ApiService.delete('/contract' + slug);
  },
};

//结算管理
export const SettleService = {
  get(resource, slug) {
    return ApiService.get('/settle'+ resource, slug);
  },
  update(resource, params) {
    return ApiService.put('/settle'+ resource, params);
  },
  create(resource, params) {
    return ApiService.post('/settle/import'+ resource, params);
  },
  delete(slug) {
    return ApiService.delete('/settle'+ slug);
  },
};

//工单管理
export const CaseService = {
  get(resource, slug) {
    return ApiService.get('/case'+ resource, slug);
  },
  update(resource, params) {
    return ApiService.put('/case'+ resource, params);
  },
  create(resource, params) {
    return ApiService.post('/case/settle'+ resource, params);
  },
  submit(slug) {
    return ApiService.put('/case/settle/submit'+ slug);
  },
  reject(slug) {
    return ApiService.put('/case/settle/reject'+ slug);
  },
  delete(slug) {
    return ApiService.delete('/case'+ slug);
  },
};

//报表服务
export const ReportService = {
  get(resource, slug = "") {
    return ApiService.get("/report/stats" + resource, slug);
  },
  update(resource, params) {
    return ApiService.put('/report/stats' + resource, params);
  },
};

//系统设置
export const SettingService = {
  get(resource, slug) {
    return ApiService.get('/setting'+ resource, slug);
  },
  update(resource, slug, params) {
    return ApiService.update('/setting'+ resource, slug, params);
  },
  create(resource, params) {
    return ApiService.post('/setting'+ resource, params);
  },
  order(resource, params) {
    return ApiService.update("/setting", resource + '/order', params)
  },
  enable(slug, params) {
    return ApiService.put('/setting'+slug+'enable/'+ params);
  },
  destroy(slug, params) {
    return ApiService.delete('/setting/'+ slug, params);
  }
};

//储备客户
export const FilingService = {
  get(resource, slug) {
    return ApiService.get('/filing'+ resource, slug);
  },
  update(slug, params) {
    return ApiService.update('/filing', slug, params);
  },
  create(resource, params) {
    return ApiService.post('/filing'+ resource, params);
  }
};

//附件备注
export const NotesService = {
  get(resource, slug) {
    return ApiService.get('/notes'+ resource, slug);
  },
  update(slug, params) {
    return ApiService.update('/notes', slug, params);
  },
  create(resource, params) {
    return ApiService.post('/notes'+ resource, params);
  },
  destroy(slug) {
    return ApiService.delete('/notes/'+ slug);
  }
};

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "max-width": "300px"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "small": ""
          }
        }, [_vm._v("format_line_spacing")])], 1)];
      }
    }]),
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_c('span', {
    staticClass: "heading"
  }, [_vm._v("调整表头")])]), _c('v-card-text', {
    staticStyle: {
      "height": "600px"
    }
  }, [_c('v-list', {
    staticClass: "mx-n4",
    attrs: {
      "dense": ""
    }
  }, [_c('draggable', _vm._b({
    model: {
      value: _vm.orderList,
      callback: function callback($$v) {
        _vm.orderList = $$v;
      },
      expression: "orderList"
    }
  }, 'draggable', _vm.dragOptions, false), _vm._l(_vm.orderList, function (item, index) {
    return _c('v-list-item', {
      key: index
    }, [_c('v-list-item-icon', [_c('v-icon', {
      staticClass: "mt-2",
      staticStyle: {
        "cursor": "move"
      },
      attrs: {
        "small": ""
      }
    }, [_vm._v("drag_indicator")])], 1), _c('v-list-item-content', [_c('v-list-item-title', {
      domProps: {
        "textContent": _vm._s(item.text)
      }
    })], 1), _c('v-list-item-action', [_c('v-simple-checkbox', {
      attrs: {
        "value": item.align === ' d-none' ? false : true
      },
      on: {
        "click": function click($event) {
          return _vm.doAction('edit_headers', item);
        }
      }
    })], 1)], 1);
  }), 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('set_default');
      }
    }
  }, [_vm._v("重置")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('set_cancel');
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.doAction('set_save');
      }
    }
  }, [_vm._v("确认")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-btn', {
    attrs: {
      "block": "",
      "color": _vm.color,
      "rounded": ""
    },
    on: {
      "click": _vm.openDialog
    }
  }, [_c('v-icon', {
    attrs: {
      "left": ""
    }
  }, [_vm._v("bug_report")]), _vm._t("default")], 2), _c('v-dialog', {
    attrs: {
      "persistent": "",
      "scrollable": "",
      "width": "750"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v(" 提交BUG或者建议 "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v("close")])], 1)], 1), _c('v-card-text', [_c('v-container', [_c('v-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    },
    model: {
      value: _vm.valid,
      callback: function callback($$v) {
        _vm.valid = $$v;
      },
      expression: "valid"
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("提交人")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.editBodyData.who.personal ? _vm.editBodyData.who.personal.name : _vm.editBodyData.who.username))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("功能模块")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "4"
    }
  }, [_vm._v(_vm._s(_vm.editBodyData.where.meta ? _vm.editBodyData.where.meta.title : 'ESP'))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("发生时间")]), _c('v-col', {
    staticClass: "text--secondary",
    attrs: {
      "cols": "10"
    }
  }, [_vm._v(_vm._s(_vm._f("formatTime")(_vm.editBodyData.when)))]), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("问题描述")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "请输入问题现象或建议",
      "auto-grow": "",
      "outlined": "",
      "rows": "3"
    },
    model: {
      value: _vm.editBodyData.what,
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData, "what", $$v);
      },
      expression: "editBodyData.what"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("期望结果")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-textarea', {
    attrs: {
      "rules": [_vm.rules.required],
      "label": "请输入期望的结果或效果",
      "auto-grow": "",
      "outlined": "",
      "rows": "3"
    },
    model: {
      value: _vm.editBodyData.want,
      callback: function callback($$v) {
        _vm.$set(_vm.editBodyData, "want", $$v);
      },
      expression: "editBodyData.want"
    }
  })], 1), _c('v-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "2"
    }
  }, [_vm._v("页面截图")]), _c('v-col', {
    attrs: {
      "cols": "10"
    }
  }, [_c('v-img', {
    attrs: {
      "max-width": "250",
      "contain": "",
      "src": _vm.editBodyData.preview ? _vm.editBodyData.preview : '/static/error/empty_state.svg'
    }
  })], 1)], 1)], 1)], 1)], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "text": ""
    },
    on: {
      "click": function click($event) {
        return _vm.closeDialog();
      }
    }
  }, [_vm._v("取消")]), _c('v-btn', {
    attrs: {
      "text": "",
      "color": "secondary",
      "disabled": !_vm.valid
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("提交")])], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }
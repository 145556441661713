import { NotesService } from "@/common/http";

import {
  PUBLISH_NOTES,
  FETCH_NOTES_LIST,
  FETCH_NOTES,
  EDIT_NOTES,
  REMOVE_NOTES,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_NOTES_LIST,
  SET_NOTES_LIST,
  SET_NOTES,
} from "./mutations.type"

const state = {
  notesLoading: true,
  notesList: [],
  notes: {}
};

const getters = {
  notesList: state => state.notesList,
  notesLoading: state => state.notesLoading,
  notes: state => state.notes,
};

const actions = {
  [FETCH_NOTES]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      NotesService.get('', slug)
        .then(({ data }) => {
          commit(SET_NOTES, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [FETCH_NOTES_LIST]({ commit }, slug) {
    commit(LOAD_NOTES_LIST);
    return new Promise((resolve, reject) => {
      NotesService.get('/list', '?'+slug)
        .then(({ data }) => {
          commit(SET_NOTES_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
  [PUBLISH_NOTES](context, notes) {
    return NotesService.create('', notes);
  },
  [EDIT_NOTES](context, notes) {
    return NotesService.update(notes._id, notes);
  },
  [REMOVE_NOTES](context, notes) {
    return NotesService.destroy(notes)
  }
};

const mutations = {
  [LOAD_NOTES_LIST] (state) {
    state.notesLoading = true;
  },
  [SET_NOTES_LIST](state, { list }) {
    state.notesList = list;
    state.notesLoading = false;
  },
  [SET_NOTES](state, notes) {
    state.notes = notes;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

import Vue from "vue";
import Vuex from "vuex";
import auth from "./auth.module";
import distributor from "./distributor.module";
import account from "./account.module";
import department from "./department.module";
import group from "./group.module";
import role from "./role.module";
import employee from "./employee.module";
import error from './error.module';
import logs from './logs.module';
import theme from './theme.module';
import tools from './tools.module';
import customer from './customer.module';
import agency from './agency.module';
import contract from './contract.module';
import area from './area.module';
import task from './task.module';
import filing from './filing.module';
import notes from './notes.module';
import settle from './settle.module';
import cases from './cases.module';
import report from './report.module';
import release from './release.module';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth, account, department, group, role, area,
    distributor, employee, task,
    customer, contract, agency, filing,
    error, logs, theme, tools, notes, settle, cases, report,
    release
  }
});

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import 'vuetify/dist/vuetify.min.css'
import zhHans from 'vuetify/es5/locale/zh-Hans'
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify)

const opts = {
  theme: {
    dark: localStorage.getItem('settle_dark') === 'true',
    themes: {
      light: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
      dark: {
        primary: '#2196F3',
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
  lang: {
    locales: { zhHans },
    current: 'zhHans',
  },
}

export default new Vuetify(opts)


import {AgencyService} from "@/common/http";

import {
  CREATE_AGENCY,
  FETCH_AGENCY_LIST,
  FETCH_AGENCY,
  EDIT_AGENCY,
  REMOVE_AGENCY,
} from "./actions.type";

import {
  SET_ERROR,
  LOAD_AGENCY_LIST,
  SET_AGENCY_LIST,
  SET_AGENCY,
} from "./mutations.type";

const state = {
  agencyLoading: false,
  agencyList: [],
  agencyCount: 0,
  agencyInfo: {},
};

const getters = {
  agencyLoading: state => state.agencyLoading,
  agencyList: state => state.agencyList,
  agencyCount: state => state.agencyCount,
  agencyInfo: state => state.agencyInfo,
};

const actions = {
  [CREATE_AGENCY](context, params) {
    return new Promise((resolve, reject) => {
      AgencyService.create('', params)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({response}) => {
          reject(response);
        })
    });
  },
  [FETCH_AGENCY]({ commit }, params) {
    commit(LOAD_AGENCY_LIST);
    return new Promise((resolve, reject) => {
      AgencyService.get('', params.query)
        .then(({ data }) => {
          commit(SET_AGENCY, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [EDIT_AGENCY](context, params) {
    return AgencyService.update('/' +  params.id, params.item);
  },
  [REMOVE_AGENCY](context, data) {
    return AgencyService.delete('/' + data);
  },
  [FETCH_AGENCY_LIST]({ commit }, params) {
    commit(LOAD_AGENCY_LIST);
    return new Promise((resolve, reject) => {
      AgencyService.get('/list', '?'+params.query)
        .then(({ data }) => {
          commit(SET_AGENCY_LIST, data);
          resolve(data);
        })
        .catch((error) => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        })
    });
  },
}

const mutations = {
  [LOAD_AGENCY_LIST] (state) {
    state.agencyLoading = true;
  },
  [SET_AGENCY_LIST](state, list) {
    state.agencyCount = list.query.count;
    state.agencyList = list.docs;
    state.agencyLoading = false;
  },
  [SET_AGENCY](state, agency) {
    state.agencyInfo = agency.agencyInfo;
    state.agencyLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

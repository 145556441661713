<template>
  <v-card outlined>
    <v-card-text>
      <v-data-table
        :options.sync="optionsItem"
        :headers="defaultHeader"
        :items="contractCustomerList"
        :item-class="row_class"
        :server-items-length="contractCustomerCount"
        :loading="contractLoading"
        loading-text="数据读取中... 请稍后"
        :footer-props="{'items-per-page-options': [5, 10, 15, 30, 50]}"
        class="elevation-0 mt-4"
        transition
        fixed-header
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-row>
              <v-col cols="3">
                <v-text-field
                  v-model="query.customer_name"
                  label="客户名称"
                  outlined
                  dense
                  clearable
                  hide-details
                  @input="searchList"
                ></v-text-field>
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="query.area"
                  :items="areaList"
                  label="客户区域"
                  item-text="name"
                  item-value="_id"
                  outlined
                  dense
                  clearable
                  hide-details
                  @change="searchList"
                ></v-select>
              </v-col>
            </v-row>
          </v-toolbar>
        </template>
        <template v-slot:item.project_name="{ item }">
          <td nowrap="true">{{ item?.project_name }}</td>
        </template>
        <template v-slot:item.customer="{ item }">
          <agency-customer-edit v-if="(currentUser.hasAdmin.includes('distributor') || currentUser._id === item.creator._id || currentUser._id === item.customer?.creator || currentUser._id === item.customer?.confirm_settle) && item.approve_status !== 1" :getType="item.business_type" :getId="getId" :editData="item" :isEdit="true">
            <td nowrap="true" class="primary--text">{{ item.customer?.name }}</td>
          </agency-customer-edit>
          <td nowrap="true" v-else>{{ item.customer?.name }}</td>
        </template>
        <template v-slot:item.area="{ item }">
          <td nowrap="true" align="center" v-if="item.customer && item.customer.area">{{ item.customer.area.name }}</td>
        </template>
        <template v-slot:item.business_type="{ item }">
          <span v-if="item.business_type === 1">能源</span>
          <span v-if="item.business_type === 2">调峰</span>
          <span v-if="item.business_type === 3">售电</span>
          <span v-if="item.business_type === 4">智科</span>
        </template>
        <template v-slot:item.sign_type="{ item }">
          <span v-if="item.sign_type === 1">比例分成</span>
          <span v-if="item.sign_type === 2">固定服务费</span>
          <span v-if="item.sign_type === 3">固定单价</span>
          <span v-if="item.sign_type === 5">其他</span>
        </template>
        <template v-slot:item.sign_other="{ item }">
          <span v-if="item.sign_other">{{ item.sign_other }}</span>
        </template>
        <template v-slot:item.xt_ratio="{ item }">
          <td nowrap="true" align="center" v-if="item.xt_ratio">{{ item.xt_ratio.toFixed(2) }}</td>
        </template>
        <template v-slot:item.agency_ratio="{ item }">
          <td nowrap="true" align="center" v-if="item.agency_ratio">{{ item.agency_ratio.toFixed(2) }}</td>
        </template>
        <template v-slot:item.sharp_price="{ item }">
          <td nowrap="true" align="center" v-if="item.sharp_price">{{ item.sharp_price | formatMoney }}</td>
        </template>
        <template v-slot:item.peak_price="{ item }">
          <td nowrap="true" align="center" v-if="item.peak_price">{{ item.peak_price | formatMoney }}</td>
        </template>
        <template v-slot:item.plain_price="{ item }">
          <td nowrap="true" align="center" v-if="item.plain_price">{{ item.plain_price | formatMoney }}</td>
        </template>
        <template v-slot:item.valley_price="{ item }">
          <td nowrap="true" align="center" v-if="item.valley_price">{{ item.valley_price | formatMoney }}</td>
        </template>
        <template v-slot:item.income_start="{ item }">
          {{ item.income_start | formatDate }}
        </template>
        <template v-slot:item.contract_start="{ item }">
          {{ item.contract_start | formatDate }}
        </template>
        <template v-slot:item.contract_end="{ item }">
          {{ item.contract_end | formatDate }}
        </template>
        <template v-slot:item.confirm_image="{ item }">
          <v-btn v-if="item.confirm_image" text x-small color="primary" @click="doAction('check_img', item.confirm_image)">查看</v-btn>
          <span v-else>无</span>
        </template>
        <template v-slot:item.href="{ item }">
          <v-btn v-if="item.href" text x-small color="primary" @click="doAction('check_img', item.href)">查看</v-btn>
          <span v-else>无</span>
        </template>
        <template v-slot:item.contract_status="{ item }">
          <span v-if="item.contract_status === 0">
            未开始
          </span>
          <span v-if="item.contract_status === 1" class="success--text">
            履约中
          </span>
          <span v-if="item.contract_status === 2" class="error--text">
            已终止
          </span>
        </template>
        <template v-slot:item.approve_status="{ item }">
          <span v-if="item.approve_status === 0" class="error--text">
            未审核
          </span>
          <span v-if="item.approve_status === 1">
            已审核
          </span>
        </template>
        <template v-slot:item.creator="{ item }">
          <span v-if="item.creator">{{ item.creator.personal.name }}</span>
        </template>
        <template v-slot:item.createTime="{ item }">
          {{ item.createTime | formatTime }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                v-bind="attrs"
                v-on="on"
                icon
                small
              >
                <v-icon small>more_vert</v-icon>
              </v-btn>
            </template>
              <v-list dense>
                <v-list-item class="v-list-item&#45;&#45;link" @click="confirmApprove(item)" v-if="!item.approve_status && (currentUser.hasAdmin.includes('distributor') || currentUser.permission.includes('Contract_Approve'))">
                  <v-list-item-icon>
                    <v-icon small color="primary">task</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> 审核 </v-list-item-title>
                </v-list-item>
                <v-list-item class="v-list-item&#45;&#45;link" @click="confirmBlock(item._id)" v-if="item.contract_status === 1 && (currentUser.hasAdmin.includes('distributor') || currentUser._id === item.creator._id || currentUser._id === item.customer.confirm_settle)">
                  <v-list-item-icon>
                    <v-icon small>block</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> 终止 </v-list-item-title>
                </v-list-item>
                <v-list-item class="v-list-item&#45;&#45;link" @click="confirmDelete(item._id)" v-if="currentUser.hasAdmin.includes('distributor') || currentUser._id === item.creator._id || currentUser._id === item.customer.confirm_settle">
                  <v-list-item-icon>
                    <v-icon small color="error">delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title> 删除 </v-list-item-title>
                </v-list-item>
              </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <v-dialog v-model="warnDlg" persistent scrollable width="400">
        <v-card>
          <v-card-title>
            {{ dlgTitle }}
            <v-spacer></v-spacer>
            <v-btn icon @click="closeDlg"><v-icon>close</v-icon></v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text class="text-center py-6">
            <span v-if="dlgTitle === '审批客户'">确定审核通过此客户吗？</span>
            <span v-if="dlgTitle === '终止合同'">确定终止此合同吗？</span>
            <span v-if="dlgTitle === '删除合同'">确定删除此合同吗？</span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn text @click="closeDlg">取消</v-btn>
            <v-spacer></v-spacer>
            <v-btn v-if="dlgTitle === '审批客户'" text color="primary" @click="approveCustomer">审核通过</v-btn>
            <v-btn v-if="dlgTitle === '终止合同'" text color="error" @click="blockContract">确认终止</v-btn>
            <v-btn v-if="dlgTitle === '删除合同'" text color="error" @click="deleteContract">确认删除</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import store from "@/store";
import {mapGetters} from "vuex";
import {
  EDIT_CONTRACT,
  REMOVE_CONTRACT, FETCH_URL, FETCH_CONTRACT_CUSTOMER, FETCH_AREA_LIST
} from "@/store/actions.type";
import {SET_ERROR} from "@/store/mutations.type";
import * as XLSX from "xlsx";
export default {
  data() {
    return {
      optionsItem: {},
      query: {
        customer_name: '',
        area: '',
      },
      defaultQuery: {
        area: '',
      },
      defaultHeader: [
        { text: '客户名称', value: 'customer', width: '200', align: 'left', sortable: false},
        { text: '项目名称', value: 'project_name', width: '180', align: 'left', sortable: false},
        { text: '客户区域', value: 'area', width: '120', align: 'center', sortable: false},
        { text: '业务类型', value: 'business_type', width: '85', align: 'center', sortable: false},
        { text: '客户合作条件(元/MWh)', value: 'condition', width: '200', align: 'center', sortable: false},
        { text: '允许客户偏差免考范围(%)', value: 'allow_ratio', width: '120', align: 'center', sortable: false},
        { text: '结算方式', value: 'sign_type', width: '120', align: 'center', sortable: false},
        { text: '签约方式说明', value: 'sign_other', width: '200', align: 'center', sortable: false},
        { text: '鑫泰比例(%)', value: 'xt_ratio', width: '120', align: 'center', sortable: false},
        { text: '代理商比例(%)', value: 'agency_ratio', width: '120', align: 'center', sortable: false},
        { text: '固定单价(尖)', value: 'sharp_price', width: '120', align: 'center', sortable: false},
        { text: '固定单价(峰)', value: 'peak_price', width: '120', align: 'center', sortable: false},
        { text: '固定单价(平)', value: 'plain_price', width: '120', align: 'center', sortable: false},
        { text: '固定单价(谷)', value: 'valley_price', width: '120', align: 'center', sortable: false},
        { text: '固定服务费(元)', value: 'fixed_fee', width: '120', align: 'center', sortable: false},
        { text: '收益开始日期', value: 'income_start', width: '120', align: 'center', sortable: false},
        { text: '项目确认单开始时间', value: 'contract_start', width: '120', align: 'center', sortable: false},
        { text: '项目确认单结束时间', value: 'contract_end', width: '120', align: 'center', sortable: false},
        { text: '合同状态', value: 'contract_status', width: '120', align: 'center', sortable: false},
        { text: '审批状态', value: 'approve_status', width: '120', align: 'center', sortable: false},
        { text: '备注', value: 'remark', width: '200', align: 'center', sortable: false},
        { text: '创建人', value: 'creator', width: '120', align: 'center', sortable: false},
        { text: '创建时间', value: 'createTime', width: '120', align: 'center', sortable: false},
        { text: '操作', value: 'actions', width: '100', align: 'center', sortable: false},
      ],
      warnDlg: false,
      dlgTitle: '',
      activeId: '',
      approveItem: '',
    }
  },
  props: {
    getType: {
      type: String,
      require: true,
      default: '',
    },
    contractType: {
      type: String,
      require: true,
      default: '',
    },
    getId: {
      type: String,
      require: true,
      default: '',
    },
    reload: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    optionsItem: {
      handler() {
        this.getList();
      },
      deep: true,
    },
    contractReload() {
      this.getList()
    },
  },
  beforeRouteEnter(to, from, next) {
    Promise.all([
      store.dispatch(FETCH_AREA_LIST, 'limit=-1&isEnable=true')
    ]).then(() => {
      next();
    });
  },
  created() {

  },
  computed: {
    ...mapGetters([
      "currentUser",
      "contractLoading",
      "contractCustomerList",
      "contractCustomerCount",
      "contractReload",
      "areaList"
    ]),
  },
  mounted() {},
  methods: {
    searchList() {
      this.getList()
    },
    getList() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.optionsItem;
      let sort = "";
      if (sortBy && sortBy.length === 1) {
        if (sortDesc[0] === true) {
          sort = "-" + sortBy[0];
        } else {
          sort = sortBy[0];
        }
      }

      let urlQuery = ''
      urlQuery += '&contract_type=' + this.contractType
      if (this.getType === 'customer') {
        urlQuery += '&customer=' + this.getId
      }else if (this.getType === 'agency') {
        urlQuery += '&agency=' + this.getId
      }
      if (this.query.customer_name) urlQuery += '&customer_name=' + this.query.customer_name
      if (this.query.area) urlQuery += '&area=' + this.query.area
      if (Object.keys(this.optionsItem).length > 0) {
        store.dispatch(FETCH_CONTRACT_CUSTOMER, {
          query:
            "page=" +
            page +
            "&limit=" +
            itemsPerPage +
            "&sort=" +
            sort +
            urlQuery,
        })
      }
    },
    doAction(action, item = {}) {
      switch (action) {
        case 'batch_create': {
          this.dlgInfo = true
          break
        }
        case 'input_create': {
          this.$router.push('/distributor/customer/edit')
          break
        }
        case 'close_info': {
          this.dlgInfo = false
          break
        }
        case 'check_img': {
          this.imgDlg = true
          this.$store.dispatch(FETCH_URL, item).then((url) => {
            this.imgUrl = url
            this.overlay = false
          })
          break
        }
        case 'close_img': {
          this.imgUrl = ''
          this.imgDlg = false
          this.overlay = true
          break
        }
        case 'download_create_template': {
          console.log(XLSX)
          let ws = XLSX.utils.aoa_to_sheet([['批量创建客户模版。']])
          XLSX.utils.sheet_add_json(ws, [
            {}
          ], {header: this.batch.create_header, origin: 'A2'})
          const merge = [
            {s: {r: 0, c: 0}, e: {r: 0, c: this.batch.create_header.length - 1}}
          ]
          ws['!merges'] = merge
          ws.A1.s = {alignment: {wrapText: true}};
          let wb = XLSX.utils.book_new()
          XLSX.utils.book_append_sheet(wb, ws, '批量创建客户')
          XLSX.writeFile(wb, '批量创建客户模版.xlsx')
          break
        }
        case 'upload_create_file': {
          this.$refs.uploadImport.click()
          break
        }
        case 'delete_customer': {
          this.deleteId = item
          this.warnDlg = true
          break
        }
        case 'close_delete': {
          this.deleteId = ''
          this.warnDlg = false
          break
        }
      }
    },
    confirmBlock(id) {
      this.activeId = id
      this.dlgTitle = '终止合同'
      this.warnDlg = true
    },
    blockContract() {
      store.dispatch(EDIT_CONTRACT, { id: this.activeId, item: { contract_status: 2 }}).then(() => {
        this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
        this.getList()
        this.closeDlg()
      })
    },
    confirmDelete(id) {
      this.activeId = id
      this.dlgTitle = '删除合同'
      this.warnDlg = true
    },
    deleteContract() {
      store.dispatch(REMOVE_CONTRACT, this.activeId).then(() => {
        this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
        this.$emit('reload')
        this.getList()
        this.closeDlg()
      })
    },
    confirmApprove(item) {
      this.activeId = item._id
      this.approveItem = item
      this.dlgTitle = '审批客户'
      this.warnDlg = true
    },
    approveCustomer() {
      this.approveItem.agency = this.approveItem.agency.id
      this.approveItem.customer = this.approveItem.customer.id
      this.approveItem.approve_status = 1
      store.dispatch(EDIT_CONTRACT, {id: this.approveItem._id, item: this.approveItem}).then(() => {
        this.$store.commit(SET_ERROR, {msg: '修改成功', color: 'primary'});
        this.getList()
        this.closeDlg()
      })
    },
    closeDlg() {
      this.warnDlg = false
      this.activeId = ''
      this.dlgTitle = ''
      this.approveItem = ''
    },
    row_class(item) {
      if (item.contract_end && ((new Date(item.contract_end) - new Date()) / (1000*60*60*24) <= 30)) return this.$vuetify.theme.dark ? 'red lighten-2' : 'red lighten-5'
    },
  },
  directives: {}
}
</script>

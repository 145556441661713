import { FilingService } from "@/common/http";
import {
  FETCH_FILING_LIST,
  FETCH_FILING,
  PUBLISH_FILING,
  EDIT_FILING,
} from "./actions.type";
import {
  SET_ERROR,
  LOAD_FILINGS,
  SET_FILING_LIST,
  SET_FILING,
} from "./mutations.type"

const state = {
  filingLoading: false,
  filingList: [],
  filingCount: 0,
  filing: {},
};

const getters = {
  filing: state => state.filing,
  filingList: state => state.filingList,
  filingCount: state => state.filingCount,
  filingLoading: state => state.filingLoading,
};

const actions = {
  [FETCH_FILING_LIST]({ commit }, slug) {
    commit(LOAD_FILINGS);
    return new Promise((resolve, reject) => {
      FilingService.get('', 'list?'+slug)
        .then(({ data }) => {
          commit(SET_FILING_LIST, data);
          resolve(data);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [FETCH_FILING]({ commit }, slug) {
    return new Promise((resolve, reject) => {
      FilingService.get('', slug)
        .then(({ data }) => {
          commit(SET_FILING, data.doc);
          resolve(data.doc);
        })
        .catch(error => {
          commit(SET_ERROR, { msg: error });
          reject(error);
        });
    });
  },
  [PUBLISH_FILING](context, data) {
    return FilingService.create('', data);
  },
  [EDIT_FILING](context, data) {
    return FilingService.update(data._id, data);
  }
};

const mutations = {
  [LOAD_FILINGS] (state) {
    state.filingLoading = true;
  },
  [SET_FILING_LIST](state, list) {
    state.filingList = list.docs;
    state.filingCount = list.query.count;
    state.filingLoading = false;
  },
  [SET_FILING](state, data) {
    state.filing = data;
    state.filingLoading = false;
  },
};

export default {
  state,
  actions,
  mutations,
  getters
};

<template>
  <table class="receiptTable caption" cellspacing="0">
    <tr>
      <th colspan="10" class="title py-4">
        {{ settleData.settleTitle }}
      </th>
    </tr>
    <tr>
      <th>代理商名称</th>
      <th width="12%">项目名称</th>
      <th>代理商服务费（含税）（元）</th>
      <th>代理商服务费（不含税）（元）</th>
      <th>专票/普票</th>
      <th>代理商<br>发票费率</th>
      <th>税金及附加（元）</th>
      <th>代理商费用（不含税）（元）</th>
      <th>代理商费用（含税）（元）</th>
      <th>总开票金额（元）</th>
    </tr>
    <tr v-for="(item,i) in settleData.settleList" :key="item._id">
      <td v-if="i === 0" class="text-center" style="width: 10%;" :rowspan="settleData.settleList.length">{{ settleData.agency?.name }}</td>
      <td class="text-center" style="border-left: 0;">{{ item.customer?.name }}</td>
      <td class="text-center">{{ item.agency_service_fee?.$numberDecimal*1 | formatMoney }}</td>
      <td class="text-center">{{ item.agency_service_fee_no_tax?.$numberDecimal*1 | formatMoney }}</td>
      <td class="text-center">{{ settleData.settleList?.[0]?.agency_invoice_type === 1 ? '专票' : '普票' }}</td>
      <td class="text-center">{{ settleData.settleList?.[0]?.agency_tax_rate?.$numberDecimal * 1 }}%</td>
      <td class="text-center">{{ item.tax?.$numberDecimal*1 | formatMoney }}</td>
      <td class="text-center">{{ item.agency_fee_no_tax?.$numberDecimal*1 | formatMoney }}</td>
      <td class="text-center">{{ item.agency_fee?.$numberDecimal*1 | formatMoney }}</td>
      <td v-if="i === 0" :rowspan="settleData.settleList.length" class="text-center">{{ settleData.settleList.reduce((prev, curr) => {return prev.agency_fee?.$numberDecimal*1 + curr.agency_fee?.$numberDecimal*1}) | formatMoney }}</td>
    </tr>
    <tr style="border: 1px solid #000; border-top: 0; border-bottom: 0;">
      <td colspan="3" class="text-left no-border font-weight-bold pl-6" style="vertical-align: top;">制表人：{{ settleData.producedBy?.personal?.name }}</td>
      <td colspan="3" class="text-left no-border font-weight-bold pl-6">
        <template v-if="comfirmors.length">
          <v-row v-for="(item, i) in comfirmors" :key="i">
            <v-col cols="6" align="right" class="pr-0">
              <span v-if="i === 0">确认人：</span>
            </v-col>
            <v-col cols="6" align="left" class="pl-0">
              {{ item }}
            </v-col>
          </v-row>
        </template>
        <template v-else>
          确认人：
        </template>
      </td>
      <td colspan="2" class="text-left no-border font-weight-bold pl-6" style="vertical-align: top;">
        <template v-if="approvers.length">
          <v-row v-for="(item, i) in approvers" :key="i">
            <v-col cols="6" align="right" class="pr-0">
              <span v-if="i === 0">核定人：</span>
            </v-col>
            <v-col cols="6" align="left" class="pl-0">
              {{ item }}
            </v-col>
          </v-row>
        </template>
        <template v-else>
          核定人：
        </template>
      </td>
      <td colspan="3" class="text-left no-border font-weight-bold pl-6" style="vertical-align: top;">
        <template v-if="reviewers.length">
          <v-row v-for="(item, i) in reviewers" :key="i">
            <v-col cols="6" align="right" class="pr-0">
              <span v-if="i === 0">复核人：</span>
            </v-col>
            <v-col cols="6" align="left" class="pl-0">
              {{ item }}
            </v-col>
          </v-row>
        </template>
        <template v-else>
          复核人：
        </template>
      </td>
    </tr>
    <tbody style="border: 1px solid #000;">
    <tr>
      <td colspan="5" class="text-center no-border">甲方：北京鑫泰智科技术有限公司</td>
      <td colspan="5" class="text-center no-border">乙方：{{ settleData.agency?.name }}</td>
    </tr>
    <tr>
      <td colspan="5" class="text-center no-border" v-if="this.reviewTime">日期：{{ reviewTime.getFullYear() + '年' + (reviewTime.getMonth()+1)+ '月' + reviewTime.getDate() + '日' }}</td>
      <td colspan="5" class="text-center no-border" v-else>日期：<span style="letter-spacing: 1.5rem;" class="pl-10">年月日</span></td>
      <td colspan="5" class="text-center no-border" v-if="this.reviewTime">日期：{{ reviewTime.getFullYear() + '年' + (reviewTime.getMonth()+1)+ '月' + reviewTime.getDate() + '日' }}</td>
      <td colspan="5" class="text-center no-border" v-else>日期：<span style="letter-spacing: 1.5rem;" class="pl-10">年月日</span></td>
    </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  data() {
    return {
      dialog: false,
      comfirmors: [],
      approvers: [],
      reviewers: [],
      reviewTime: '',
    }
  },
  props: {
    getType: {
      type: Number,
      require: true,
      default: null,
    },
    getId: {
      type: String,
      require: true,
      default: '',
    },
    settleData: {
      type: Object,
      default: null,
    },
    color: {
      type: String,
      default: "primary"
    },
    divider: {
      type: String,
      default: null
    }
  },
  watch: {
    settleData() {
      this.checkData()
    },
  },
  created() {
    this.checkData()
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    checkData() {
      this.settleData.tasks_list.map((item, i) => {
        item.record?.map((op,j) => {
          if (op.action === 1) {
            if (i === 0) this.comfirmors.push(op.operator.personal.name)
            if (i === 1) this.approvers.push(op.operator.personal.name)
            if (i === 2) {
              if (j === 0) this.reviewTime = new Date(op.optime)
              this.reviewers.push(op.operator.personal.name)
            }
          }
        })
      })
      this.comfirmors = [...new Set(this.comfirmors)]
      this.approvers = [...new Set(this.approvers)]
      this.reviewers = [...new Set(this.reviewers)]
    },
    closeEdit() {
      this.dialog = false
    },
    downloadReceipt() {
      console.log('download')
    },
  },
  directives: {}
}
</script>

<style scoped>
.receiptTable {
  width: 1px;
  min-width: 100%;
  border-collapse: collapse;
}
.receiptTable th {
  word-break: keep-all;
}
.receiptTable td,
.receiptTable th {
  padding: 0 4px;
  height: 30px;
  border: 1px solid rgb(0, 0, 0);
  color: #000 !important;
}
.receiptTable td.no-border {
  border: 0;
  padding: 20px 4px;
}
.receiptTable>tr>th+th {
  border-left-width: 0;
}
.receiptTable>tr+tr>th {
  border-top-width: 0;
}
.receiptTable>tr>td+td {
  border-left-width: 0;
}
.receiptTable>tr+tr>td {
  border-top-width: 0;
}
</style>
